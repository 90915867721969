import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core'
import { EventOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SmartFormResponseEvents = ({ events }) => {
	const { t } = useTranslation()

	const [open, setOpen] = useState(true)

	return (
		<Card>
			<CardHeader
				title={t('workflows.response.events')}
				avatar={<EventOutlined />}
				action={
					<IconButton
						aria-label="collapse"
						onClick={() => setOpen(!open)}
						// className={classes.iconButton}
					>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				}
			/>

			<Collapse in={open} timeout="auto" unmountOnExit>
				<CardContent>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{t('workflows.response.event.completed')}</TableCell>
									<TableCell>
										{t('workflows.response.event.decisionResult')}
									</TableCell>
									<TableCell>{t('workflows.response.event.taskName')}</TableCell>
									<TableCell>{t('workflows.response.event.status')}</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{events.map((event, i) => (
									<TableRow key={i}>
										<TableCell>
											{t(
												`workflows.response.event.completed.${
													event.completed ? 'true' : 'false'
												}`
											)}
										</TableCell>
										<TableCell>
											{t(
												`workflows.response.event.decisionResult.${
													event.decisionResult ? 'true' : 'false'
												}`
											)}
										</TableCell>
										<TableCell>{event.taskName}</TableCell>
										<TableCell>
											{t(`workflows.response.status.${event.status}`)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Collapse>
		</Card>
	)
}

export default SmartFormResponseEvents
