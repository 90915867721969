import {
	Box,
	Collapse,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useState } from 'react'
import formatDate from '../../../utils/formatDate'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const formatValue = (value) => {
	return value ? (typeof value === 'string' ? value : JSON.stringify(value)) : ''
}

const Rows = ({ data, t }) => {
	return (
		<>
			{data.map((historyRow, index) => (
				<TableRow key={index}>
					<TableCell component="th" scope="row">
						{t(historyRow.fieldName)}
					</TableCell>
					<TableCell>
						<span
							style={{
								wordBreak: 'break-all'
							}}
						>
							{formatValue(historyRow.newValue)}
						</span>
					</TableCell>
					<TableCell>
						<span
							style={{
								wordBreak: 'break-all'
							}}
						>
							{formatValue(historyRow.originalValue)}
						</span>
					</TableCell>
				</TableRow>
			))}
		</>
	)
}

const RowsOldFormat = ({ data, oldData, dataKey, t }) => {
	return (
		<>
			{!dataKey || (data[dataKey] && typeof data[dataKey] === 'object') ? (
				<>
					{Object.keys(dataKey ? data[dataKey] : data).map((k, i) => (
						<RowsOldFormat
							key={i}
							data={dataKey ? data[dataKey] : data}
							oldData={dataKey && oldData ? oldData[dataKey] : oldData}
							dataKey={k}
							t={t}
						/>
					))}
				</>
			) : (
				<TableRow
					selected={
						oldData &&
						data[dataKey] &&
						oldData[dataKey] &&
						data[dataKey] !== oldData[dataKey]
					}
				>
					<TableCell width={20} component="th" scope="row">
						{t(dataKey)}
					</TableCell>
					<TableCell width={40}>{data[dataKey] && data[dataKey]}</TableCell>
					<TableCell width={40}>
						{oldData && oldData[dataKey] && oldData[dataKey]}
					</TableCell>
				</TableRow>
			)}
		</>
	)
}

const HistoryTableRow = ({ history, additionalColumns }) => {
	const { t, i18n } = useTranslation()
	const [open, setOpen] = useState(false)

	return (
		<>
			<TableRow>
				<TableCell>
					{history.data && (
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
					)}
				</TableCell>

				{additionalColumns &&
					additionalColumns.map(({ Template }, index) => (
						<TableCell key={index}>{<Template data={history} />}</TableCell>
					))}

				<TableCell>
					{i18n.exists(`history.${history.action}`)
						? t(`history.${history.action}`)
						: history.action}
				</TableCell>
				<TableCell>{history.createdByName}</TableCell>
				<TableCell>{formatDate(history.createdAt)}</TableCell>
			</TableRow>

			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={4 + (additionalColumns?.length || 0)}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell width={'20%'}>{t('history.key')}</TableCell>
										<TableCell width={'40%'}>{t('history.new')}</TableCell>
										<TableCell width={'40%'}>{t('history.old')}</TableCell>
									</TableRow>
								</TableHead>
								{history.data && (
									<TableBody>
										{Array.isArray(history.data) ? (
											<Rows
												data={history.data}
												t={t}
												additionalColumns={additionalColumns}
											/>
										) : (
											<RowsOldFormat
												data={history.data}
												oldData={history.oldData}
												dataKey={null}
												t={t}
											/>
										)}
									</TableBody>
								)}
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

const HistoryTable = ({ histories, additionalColumns }) => {
	const { t } = useTranslation()

	return (
		<TableContainer>
			<Table
				classes={{
					root: 'secondary'
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>

						{additionalColumns &&
							additionalColumns.map((column) => (
								<TableCell key={column.name} width={'20%'}>
									{column.name}
								</TableCell>
							))}

						<TableCell>{t('history.action')}</TableCell>
						<TableCell>{t('created_by')}</TableCell>
						<TableCell>{t('created_at')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{histories.map((history, i) => (
						<HistoryTableRow
							key={`prompt-${i}`}
							history={history}
							t={t}
							additionalColumns={additionalColumns}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default HistoryTable
