import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'
import { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

const TotalCompanies = () => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(true)
	const [total, setTotal] = useState()
	const [monitoring, setMonitoring] = useState()

	useEffect(() => {
		;(async () => {
			const { data } = await axios.get(`${API_URL}/v3/rpc/count_total_companies`, {})
			setTotal(data)

			const { data: monitoringData } = await axios.get(
				`${API_URL}/v3/rpc/count_monitored_companies`,
				{}
			)
			setMonitoring(monitoringData)

			setLoading(false)
		})()
	}, [])

	return (
		<Box
			height={'100%'}
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'space-between'}
		>
			<Typography variant={'h4'}>
				{loading ? <Skeleton /> : <>{t('dashboard.companies.total', { value: total })}</>}
			</Typography>
			<br />
			<Box>
				{loading ? (
					<Skeleton />
				) : monitoring && total ? (
					<>
						<Typography variant="h1" component="span">
							{Math.floor((monitoring / total) * 100)}%{' '}
						</Typography>
						<Typography variant="h5" component="span">
							{t('dashboard.companies.monitoring')}
						</Typography>
					</>
				) : (
					<></>
				)}
			</Box>
		</Box>
	)
}

export default TotalCompanies
