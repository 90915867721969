import { createContext, useState } from 'react'

export const PageContext = createContext({
	pageConfig: { name: '', backLink: '' },
	setPageConfig: () => {}
})

export const PageProvider = ({ children }) => {
	const [pageConfig, setPageConfig] = useState(null)

	return (
		<PageContext.Provider
			value={{
				pageConfig,
				setPageConfig
			}}
		>
			{children}
		</PageContext.Provider>
	)
}
