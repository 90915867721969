import { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { CONFIGURATION_API_URL } from '../../../config/constants'
import {
	Box,
	Button,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableRow,
	TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SnackbarContext, UserContext } from '../../../context'

const PipedriveCredentials = () => {
	const { user } = useContext(UserContext)
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [credentialsExists, setCredentialsExists] = useState(false)
	const [loading, setLoading] = useState(true)
	const [saving, setSaving] = useState(false)
	const [pipedriveCredentials, setPipedriveCredentials] = useState({
		api_key: ''
	})

	useEffect(() => {
		if (user) {
			setPipedriveCredentials({
				...pipedriveCredentials,
				organization_id: user.organizationId
			})
		}
	}, [user])

	useEffect(() => {
		;(async () => {
			setLoading(true)

			try {
				const { data } = await axios.get(
					`${CONFIGURATION_API_URL}/organization_pipedrive_credentials`,
					{
						params: {
							organization_id: `eq.${user.organizationId}`
						}
					}
				)
				console.log('data', data[0])
				if (data[0]) {
					setPipedriveCredentials(data[0])
					setCredentialsExists(true)
				}
			} catch (e) {
				console.log(e)
				setCredentialsExists(false)
			}

			setLoading(false)
		})()
	}, [])

	const handleInputChange = (prop) => (event) => {
		setPipedriveCredentials({ ...pipedriveCredentials, [prop]: event.target.value })
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault()

		setSaving(true)

		try {
			if (credentialsExists) {
				await axios.patch(
					`${CONFIGURATION_API_URL}/organization_pipedrive_credentials`,
					pipedriveCredentials
				)
			} else {
				await axios.post(
					`${CONFIGURATION_API_URL}/organization_pipedrive_credentials`,
					pipedriveCredentials
				)
			}

			showMessage(t('3rd_party_credentials.saved'), 'success')
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setCredentialsExists(true)
		setSaving(false)
	}

	const handleDelete = async () => {
		setSaving(true)

		try {
			await axios.delete(`${CONFIGURATION_API_URL}/organization_pipedrive_credentials`, {
				data: {}
			})

			setPipedriveCredentials({
				api_key: ''
			})

			showMessage(t('3rd_party_credentials.saved'), 'success')
		} catch (e) {
			showMessage(t('error'), 'error')

			setCredentialsExists(false)
		}

		setSaving(false)
	}

	return (
		<Box mb={6}>
			{loading ? (
				<LinearProgress />
			) : (
				<form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
					<Table
						classes={{
							root: 'primary'
						}}
					>
						<TableBody>
							<TableRow>
								<TableCell>
									<TextField
										size={'small'}
										type={'text'}
										label={t('apiKey')}
										variant="outlined"
										required
										fullWidth={true}
										onChange={handleInputChange('api_key')}
										value={pipedriveCredentials.api_key}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell>
									<Box display={'flex'} gridGap={24}>
										<Button
											type={'submit'}
											variant="contained"
											color="primary"
											disabled={saving}
										>
											{t('save')}
										</Button>

										<Button
											type={'button'}
											variant="outlined"
											color="secondary"
											onClick={handleDelete}
											disabled={saving}
										>
											{t('delete')}
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</form>
			)}
		</Box>
	)
}

export default PipedriveCredentials
