import {
	Card,
	CardHeader,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Tab,
	CardContent
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { COMPANY_FINVOICE_ADDRESS_TYPES } from '../../../../config/constants'
import { ReceiptOutlined } from '@material-ui/icons'

const InvoiceTable = ({ selectedIndex, index, addresses, type }) => {
	const { t } = useTranslation()

	const [filteredAddresses, setFilteredAddresses] = useState([])

	useEffect(() => {
		setFilteredAddresses(
			addresses.filter((address) => address.finvoiceAddressType?.toLowerCase() === type)
		)
	}, [addresses])

	return (
		<div
			role="tabpanel"
			hidden={selectedIndex !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{filteredAddresses && (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell width={'25%'}>
									{t('company.invoicing.address')}
								</TableCell>
								<TableCell width={'25%'}>
									{t('company.invoicing.operator')}
								</TableCell>
								<TableCell width={'25%'}>
									{t('company.invoicing.operatorCode')}
								</TableCell>
								<TableCell width={'25%'}>{t('company.invoicing.ovt')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredAddresses.map((address, i) => (
								<TableRow key={i}>
									<TableCell>{address.address}</TableCell>
									<TableCell>{address.operator}</TableCell>
									<TableCell>{address.operatorCode}</TableCell>
									<TableCell>{address.ovt}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	)
}

const CompanyContactInfo = ({ addresses }) => {
	const { t } = useTranslation()

	const [selectedIndex, setSelectedIndex] = useState(0)

	const handleChange = (event, newValue) => {
		setSelectedIndex(newValue)
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		}
	}

	return (
		<>
			<Card component={Paper}>
				<CardHeader title={t(`company.invoicing.address`)} avatar={<ReceiptOutlined />} />

				<CardContent>
					<Tabs
						value={selectedIndex}
						onChange={handleChange}
						aria-label="invoices"
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab label={COMPANY_FINVOICE_ADDRESS_TYPES.receiving} {...a11yProps(0)} />
						<Tab label={COMPANY_FINVOICE_ADDRESS_TYPES.sending} {...a11yProps(1)} />
					</Tabs>

					<InvoiceTable
						selectedIndex={selectedIndex}
						index={0}
						addresses={addresses}
						type={COMPANY_FINVOICE_ADDRESS_TYPES.receiving}
					/>
					<InvoiceTable
						selectedIndex={selectedIndex}
						index={1}
						addresses={addresses}
						type={COMPANY_FINVOICE_ADDRESS_TYPES.sending}
					/>
				</CardContent>
			</Card>
		</>
	)
}

export default CompanyContactInfo
