import { useContext, useState } from 'react'

import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core'
import { Translate, ExpandMore } from '@material-ui/icons'

import { LanguageContext } from '../../../../context'
import { LANGUAGES } from '../../../../config/constants'

import useStyles from './Language.styles'

const Language = () => {
	const { language, setLanguage } = useContext(LanguageContext)
	const [languageAnchorEl, setLanguageAnchorEl] = useState(null)
	const languageMenuOpen = Boolean(languageAnchorEl)

	const handleLanguageClick = (event) => {
		setLanguageAnchorEl(event.currentTarget)
	}

	const handleLanguageItemClick = (lang) => {
		setLanguage(lang)
		handleLanguageMenuClose()
	}

	const handleLanguageMenuClose = () => {
		setLanguageAnchorEl(null)
	}

	const classes = useStyles()

	return (
		<Box className={classes.box}>
			<Button
				className={classes.languageButton}
				onClick={handleLanguageClick}
				startIcon={<Translate fontSize={'small'} />}
				endIcon={<ExpandMore fontSize={'small'} />}
				variant={'contained'}
				color={'default'}
				size={'small'}
			>
				{LANGUAGES[language]}
			</Button>

			<Menu
				id="menu-appbar"
				getContentAnchorEl={null}
				anchorEl={languageAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				classes={{ paper: classes.menu }}
				open={languageMenuOpen}
				onClose={handleLanguageMenuClose}
			>
				{Object.keys(LANGUAGES).map((lang) => (
					<MenuItem
						key={`language-${lang}`}
						selected={language === lang}
						onClick={() => handleLanguageItemClick(lang)}
					>
						<img className={classes.flag} src={`/flags/${lang}.svg`} alt={''} />
						<Typography variant={'h6'}>{LANGUAGES[lang]}</Typography>
					</MenuItem>
				))}
			</Menu>
		</Box>
	)
}

export default Language
