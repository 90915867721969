import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider, LanguageProvider, UserProvider } from './context'
import Snackbar from './components/shared/Snackbar'

import AppRouter from './router'
import { Authenticator } from '@aws-amplify/ui-react'
import { PageProvider } from './context/PageContext'

import '@aws-amplify/ui-react/styles.css'

const App = () => {
	return (
		<LanguageProvider>
			<PageProvider>
				<SnackbarProvider>
					<Authenticator.Provider>
						<UserProvider>
							<div className="App">
								<Router>
									<AppRouter />
								</Router>
								<Snackbar />
							</div>
						</UserProvider>
					</Authenticator.Provider>
				</SnackbarProvider>
			</PageProvider>
		</LanguageProvider>
	)
}

export default App
