import { Authenticator, Autocomplete, CheckboxField, TextField } from '@aws-amplify/ui-react'

import { v4 as uuidv4 } from 'uuid'
import { getData as getCountriesList } from 'country-list'
import { useState } from 'react'
import CompanyAutocomplete from '../../utils/CompanyAutocomplete'
import { Box, Link } from '@material-ui/core'
import { I18n } from 'aws-amplify'

const SignupFields = ({}) => {
	const [uuid] = useState(uuidv4())
	const [organisationName, setOrganisationName] = useState('')

	const [signupData, setSignupData] = useState({
		company: {
			value: '',
			label: ''
		}
	})

	const handleSignupDataChange = (prop) => (e) => {
		setSignupData({
			...signupData,
			[prop]: {
				value: '',
				label: e.target.value
			}
		})
	}

	const handleSignupDataClear = (prop) => (e) => {
		setSignupData({
			...signupData,
			[prop]: {
				value: '',
				label: ''
			}
		})
	}

	const handleSignupDataSelect = (prop) => (e) => {
		if (prop === 'company') {
			setOrganisationName(e.label)
		}

		setSignupData({
			...signupData,
			[prop]: {
				label: e.label,
				value: e.id
			}
		})
	}

	const handleSignupDataBlur = (prop) => (e) => {
		if (!signupData[prop].value) {
			setSignupData({
				...signupData,
				[prop]: {
					...signupData[prop],
					label: ''
				}
			})
		}
	}

	return (
		<>
			<Authenticator.SignUp.FormFields />

			<CompanyAutocomplete
				label={I18n.get('Company')}
				placeholder={I18n.get('Search company')}
				value={signupData.company.label}
				required={true}
				labelHidden={false}
				onChange={handleSignupDataChange('company')}
				onClear={handleSignupDataClear('company')}
				onSelect={handleSignupDataSelect('company')}
				onBlur={handleSignupDataBlur('company')}
			/>

			<Box my={2}>
				<CheckboxField
					name="acknowledgement"
					value="yes"
					label={I18n.get('I agree with the Terms & Conditions')}
				/>
			</Box>

			<Box my={2} gridGap={16} display={'flex'}>
				<Link href="https://www.entercheck.eu/terms-of-service/" target={'_blank'}>
					{I18n.get('Terms of Service')}
				</Link>

				<Link href="https://www.entercheck.eu/privacy-policy/" target={'_blank'}>
					{I18n.get('Privacy policy')}
				</Link>
			</Box>

			<TextField name="custom:org" hidden={true} display={'none'} value={uuid} readOnly />
			<TextField
				name="custom:orgName"
				hidden={true}
				display={'none'}
				value={organisationName}
				readOnly
			/>
			<TextField
				name="custom:company"
				hidden={true}
				display={'none'}
				value={signupData.company.value}
				readOnly
			/>
			<TextField name="locale" hidden={true} display={'none'} value={'FI'} readOnly />
		</>
	)
}

export default SignupFields
