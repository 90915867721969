import React, { useEffect } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import Auth from '../../components/auth/Auth'
import { useNavigate } from 'react-router'

const Login = () => {
	const { route } = useAuthenticator((context) => [context.route])
	const navigate = useNavigate()

	useEffect(() => {
		if (route === 'signUp') {
			navigate('/register')
		}
	}, [route])

	return <Auth />
}

export default Login
