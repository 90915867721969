import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { SMARTFORM_API_URL } from '../../../../config/constants'
import { SnackbarContext } from '../../../../context'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const WorkflowDetailDelete = ({ promptOpen, setPromptOpen, name }) => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState(false)
	const params = useParams()
	const { showMessage } = useContext(SnackbarContext)
	const navigate = useNavigate()

	const handleDelete = async (e) => {
		setLoading(true)
		setPromptOpen(false)

		try {
			await axios.delete(`${SMARTFORM_API_URL}/${params.id}`, {
				data: {}
			})

			navigate('/workflows')
		} catch (e) {
			console.log(e)
			showMessage(t('workflow.update.error'), 'error')
		}

		setLoading(false)
	}

	const handleClosePrompt = () => {
		setPromptOpen(false)
	}

	return (
		<Dialog
			fullScreen={false}
			open={promptOpen}
			onClose={handleClosePrompt}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle id="responsive-dialog-title" disableTypography={true}>
				<Typography variant={'h4'}>
					{t('workflow.delete.confirm', { name: name })}
				</Typography>
			</DialogTitle>
			<DialogActions>
				<Button autoFocus onClick={handleClosePrompt} color="primary" disabled={loading}>
					{t('workflow.delete.confirm.disagree')}
				</Button>
				<Button onClick={handleDelete} color="secondary" autoFocus disabled={loading}>
					{t('workflow.delete.confirm.agree')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default WorkflowDetailDelete
