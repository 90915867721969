import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { SMARTFORM_API_URL } from '../../../config/constants'
import { Box, Grid, LinearProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Error from '../../../layouts/Main/components/Error'
import { useParams } from 'react-router-dom'
import { SmartFormResponseEvents } from './components'
import SmartFormResponseMainInfo from './components/SmartFormResponseMainInfo'
import SmartFormResponseFormDetails from './components/SmartFormResponseFormDetails'
import { PageContext } from '../../../context/PageContext'

const WorkflowEventsDetail = () => {
	const { t } = useTranslation()

	const params = useParams()

	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const [response, setResponse] = useState([])

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({ name: 'workflow.responses', backLink: '/events' })
	}, [])

	useEffect(() => {
		;(async () => {
			setLoading(true)

			try {
				const { data } = await axios.get(`${SMARTFORM_API_URL}/responses/${params.id}`)

				setResponse(data)
			} catch (e) {
				setError(true)
			}

			setLoading(false)
		})()
	}, [params.id])

	return (
		<>
			{error ? (
				<Error />
			) : loading ? (
				<LinearProgress />
			) : (
				<>
					<Box mb={4}>
						<SmartFormResponseEvents events={response.events} />
					</Box>

					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<SmartFormResponseMainInfo data={response} />
						</Grid>

						<Grid item xs={12} md={6}>
							<SmartFormResponseFormDetails data={response} />
						</Grid>
					</Grid>
				</>
			)}
		</>
	)
}

export default WorkflowEventsDetail
