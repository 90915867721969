import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import {
	Button,
	Card,
	CardHeader,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core'
import { CompanyPersonForm } from './index'
import CompanyPerson from './CompanyPerson'
import { ContactMailOutlined, AddCircleOutline } from '@material-ui/icons'

const CompanyPersons = ({
	id,
	data,
	loadPersons,
	businessId,
	refreshing,
	decisionData,
	country
}) => {
	const { t } = useTranslation()

	const [modalOpen, setModalOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleAdd = async () => {
		setModalOpen(true)
	}

	const handleModalClose = () => {
		setModalOpen(false)
	}

	return (
		<>
			{modalOpen && (
				<CompanyPersonForm
					id={id}
					handleModalClose={handleModalClose}
					businessId={businessId}
					loadPersons={loadPersons}
					loading={loading}
					setLoading={setLoading}
					country={country}
				/>
			)}

			<Card component={Paper}>
				<CardHeader
					title={t('contacts')}
					avatar={<ContactMailOutlined />}
					action={
						<Button
							color={'primary'}
							variant={'outlined'}
							onClick={handleAdd}
							disabled={refreshing}
							startIcon={<AddCircleOutline fontSize={'small'} />}
						>
							{t('add')}
						</Button>
					}
				/>

				{loading ? (
					<LinearProgress />
				) : (
					<>
						{data && data.length > 0 && (
							<>
								<TableContainer>
									<Table
										classes={{
											root: 'secondary'
										}}
									>
										<TableHead>
											<TableRow>
												<TableCell>{t('name')}</TableCell>
												<TableCell>{t('phone')}</TableCell>
												<TableCell>{t('email')}</TableCell>
												<TableCell>
													{t('company.person.verificationStatus')}
												</TableCell>
												<TableCell>{t('created_at')}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((person, i) => {
												return (
													<CompanyPerson
														person={person}
														decisionData={decisionData}
														key={'person-' + i}
													/>
												)
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						)}
					</>
				)}
			</Card>
		</>
	)
}

export default CompanyPersons
