import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		paddingBottom: theme.spacing(4)
	},
	logo: {
		paddingTop: theme.spacing(3.25),
		paddingLeft: theme.spacing(3),
		paddingBottom: theme.spacing(2)
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.text.primary
	},
	list: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		// marginTop: theme.spacing(7),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0
		}
	},
	nestedList: {
		paddingLeft: theme.spacing(1.5),
		marginLeft: theme.spacing(3),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		'&::before': {
			content: '""',
			display: 'block',
			borderLeft: `3px solid ${theme.palette.primary.main}`,
			top: 0,
			left: 0,
			height: '100%',
			// marginLeft: '-12px',
			position: 'absolute',
			opacity: 0.5
		}
	},
	listItemText: {
		color: 'inherit',
		justifyContent: 'flex-start',
		letterSpacing: 0,
		fontSize: 'inherit',
		fontWeight: 500
	},
	icon: {
		minWidth: '24px',
		color: 'inherit'
	},
	itemNested: {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.fontSize - 2,
		padding: theme.spacing(1)
	},
	root: {
		color: theme.palette.text.secondary,
		fontWeight: 'medium',
		borderRadius: '6px',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(0.5),
		gap: theme.spacing(1),
		fontSize: theme.typography.fontSize,
		'&::before': {
			content: '""',
			display: 'block',
			borderLeft: `3px solid ${theme.palette.primary.main}`,
			height: '36px',
			marginLeft: theme.spacing(2) * -1,
			position: 'absolute',
			opacity: 0,
			transition: 'opacity 0.4s'
		},
		'&$focusVisible': {
			backgroundColor: theme.palette.action.selected
		},
		'&$selected': {
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: 'transparent'
			},
			'&::before': {
				opacity: 1
			}
		},
		'&:hover': {
			backgroundColor: 'transparent',
			'&::before': {
				opacity: 0.5
			}
		},
		'&$disabled': {
			opacity: 0.5
		}
	},
	itemMargin: {
		marginTop: 'auto'
	},
	selected: {
		fontWeight: 'bold',
		color: theme.palette.primary.main,
		'&::before': {
			opacity: 1
		}
	}
}))

export default useStyles
