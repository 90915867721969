const formatCurrency = (number) => {
	if (number !== null && number !== undefined) {
		if (number === 0) {
			return new Intl.NumberFormat('fi-FI', {
				style: 'currency',
				currency: 'EUR',
			}).format(0);
		} else {
			number = parseInt(number.toString() + '000');
			return new Intl.NumberFormat('fi-FI', {
				style: 'currency',
				currency: 'EUR',
			}).format(number);
		}
	} else {
		return '';
	}
};

export default formatCurrency
