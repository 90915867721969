import React, { useContext, useEffect, useState } from 'react'
import { PageContext } from '../../../context/PageContext'
import axios from '../../../utils/axios'
import { API_URL, PAGE_SIZE } from '../../../config/constants'
import { SnackbarContext, UserContext } from '../../../context'
import {
	Button,
	Grid,
	IconButton,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip
} from '@material-ui/core'
import formatDate from '../../../utils/formatDate'
import { useTranslation } from 'react-i18next'
import { Archive, Delete, Drafts, Mail } from '@material-ui/icons'
import useStyles from './Notifications.styles'
import { Link } from 'react-router-dom'

const Notifications = () => {
	const classes = useStyles()

	const { user } = useContext(UserContext)
	const { t } = useTranslation()

	const { setPageConfig } = useContext(PageContext)

	const { showMessage } = useContext(SnackbarContext)
	const { setUser } = useContext(UserContext)

	const [loading, setLoading] = useState(true)
	const [loadingAction, setLoadingAction] = useState(false)
	const [notifications, setNotifications] = useState([])
	const [loadFinished, setLoadFinished] = useState(false)
	const [offset, setOffset] = useState(0)

	useEffect(() => {
		setPageConfig({
			name: 'nav.notifications'
		})
	}, [])

	useEffect(() => {
		axios
			.get(`${API_URL}/v3/notification`, {
				params: {
					organization_id: `eq.${user.organizationId}`,
					archived_at: 'is.null',
					order: 'created_at.desc',
					offset: `${offset}`,
					limit: `${PAGE_SIZE}`
				}
			})
			.then(({ data }) => {
				setNotifications([...notifications, ...data])

				if (data.length < PAGE_SIZE) {
					setLoadFinished(true)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}, [offset])

	const archiveNotification = (notification) => async () => {
		setLoadingAction(true)
		try {
			await axios.patch(
				`${API_URL}/v3/notification`,
				{
					archived_at: new Date().toISOString(),
					read_at: notification.read_at || new Date().toISOString()
				},
				{
					params: {
						id: `eq.${notification.id}`
					}
				}
			)
			setNotifications(notifications.filter((item) => item.id !== notification.id))

			if (!notification.read_at) {
				setUser({
					...user,
					notifications: user.notifications - 1
				})
			}
		} catch (e) {
			showMessage(t('user.update.error'), 'error')
		}

		setLoadingAction(false)
	}

	const toggleReadNotification = (notification) => async () => {
		setLoadingAction(true)

		const readAtValue = notification.read_at ? null : new Date().toISOString()

		try {
			await axios.patch(
				`${API_URL}/v3/notification`,
				{
					read_at: readAtValue
				},
				{
					params: {
						id: `eq.${notification.id}`
					}
				}
			)

			setNotifications(
				notifications.map((item) => ({
					...item,
					read_at: item.id === notification.id ? readAtValue : item.read_at
				}))
			)

			setUser({
				...user,
				notifications: readAtValue ? user.notifications - 1 : user.notifications + 1
			})
		} catch (e) {
			showMessage(t('user.update.error'), 'error')
		}

		setLoadingAction(false)
	}

	const deleteNotification = (notification) => async () => {
		setLoadingAction(true)

		try {
			await axios.delete(`${API_URL}/v3/notification`, {
				params: {
					id: `eq.${notification.id}`
				}
			})

			setNotifications(notifications.filter((item) => item.id !== notification.id))

			if (!notification.read_at) {
				setUser({
					...user,
					notifications: user.notifications - 1
				})
			}
		} catch (e) {
			showMessage(t('user.update.error'), 'error')
		}

		setLoadingAction(false)
	}

	return (
		<TableContainer>
			<Table
				aria-label="Notifications Table"
				classes={{
					root: 'primary'
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell> {t('notifications.name')} </TableCell>
						<TableCell> {t('notifications.text')} </TableCell>
						<TableCell> {t('notifications.type')} </TableCell>
						<TableCell> {t('createdAt')} </TableCell>
						<TableCell> {t('notifications.read')} </TableCell>
						<TableCell> {t('notifications.archive')} </TableCell>
						<TableCell> {t('notifications.delete')} </TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{notifications.map((result, i) => (
						<TableRow
							key={`result-table-row-${i}`}
							className={`${result.read_at ? classes.highlighted : ''}`}
						>
							<TableCell>
								<Link
									to={
										result.company_id
											? `/companies/${result.company_id}`
											: `/persons/${result.person_id}`
									}
								>
									{result.company_name || result.person_name}
								</Link>
							</TableCell>
							<TableCell>{result.notification_text}</TableCell>
							<TableCell>{result.notification_type}</TableCell>

							<TableCell>{formatDate(result.created_at)}</TableCell>

							<TableCell>
								<Tooltip
									title={t(
										`notifications.tooltip.${result.read_at ? 'unread' : 'read'}`
									)}
								>
									<IconButton
										aria-label="read"
										onClick={toggleReadNotification(result)}
										disabled={loadingAction}
									>
										{result.read_at ? (
											<Mail fontSize="small" />
										) : (
											<Drafts fontSize="small" />
										)}
									</IconButton>
								</Tooltip>
							</TableCell>
							<TableCell>
								<IconButton
									aria-label="archive"
									onClick={archiveNotification(result)}
									disabled={loadingAction}
								>
									<Archive fontSize="small" />
								</IconButton>
							</TableCell>
							<TableCell>
								<IconButton
									aria-label="delete"
									onClick={deleteNotification(result)}
									disabled={loadingAction}
								>
									<Delete fontSize="small" />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					{!loadFinished && (
						<TableRow>
							<TableCell colSpan={7}>
								<Grid container direction="column" alignItems="center">
									{loading && <LinearProgress />}
									<Button
										disabled={loading}
										variant="outlined"
										color="primary"
										onClick={() => setOffset(offset + PAGE_SIZE)}
									>
										{t('load_more')}
									</Button>
								</Grid>
							</TableCell>
						</TableRow>
					)}
				</TableFooter>
			</Table>
		</TableContainer>
	)
}

export default Notifications
