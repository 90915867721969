import { useContext, useEffect, useState } from 'react'
import CompanyListTable from './CompanyListTable'
import { Box } from '@material-ui/core'
import Error from '../../../layouts/Main/components/Error'
import { PageContext } from '../../../context/PageContext'

const CompanyList = () => {
	const [error, setError] = useState(false)
	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'company_list'
		})
	}, [])

	return <Box> {error ? <Error /> : <CompanyListTable setError={setError} />}</Box>
}

export default CompanyList
