import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CompanyDataNotAvailable = ({ lastUpdatedAt, isRemarks }) => {
	const { t } = useTranslation()

	return (
		<>
			<Box display={'flex'} justifyContent={'center'}>
				<Typography>
					{t(
						!!lastUpdatedAt
							? `company.${isRemarks ? 'remarks' : 'info'}.notFound`
							: `company.${isRemarks ? 'remarks' : 'info'}.notRetrieved`
					)}
				</Typography>
			</Box>
		</>
	)
}

export default CompanyDataNotAvailable
