import React, { useCallback, useEffect, useState } from 'react'
import { API_URL } from '../config/constants'
import { Autocomplete } from '@aws-amplify/ui-react'
const { REACT_APP_ENTERCHECK_API_KEY } = process.env

const CompanyAutocomplete = (props) => {
	const [inputValue, setInputValue] = useState('')
	const [companies, setCompanies] = useState([])
	const [searching, setSearching] = useState(false)

	const searchCompanies = async (companyName, signal) => {
		const finishCompanySearching = () => {
			setSearching(false)
		}

		if (companyName.length < 3) {
			finishCompanySearching()
			setCompanies([])

			return
		}

		setSearching(true)

		const headers = new Headers()
		headers.append('x-api-key', `${REACT_APP_ENTERCHECK_API_KEY}`)

		try {
			const response = await fetch(
				`${API_URL}/portal/search?country=fi&name=${encodeURIComponent(companyName)}`,
				{
					headers,
					signal
				}
			)

			if (response.ok) {
				const data = await response.json()
				setCompanies(data)
				console.log('Search results:', data) // Log the search results to the console
				finishCompanySearching()
			} else {
				finishCompanySearching()
				setCompanies([])
			}
		} catch (error) {
			if (!signal?.aborted) {
				console.error('Error during fetch:', error)
				finishCompanySearching()
				setCompanies([])
			}
		}
	}

	// Call searchCompanies on input change
	useEffect(() => {
		const abortController = new AbortController()
		const { signal } = abortController
		const timer = setTimeout(() => {
			searchCompanies(inputValue, signal)
		}, 550)
		return () => {
			abortController.abort()
			clearTimeout(timer)
		}
	}, [inputValue])

	const handleInputChange = useCallback(
		(event) => {
			setInputValue(event.target.value)
			if (props.onChange) {
				props.onChange(event)
			}
		},
		[props]
	)

	// Transform companies to options format
	const options = companies.map((company) => ({
		id: company.businessId,
		label: company.name
	}))

	return (
		<Autocomplete
			{...props}
			onChange={handleInputChange}
			isLoading={searching}
			options={options}
		/>
	)
}

export default CompanyAutocomplete
