import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	FormControlLabel,
	Grid,
	Switch,
	TextField
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import axios from '../../../../utils/axios'
import { SMARTFORM_API_URL } from '../../../../config/constants'
import { useTranslation } from 'react-i18next'
import { SnackbarContext } from '../../../../context'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'

const WorkflowSettingsDetailBaseForm = ({ form, setForm, recipients, tasks, setPromptOpen }) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const params = useParams()

	const [saving, setSaving] = useState(false)
	const navigate = useNavigate()

	const handleInputChange = (prop) => (e) => {
		setForm({ ...form, [prop]: e.target.value })
	}

	const handleSwitchChange = (prop) => (e) => {
		setForm({ ...form, [prop]: e.target.checked })
	}

	const handleUpdateForm = async (e) => {
		e.preventDefault()

		setSaving(true)

		try {
			let id = params.id
			if (params.id) {
				await axios.put(`${SMARTFORM_API_URL}/${params.id}`, {
					...form,
					fixedNotificationRecipients: recipients
				})
			} else {
				const { data } = await axios.post(`${SMARTFORM_API_URL}`, {
					...form,
					fixedNotificationRecipients: recipients,
					tasks
				})

				id = data.uuid
			}

			showMessage(t('workflow.save.success'), 'success')

			if (!params.id) {
				navigate(`/workflows/${id}`)
			}
		} catch (e) {
			console.log(e)
			showMessage(t('workflow.save.error'), 'error')
		}

		setSaving(false)
	}

	return (
		<Card
			classes={{
				root: 'primary'
			}}
		>
			<CardHeader title={t('workflow')} />

			{form && (
				<form autoComplete="off" onSubmit={handleUpdateForm}>
					<CardContent>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={12} sm={4}>
								<TextField
									type={'text'}
									label={t('name')}
									variant="outlined"
									required
									fullWidth={true}
									onChange={handleInputChange('name')}
									value={form.name}
									size={'small'}
								/>
							</Grid>

							<Grid item xs={12} sm={1}>
								<FormControlLabel
									value="top"
									control={
										<Switch
											checked={form.notificationsEnabled}
											onChange={handleSwitchChange('notificationsEnabled')}
											color="primary"
											value={true}
										/>
									}
									label={t('workflow.notifications')}
									labelPlacement="top"
								/>
							</Grid>
							<Grid item xs={12} sm={1}>
								<FormControlLabel
									value="top"
									control={
										<Switch
											checked={form.active}
											onChange={handleSwitchChange('active')}
											color="primary"
											value={true}
										/>
									}
									label={t('enabled')}
									labelPlacement="top"
								/>
							</Grid>
						</Grid>
					</CardContent>

					{params.id ? (
						<CardActions>
							<Button
								type={'submit'}
								variant="contained"
								color="primary"
								disabled={saving}
							>
								{t('workflow.update')}
							</Button>

							<Button
								type={'button'}
								variant="outlined"
								color="secondary"
								disabled={saving}
								onClick={() => setPromptOpen(true)}
							>
								{t('workflow.delete')}
							</Button>
						</CardActions>
					) : (
						<CardActions>
							<Button
								type={'submit'}
								variant="contained"
								color="primary"
								disabled={saving || (!params.id && tasks.length === 0)}
							>
								{t('workflow.create')}
							</Button>
						</CardActions>
					)}
				</form>
			)}
		</Card>
	)
}

export default WorkflowSettingsDetailBaseForm
