import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	button: {
		display: 'flex',
		justifyContent: 'center',
		gap: '12px',
		padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
		border: `1px solid ${theme.palette.primary.main}`,
		color: `${theme.palette.primary.main} !important`,
		borderRadius: theme.shape.borderRadius
	},
	link: {
		display: 'flex',
		justifyContent: 'center',
		gap: '12px',
		color: `${theme.palette.primary.main} !important`,
		borderBottom: `2px solid ${theme.palette.primary.main}`
	},
	header: {
		fontSize: '40px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '30px'
		}
	}
}))

export default useStyles
