import React, { useContext, useEffect, useState } from 'react'

import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'

import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	TableSortLabel,
	Grid,
	Button,
	IconButton,
	TextField,
	InputAdornment,
	Box
} from '@material-ui/core'

import Error from '../../../layouts/Main/components/Error'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@material-ui/icons/Search'
import DecisionListRow from './DecisionListRow'
import { PageContext } from '../../../context/PageContext'
import { UserContext } from '../../../context'
import camelcaseKeys from 'camelcase-keys'

const DecisionList = () => {
	const { user } = useContext(UserContext)

	const { t } = useTranslation()

	const [results, setResults] = useState([])
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const [loadFinished, setLoadFinished] = useState(false)
	const [page, setPage] = useState(0)
	const [filters, setFilters] = useState({
		orderBy: 'created_at',
		order: 'desc',
		rowsPerPage: 20,
		keyword: ''
	})
	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({ name: 'nav.decisions' })
	}, [])

	useEffect(() => {
		loadMore()
	}, [filters])

	const loadMore = () => {
		setLoading(true)
		axios
			.get(`${API_URL}/v3/decision_report`, {
				params: {
					organization_id: `eq.${user.organizationId}`,
					decision_name: `ilike.*${filters.keyword}*`,
					order: `${filters.orderBy}.${filters.order}`,
					offset: page * filters.rowsPerPage,
					limit: filters.rowsPerPage
				}
			})
			.then(({ data }) => {
				setResults(results.concat(camelcaseKeys(data, { deep: true })))

				setPage(page + 1)

				if (data.length < filters.rowsPerPage) {
					setLoadFinished(true)
				}
			})
			.catch((e) => {
				setError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const sortResults = (field, company = false) => {
		setResults([])

		let order = filters.order
		if (filters.orderBy === field) {
			if (order === 'desc') {
				order = 'asc'
			} else {
				order = 'desc'
			}
		} else {
			order = 'asc'
		}

		setPage(0)
		setFilters({ ...filters, order: order, orderBy: field })
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0] ? event.target[0].value : event.target.value
		if (value !== filters.keyword) {
			setPage(0)
			setResults([])
			setFilters({
				...filters,
				keyword: value
			})
		}
	}

	const headCells = [
		{ id: 'decision_name', company: true, label: t('decision.name'), width: 13.3 },
		{ id: 'company_name', company: true, label: t('company_name'), width: 13.3 },
		{ id: 'result', label: t('result'), width: 12 },
		{ id: 'created_by', label: t('created_by'), width: 12 },
		{ id: 'created_at', label: t('date'), width: 12 }
	]

	return (
		<>
			{error ? (
				<Error />
			) : (
				<>
					<Box pb={3}>
						<form onSubmit={handleSearchFormSubmit}>
							<TextField
								label={t('decision.search.placeholder')}
								id="search"
								variant={'outlined'}
								size={'small'}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												type="submit"
												className="iconButton"
												aria-label="search"
											>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</form>
					</Box>

					<TableContainer>
						<Table
							aria-label="Results Table"
							classes={{
								root: 'primary'
							}}
						>
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											width={`${headCell.width}%`}
											key={headCell.id}
											sortDirection={
												filters.orderBy === headCell.id
													? filters.order
													: false
											}
										>
											<TableSortLabel
												active={filters.orderBy === headCell.id}
												direction={
													filters.orderBy === headCell.id
														? filters.order
														: 'desc'
												}
												onClick={() => {
													sortResults(headCell.id, headCell.company)
												}}
											>
												{headCell.label}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{results.map((result, i) => (
									<DecisionListRow
										result={result}
										key={`result-table-row-${i}`}
									/>
								))}

								{loading && (
									<TableRow>
										<TableCell colSpan={headCells.length + 1}>
											<LinearProgress />
										</TableCell>
									</TableRow>
								)}
							</TableBody>
							<TableFooter>
								{!loadFinished && (
									<TableRow>
										<TableCell colSpan={headCells.length + 1}>
											<Grid
												container
												justifyContent="center"
												direction="column"
												alignItems="center"
											>
												<Button
													disabled={loading}
													variant="outlined"
													color="primary"
													onClick={loadMore}
												>
													{t('load_more')}
												</Button>
											</Grid>
										</TableCell>
									</TableRow>
								)}
							</TableFooter>
						</Table>
					</TableContainer>
				</>
			)}
		</>
	)
}

export default DecisionList
