import {
	TextField,
	Grid,
	Button,
	Box,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Paper
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const WorkflowSettingsDetailRecipients = ({ recipients, setRecipients }) => {
	const { t } = useTranslation()

	const handleRecipientInputChange = (prop, index) => (e) => {
		const tmp = [...recipients]
		tmp[index] = { ...tmp[index], [prop]: e.target.value }
		setRecipients([...tmp])
	}

	const handleAddRecipient = () => {
		setRecipients([
			...recipients,
			{
				email: '',
				template: ''
			}
		])
	}

	const handleRemoveRecipient = (index) => () => {
		const tmp = [...recipients]
		tmp.splice(index, 1)
		setRecipients(tmp)
	}

	return (
		<Box mt={4}>
			<Card
				component={Paper}
				classes={{
					root: 'primary'
				}}
			>
				<CardHeader title={t('workflow.recipients')} />

				{!!recipients.length && (
					<CardContent>
						{recipients.map((recipient, index) => (
							<Box key={`repeatable-item-${index}`} mt={2}>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={12} sm={4}>
										<TextField
											type={'email'}
											label={t('workflow.recipient.email')}
											variant="outlined"
											required
											fullWidth={true}
											value={recipient.email}
											onChange={handleRecipientInputChange('email', index)}
											size={'small'}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											type={'text'}
											label={t('workflow.recipient.template')}
											variant="outlined"
											required
											fullWidth={true}
											value={recipient.name}
											onChange={handleRecipientInputChange('template', index)}
											size={'small'}
										/>
									</Grid>
									<Grid item xs={12} sm={1}>
										<Button
											variant="outlined"
											color={'secondary'}
											onClick={handleRemoveRecipient(index)}
										>
											{t('workflow.recipient.remove')}
										</Button>
									</Grid>
								</Grid>
							</Box>
						))}
					</CardContent>
				)}

				<CardActions>
					<Button variant="outlined" color={'primary'} onClick={handleAddRecipient}>
						{t('workflow.recipient.add')}
					</Button>
				</CardActions>
			</Card>
		</Box>
	)
}

export default WorkflowSettingsDetailRecipients
