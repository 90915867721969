import { Box, Card, CardContent, CardHeader, LinearProgress, Paper } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../../utils/axios'
import { COMPANIES_API_URL } from '../../../../config/constants'
import { SnackbarContext } from '../../../../context'

import CompanyDataNotAvailable from '../../../../components/shared/CompanyDataNotAvailable'
import UpdateStatus from '../../../../components/update-status/UpdateStatus'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { TrendingUpOutlined } from '@material-ui/icons'

const CompanyKeyFigures = ({
	id,
	lastUpdatedAt,
	data,
	afterDataRefresh,
	refreshing,
	setRefreshing,
	isConsolidated
}) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [loadingItem, setLoadingItem] = useState(false)
	const [tableData, setTableData] = useState(null)

	const maxYears = 5

	useEffect(() => {
		if (data) {
			let tmp = data.sort(
				(a, b) =>
					new Date(
						a.fiscalPeriodEndDate[0],
						a.fiscalPeriodEndDate[1] - 1,
						a.fiscalPeriodEndDate[2]
					).getFullYear() <
					new Date(
						b.fiscalPeriodEndDate[0],
						b.fiscalPeriodEndDate[1] - 1,
						b.fiscalPeriodEndDate[2]
					).getFullYear()
			)

			tmp = tmp.filter((data) => data.isConsolidated === !!isConsolidated)
			tmp = tmp.map((item) => ({
				...item,
				year: item.fiscalPeriodEndDate[0]
			}))

			setTableData(tmp.slice(0, maxYears).reverse())
		} else {
			setTableData(null)
		}
	}, [data])

	const handleRefresh = async () => {
		setLoadingItem(true)
		setRefreshing(true)

		try {
			await axios.get(`${COMPANIES_API_URL}/${id}/figures`)
			await afterDataRefresh()
		} catch (error) {
			showMessage(t('error'), 'error')
		}

		setLoadingItem(false)
	}

	return (
		<>
			{(!isConsolidated || (isConsolidated && tableData && tableData.length > 0)) && (
				<Card component={Paper}>
					<CardHeader
						title={t(
							isConsolidated
								? 'company_key_figures_title.consolidated'
								: 'company_key_figures_title'
						)}
						action={
							<UpdateStatus
								updatedAt={lastUpdatedAt}
								handleUpdate={handleRefresh}
								loading={loadingItem || refreshing}
							/>
						}
						avatar={<TrendingUpOutlined />}
					/>

					{loadingItem ? (
						<LinearProgress />
					) : !tableData?.length ? (
						<CardContent>
							<CompanyDataNotAvailable lastUpdatedAt={lastUpdatedAt} />
						</CardContent>
					) : (
						<>
							{tableData && tableData.length !== 0 && (
								<>
									<CardContent>
										<Box
											style={{
												height: '300px'
											}}
										>
											<ResponsiveContainer width="100%" height="100%">
												<BarChart data={tableData}>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis
														dataKey="year"
														stroke={'#a7a7b0'}
														tick={{ fontSize: 12 }}
													/>
													<YAxis
														stroke={'#a7a7b0'}
														axisLine={false}
														tick={{ fontSize: 12 }}
													/>
													<Tooltip
														contentStyle={{
															backgroundColor:
																'rgba(255, 255, 255, 0.96)'
														}}
														itemStyle={{ color: '#a7a7b0' }}
														cursor={{
															fill: 'rgba(167, 167, 176, 0.2)'
														}}
													/>
													<Bar
														dataKey={
															isConsolidated ? 'result' : 'revenue'
														}
														fill={
															isConsolidated
																? '#56D29E7A'
																: '#5664D27A'
														}
														activeBar={false}
													/>
												</BarChart>
											</ResponsiveContainer>
										</Box>
									</CardContent>
								</>
							)}
						</>
					)}
				</Card>
			)}
		</>
	)
}

export default CompanyKeyFigures
