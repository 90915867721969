import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../../utils/axios'
import { COMPANIES_API_URL } from '../../../../config/constants'
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core'
import formatCurrency from '../../../../utils/formatCurrency'
import { useTranslation } from 'react-i18next'
import getSortedDecisionData from '../../../../utils/getSortedDecisionData'
import { SnackbarContext } from '../../../../context'
import TableCellWithTooltip from '../../../../components/shared/TableCellWithTooltip'

import useStyles from './styles'
import CompanyDataNotAvailable from '../../../../components/shared/CompanyDataNotAvailable'
import UpdateStatus from '../../../../components/update-status/UpdateStatus'

import { InfoOutlined } from '@material-ui/icons'

const CompanyRating = ({
	id,
	lastUpdatedAt,
	data,
	afterDataRefresh,
	decisionData,
	refreshing,
	setRefreshing
}) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const classes = useStyles()

	const [creditDecision, setCreditDecision] = useState()
	const [open, setOpen] = useState(true)
	const [loadingItem, setLoadingItem] = useState(false)

	useEffect(() => {
		if (decisionData && decisionData.length) {
			const tmp = getSortedDecisionData(decisionData)
			const creditDecision = tmp.find(
				(decision) => decision?.data?.name?.toLocaleLowerCase() === 'company credit'
			)

			if (creditDecision) {
				setCreditDecision(creditDecision)
			}
		}
	}, [decisionData])

	const handleRatingRefresh = async () => {
		setLoadingItem(true)
		setRefreshing(true)

		try {
			await axios.get(`${COMPANIES_API_URL}/${id}/rating`)

			await afterDataRefresh()
		} catch (error) {
			console.log(error)
			showMessage(t('error'), 'error')
		}

		setLoadingItem(false)
		setRefreshing(false)
	}

	return (
		<Grid item xs={12}>
			<Card>
				<CardHeader
					title={t('rating_data')}
					avatar={<InfoOutlined />}
					action={
						<UpdateStatus
							updatedAt={lastUpdatedAt}
							handleUpdate={handleRatingRefresh}
							loading={loadingItem || refreshing}
						/>
					}
				/>

				{loadingItem ? (
					<LinearProgress />
				) : !data ? (
					<CardContent>
						<CompanyDataNotAvailable lastUpdatedAt={lastUpdatedAt} />
					</CardContent>
				) : (
					<>
						{data && (
							<CardContent>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell variant="head" width="25%">
												{t('company.credit.decision.assesment')}
											</TableCell>
											<TableCell
												colSpan={2}
												className={
													creditDecision &&
													creditDecision.data &&
													creditDecision.data.result &&
													(creditDecision.data.result.result === true
														? classes.textSuccess
														: creditDecision.data.result.result ===
															  false
															? classes.textDanger
															: '')
												}
											>
												{creditDecision
													? creditDecision.data?.result?.result === true
														? t(
																'company.credit.decision.assesment.true'
															)
														: creditDecision.data?.result?.result ===
															  false
															? t(
																	'company.credit.decision.assesment.false'
																)
															: t(
																	'company.credit.decision.notCompleted'
																)
													: t('company.credit.decision.notCompleted')}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head" width="25%">
												<TableCellWithTooltip
													title={'company.rating.limit'}
													info={`company.rating.limit.info`}
												/>
											</TableCell>
											<TableCell>
												{data.limit !== undefined && data.limit !== null
													? formatCurrency(data.limit)
													: '-'}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head">
												<TableCellWithTooltip
													title={'company.rating.bisnode_rating'}
													info={`company.rating.bisnode_rating.info`}
												/>
											</TableCell>
											<TableCell>{data.rating}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head">
												{t('company.rating.nr_of_credit_defaults')}
											</TableCell>
											<TableCell>{data.nrOfCreditDefaults}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head">
												{t('company.rating.payment_behavior')}
											</TableCell>
											<TableCell>{data.paymentBehavior}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head">
												<TableCellWithTooltip
													title={t('company.rating.rating_finance')}
													info={`company.rating.rating_finance.info`}
												/>
											</TableCell>
											<TableCell>{t(data.ratingFinance)}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head">
												<TableCellWithTooltip
													title={t('company.rating.rating_backround')}
													info={`company.rating.rating_backround.info`}
												/>
											</TableCell>
											<TableCell>{t(data.ratingBackround)}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head">
												<TableCellWithTooltip
													title={t('company.rating.rating_operation')}
													info={`company.rating.rating_operation.info`}
												/>
											</TableCell>
											<TableCell>{t(data.ratingOperation)}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant="head">
												<TableCellWithTooltip
													title={t(
														'company.rating.rating_ability_to_pay'
													)}
													info={`company.rating.rating_ability_to_pay.info`}
												/>
											</TableCell>
											<TableCell>{t(data.ratingAbilityToPay)}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</CardContent>
						)}
					</>
				)}
			</Card>
		</Grid>
	)
}

export default CompanyRating
