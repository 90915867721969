import {
	Card,
	CardHeader,
	Chip,
	CardContent,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../../../../utils/axios'
import { COMPANIES_API_URL } from '../../../../config/constants'
import formatDate from '../../../../utils/formatDate'
import { SnackbarContext } from '../../../../context'
import CompanyDataNotAvailable from '../../../../components/shared/CompanyDataNotAvailable'
import UpdateStatus from '../../../../components/update-status/UpdateStatus'
import { SupervisorAccountOutlined } from '@material-ui/icons'

const CompanyManagement = ({
	id,
	lastUpdatedAt,
	data,
	// setData,
	afterDataRefresh,
	refreshing,
	setRefreshing
}) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [loading, setLoading] = useState(false)

	const [persons, setPersons] = useState(null)

	useEffect(() => {
		let tmp =
			data?.filter(
				(item) =>
					!item.roleEndDate ||
					new Date(
						item.roleEndDate[0],
						item.roleEndDate[1],
						item.roleEndDate[2]
					).getTime() > Date.now()
			) || null

		setPersons(
			tmp
				? tmp.map((item) => {
						item.isNew = isNew(item.role?.roleStartDate)
						return item
					})
				: null
		)
	}, [data])

	const handleRefresh = async () => {
		setLoading(true)
		setRefreshing(true)

		try {
			await axios.get(`${COMPANIES_API_URL}/${id}/representatives`)
			await afterDataRefresh()
			// await setData(res.data)
		} catch (e) {
			console.log('e', e)
			showMessage(t('error'), 'error')
		}

		setLoading(false)
		setRefreshing(false)
	}

	const isNew = (startDate) => {
		if (!startDate) return null

		startDate = new Date(startDate)
		const now = new Date()

		return (
			now.getMonth() -
				startDate.getMonth() +
				12 * (now.getFullYear() - startDate.getFullYear()) <=
			1
		)
	}

	return (
		<Card component={Paper}>
			<CardHeader
				title={t('management')}
				avatar={<SupervisorAccountOutlined />}
				action={
					<UpdateStatus
						updatedAt={lastUpdatedAt}
						handleUpdate={handleRefresh}
						loading={loading || refreshing}
					/>
				}
			/>

			{loading ? (
				<LinearProgress />
			) : (
				<CardContent>
					{!persons?.length ? (
						<CompanyDataNotAvailable lastUpdatedAt={lastUpdatedAt} />
					) : (
						<>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>{t('name')}</TableCell>
										<TableCell>{t('position')}</TableCell>
										<TableCell>{t('persons.endDate')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{persons.map((item, i) => (
										<TableRow key={i}>
											<TableCell>
												{item.firstName} {item.lastName}
												{item.isNew && (
													<Chip
														label={t('new')}
														variant="outlined"
														color="primary"
														size="small"
													/>
												)}
											</TableCell>
											<TableCell>{t(`role.${item.role}`)}</TableCell>
											<TableCell>
												{item.roleEndDate &&
													formatDate(
														new Date(
															item.roleEndDate[0],
															item.roleEndDate[1],
															item.roleEndDate[2]
														),
														false
													)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</>
					)}
				</CardContent>
			)}
		</Card>
	)
}

export default CompanyManagement
