const API_PREFIX = 'v2'
const API_URL = `${process.env.REACT_APP_API_URL}`

const DB_API_URL = `/${API_PREFIX}/data`
const AUTH_API_URL = ``
const DECISION_API_URL = `/${API_PREFIX}/decision`
const KONTAKTO_API_URL = `/company`
const COMPANY_API_URL = `/company`
const COMPANIES_API_URL = `/companies`
const PERSONS_API_URL = `/persons`
const AML_API_URL = `/aml`
const SMARTFORM_API_URL = `/workflow`
const CONFIGURATION_API_URL = `/v3`

const LANGUAGES = {
	en: 'English',
	fi: 'Finnish',
	no: 'Norwegian'
}
const LOCALES = {
	en: 'enUS',
	fi: 'fiFI',
	no: 'noNO'
}

const DEFAULT_LANGUAGE = Object.keys(LANGUAGES)[1]

const ORDER_DIRS = {
	ASC: 'asc',
	DESC: 'desc'
}

const PAGE_SIZE = 10

const CHART_TYPE_DAY = 'day'
const CHART_TYPE_MONTH = 'month'

const CHART_TYPES = [
	[CHART_TYPE_DAY, 'Daily View'],
	[CHART_TYPE_MONTH, 'Monthly View']
]

const USER_ROLES = {
	user: 'user',
	admin: 'admin',
	support: 'support'
}

const COMPANY_ADDRESS_TYPES = {
	visit: 'visit',
	billing: 'billing',
	postal: 'postal'
}

const COMPANY_FINVOICE_ADDRESS_TYPES = {
	receiving: 'receiving',
	sending: 'sending'
}

export {
	API_URL,
	DB_API_URL,
	AUTH_API_URL,
	DECISION_API_URL,
	KONTAKTO_API_URL,
	LANGUAGES,
	DEFAULT_LANGUAGE,
	LOCALES,
	ORDER_DIRS,
	PAGE_SIZE,
	CHART_TYPE_DAY,
	CHART_TYPE_MONTH,
	CHART_TYPES,
	USER_ROLES,
	COMPANY_API_URL,
	AML_API_URL,
	SMARTFORM_API_URL,
	COMPANY_ADDRESS_TYPES,
	COMPANY_FINVOICE_ADDRESS_TYPES,
	CONFIGURATION_API_URL,
	COMPANIES_API_URL,
	PERSONS_API_URL
}
