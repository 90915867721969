import {
	Card,
	CardContent,
	CardHeader,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core'
import formatDate from '../../../../utils/formatDate'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getSortedDecisionData from '../../../../utils/getSortedDecisionData'
import useStyles from './styles'
import { MenuBookOutlined } from '@material-ui/icons'

const CompanyBasicBusinessInfo = ({ data, decisionData }) => {
	const { t } = useTranslation()

	const classes = useStyles()

	const [vatId, setVatId] = useState()
	const [baseDecision, setBaseDecision] = useState()
	const [companyAge, setCompanyAge] = useState()

	useEffect(() => {
		if (decisionData && decisionData.length) {
			const tmp = getSortedDecisionData(decisionData)
			if (tmp) {
				setBaseDecision(
					tmp.find(
						(decision) => decision.data?.name?.toLocaleLowerCase() === 'company base'
					)
				)
			}
		}
	}, [decisionData])

	useEffect(() => {
		setVatId(
			data.additionalCompanyIds.find((id) => id.idType?.toLocaleLowerCase() === 'vat')
				?.idValue
		)

		if (data.establishmentDate) {
			const today = new Date(new Date().setHours(0, 0, 0, 0))
			const establishment = new Date(data.establishmentDate)

			let days = 0
			let months = 0
			let years = today.getFullYear() - establishment.getFullYear()

			if (today.getMonth() < establishment.getMonth()) {
				if (years > 0) years--
			}

			if (today.getMonth() === establishment.getMonth()) {
			} else if (today.getMonth() < establishment.getMonth()) {
				months = 12 - (establishment.getMonth() + 1) + (today.getMonth() + 1)
			} else {
				months = today.getMonth() + 1 - (establishment.getMonth() + 1)
			}

			if (years === 0 && months === 0) {
				const day = 1000 * 60 * 60 * 24

				const diff = today.getTime() - establishment.getTime()
				days = Math.floor(diff / day)
			} else {
				if (today.getDate() > establishment.getDate()) {
					days = today.getDate() - establishment.getDate()
				} else if (today.getDate() < establishment.getDate()) {
					months = months === 0 ? 11 : months - 1
					days =
						new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate() -
						establishment.getDate() +
						today.getDate()
				}
			}

			const dateArr = []
			if (years > 0) {
				dateArr.push(t('company.age.years', { years: years }))
			}

			if (months > 0) {
				dateArr.push(t('company.age.months', { months: months }))
			}

			if (days > 0) {
				dateArr.push(t('company.age.days', { days: days }))
			}

			setCompanyAge(dateArr.join(' '))
		}
	}, [data])

	return (
		<Card component={Paper}>
			<CardHeader title={t('basic_business_info')} avatar={<MenuBookOutlined />} />

			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell variant={'head'} width="25%">
								{t('company.status')}
							</TableCell>
							<TableCell colSpan={2}>
								{t('company.status.' + data.operationalStatus)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('company_age')}</TableCell>
							<TableCell colSpan={2}>{companyAge}</TableCell>
						</TableRow>

						{data.registers?.map((register, i) => {
							return (
								<TableRow key={`registers-${i}`}>
									<TableCell variant={'head'}>{register.registerName}</TableCell>
									<TableCell width="37.5%">
										{register.registered
											? t('registery.true')
											: t('registery.false')}
									</TableCell>
									<TableCell width="37.5%">
										{register.startDate &&
											formatDate(
												new Date(
													register.startDate[0],
													register.startDate[1],
													register.startDate[2]
												),
												false
											)}
									</TableCell>
								</TableRow>
							)
						})}

						<TableRow>
							<TableCell variant={'head'}>{t('alv')}</TableCell>
							<TableCell colSpan={2}>{vatId}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	)
}

export default CompanyBasicBusinessInfo
