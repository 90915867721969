import React, { useEffect } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import Auth from '../../components/auth/Auth'
import { useNavigate } from 'react-router'

const Login = () => {
	const { route } = useAuthenticator((context) => [context.route])
	const navigate = useNavigate()

	useEffect(() => {
		if (route === 'signIn') {
			navigate('/login')
		}
	}, [route])

	return <Auth width={'45rem'} initialState={'signUp'} />
}

export default Login
