import {
	Card,
	CardContent,
	CardHeader,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LibraryBooksOutlined } from '@material-ui/icons'

const SmartFormResponseFormDetails = ({ data }) => {
	const { t } = useTranslation()

	const [keys, setKeys] = useState([])

	useEffect(() => {
		// "additionalData": null, (this is a raw JSON)
		const checkupFields = [
			'preferredContactMethod',
			'allowMarketing',
			'subscribeToNewsLetter',
			'acceptedTos',
			'acceptedPrivacyPolicy',
			'orderTotal',
			'orderCurrency',
			'targetCompletionDate',
			'referralSource',
			'freeText1',
			'freeText2',
			'additionalData'
		]

		setKeys(
			Object.keys(data).filter((key) => checkupFields.includes(key) && data[key] !== null)
		)
	}, [data])

	return (
		<Card>
			<CardHeader
				title={t('workflows.response.form_details')}
				avatar={<LibraryBooksOutlined />}
			/>

			<CardContent>
				<TableContainer>
					<Table>
						<TableBody>
							{keys.map((key, i) => (
								<TableRow key={i}>
									<TableCell width={200} variant={'head'}>
										{t(`workflows.response.${key}`)}
									</TableCell>
									<TableCell>
										{key === 'additionalData'
											? JSON.stringify(data[key])
											: data[key]}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	)
}

export default SmartFormResponseFormDetails
