import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import axios from '../../../utils/axios'
import {
	AML_API_URL,
	COMPANIES_API_URL,
	DECISION_API_URL,
	DB_API_URL
} from '../../../config/constants'

import { Box, Button, Grid, LinearProgress } from '@material-ui/core'

import Error from '../../../layouts/Main/components/Error'
import CompanyKeyFigures from './components/CompanyKeyFigures'
import {
	CompanyBasicBusinessInfo,
	CompanyContactInfo,
	CompanyDocuments,
	CompanyGeneral,
	CompanyManagement,
	CompanyPersons,
	CompanyRating
} from './components'
import { SnackbarContext } from '../../../context'
import CompanyAmlPersons from './components/CompanyAmlPersons'
import CompanyInvoiceAddress from './components/CompanyInvoiceAddress'
import CompanyPaymentRemarks from './components/CompanyPaymentRemarks'
import CompanyStatus from './components/CompanyStatus'
import { PageContext } from '../../../context/PageContext'
import { History } from '@material-ui/icons'
import UpdateStatus from '../../../components/update-status/UpdateStatus'
import camelcaseKeys from 'camelcase-keys'

const ResultDetail = () => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const navigate = useNavigate()
	const params = useParams()

	const [result, setResult] = useState(null)

	const [decisionApis, setDecisionApis] = useState(null)

	const [persons, setPersons] = useState([])
	const [status, setStatus] = useState(true)
	const [isMonitoringEnabled, setIsMonitoringEnabled] = useState(true)

	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(true)
	const [refreshing, setRefreshing] = useState(false)

	const [country, setCountry] = useState('fi')

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		load()
	}, [params.id])

	const loadStatus = async (id) => {
		try {
			const { data } = await axios.get(`${COMPANIES_API_URL}/${id || result.id}/status`)
			setStatus(data)
			setIsMonitoringEnabled(true)
		} catch (error) {
			setIsMonitoringEnabled(!(error?.response?.status === 405))
		}
	}

	const loadPersons = async (id) => {
		try {
			const { data } = await axios.get(`v3/person`, {
				params: {
					company_id: `eq.${id || result.id}`
				}
			})
			setPersons(data.map((person) => camelcaseKeys(person)))
		} catch (error) {}
	}

	const tryLoadAndRefreshCompanyData = async () => {
		try {
			return await axios.get(`${COMPANIES_API_URL}/${params.id}`)
		} catch (e) {
			return await axios.patch(`${COMPANIES_API_URL}/${params.id}`, {
				body: {}
			})
		}
	}

	const load = async () => {
		setLoading(true)

		try {
			const { data } = await tryLoadAndRefreshCompanyData()

			setResult(data)
			setCountry(data.primaryBusinessId.indexOf('-') > -1 ? 'fi' : 'no')

			setPageConfig({
				name: data.name,
				backLink: '/companies'
			})
		} catch (error) {
			setError(true)
		}

		try {
			const { data: decisionData } = await axios.get(
				`${DB_API_URL}/companies/${params.id}/decisions`
			)
			setDecisionApis(decisionData)
		} catch (error) {}

		await loadPersons(params.id)

		await loadStatus(params.id)

		setLoading(false)
	}

	const onCompanyDataUpdate = async (companyData) => {
		setResult(companyData)
	}

	const refreshData = async () => {
		setRefreshing(true)

		try {
			const { data: companyData } = await axios.get(`${COMPANIES_API_URL}/${params.id}`)
			await onCompanyDataUpdate(companyData)

			// const { data: decisionData } = await axios.get(
			// 	`${DB_API_URL}/companies/${params.id}/decisions`
			// )
			// setDecisionApis(decisionData)

			await loadStatus(params.id)
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setRefreshing(false)
	}

	const handleRefresh = async () => {
		setLoading(true)
		try {
			await axios.get(`${DECISION_API_URL}/company/base`, {
				params: {
					businessId: result.primaryBusinessId,
					refresh: true,
					country: country
				}
			})

			await refreshData()
		} catch (error) {
			showMessage(t('error'), 'error')
		}

		setLoading(false)
	}

	const loadAmlData = async () => {
		setRefreshing(true)

		try {
			await axios.get(`${AML_API_URL}/company/comprehensive`, {
				params: {
					businessId: result.primaryBusinessId,
					country: country
				}
			})

			await loadPersons()
		} catch (e) {
			showMessage(t('company.amlNotAvailable'), 'error')
		}

		setRefreshing(false)
	}

	return (
		<>
			{error ? (
				<Error />
			) : loading ? (
				<LinearProgress />
			) : (
				<Box mb={6} display={'flex'} flexDirection={'column'} gridRowGap={24}>
					<Box display={'flex'} justifyContent={'space-between'}>
						<Button
							variant={'contained'}
							color={'primary'}
							startIcon={<History />}
							onClick={() => navigate(`/companies/${params.id}/history`)}
						>
							{t('history')}
						</Button>

						<UpdateStatus
							updatedAt={result.basicInfoLastUpdated}
							handleUpdate={handleRefresh}
							loading={refreshing}
							isSingleLine={true}
						/>
					</Box>

					<CompanyStatus
						id={params.id}
						result={status}
						isMonitoringEnabled={isMonitoringEnabled}
						setIsMonitoringEnabled={setIsMonitoringEnabled}
						loadStatus={loadStatus}
					/>

					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<CompanyGeneral
								result={result}
								handleRefresh={handleRefresh}
								refreshing={refreshing}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CompanyContactInfo result={result} />
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<CompanyInvoiceAddress addresses={result.finvoiceAddresses} />
						</Grid>
						<Grid item xs={12} md={6}>
							<CompanyBasicBusinessInfo data={result} decisionData={decisionApis} />
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<CompanyKeyFigures
								id={params.id}
								lastUpdatedAt={result.keyFiguresLastUpdated}
								data={result.keyFigures}
								afterDataRefresh={refreshData}
								refreshing={refreshing}
								isConsolidated={false}
								setRefreshing={setRefreshing}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CompanyKeyFigures
								id={params.id}
								lastUpdatedAt={result.keyFiguresLastUpdated}
								data={result.keyFigures}
								afterDataRefresh={refreshData}
								refreshing={refreshing}
								setRefreshing={setRefreshing}
								isConsolidated={true}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<CompanyManagement
								id={params.id}
								lastUpdatedAt={result.responsiblePersonsLastUpdated}
								data={result.responsiblePersons}
								afterDataRefresh={refreshData}
								refreshing={refreshing}
								setRefreshing={setRefreshing}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<CompanyPaymentRemarks
								id={params.id}
								lastUpdatedAt={result.remarksLastUpdated}
								remarks={result?.remarks}
								refreshing={refreshing}
								setRefreshing={setRefreshing}
								onCompanyDataUpdate={onCompanyDataUpdate}
							/>
						</Grid>
					</Grid>

					<CompanyPersons
						id={params.id}
						data={persons}
						loadPersons={loadPersons}
						loadAmlData={loadAmlData}
						businessId={result.primaryBusinessId}
						refreshing={refreshing}
						country={country}
					/>

					<CompanyAmlPersons
						data={persons}
						loadAmlData={loadAmlData}
						loading={refreshing}
					/>

					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<CompanyDocuments id={params.id} />
						</Grid>
						<Grid item xs={12} md={6}>
							<CompanyRating
								id={params.id}
								lastUpdatedAt={result.ratingLastUpdated}
								data={result.rating?.find((item) => item.active)}
								refreshing={refreshing}
								setRefreshing={setRefreshing}
								afterDataRefresh={refreshData}
								decisionData={decisionApis}
							/>
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	)
}

export default ResultDetail
