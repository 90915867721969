import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import ChartTooltip from '../../chart/ChartTooltip'
import getDatesArray from '../../../utils/getDatesArray'

const CompaniesChart = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		;(async () => {
			const { data } = await axios.get(`${API_URL}/v3/daily_company_stats`, {
				params: {
					organization_id: `eq.${user.organizationId}`,
					order: 'day.desc',
					limit: 7
				}
			})

			const dates = getDatesArray()

			setData(
				dates.map((day) => {
					const item = data.find((item) => item.day === day)
					return {
						daily_company_count: item?.daily_company_count || 0,
						day: new Date(item?.day || day).toLocaleString('en-US', {
							day: '2-digit',
							month: 'short'
						})
					}
				})
			)
			setLoading(false)
		})()
	}, [])

	return (
		<>
			<Typography variant={'h4'}>
				{loading ? <Skeleton /> : <>{t('dashboard.companies.chart')}</>}
			</Typography>

			<Box width={'100%'} height={300} mt={4}>
				{loading ? (
					<Skeleton variant={'rect'} height={300} />
				) : (
					<ResponsiveContainer width="100%" height="100%">
						<BarChart
							width={500}
							height={300}
							data={data}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5
							}}
						>
							<CartesianGrid strokeDasharray="3 0" vertical={false} />
							<XAxis dataKey="day" stroke={'#a7a7b0'} tick={{ fontSize: 12 }} />
							<YAxis stroke={'#a7a7b0'} axisLine={false} tick={{ fontSize: 12 }} />
							<Tooltip
								cursor={{ fill: 'rgba(167, 167, 176, 0.2)' }}
								content={<ChartTooltip />}
							/>
							<Bar
								dataKey="daily_company_count"
								fill="#1AA8E9"
								barSize={21}
								radius={24}
							/>
						</BarChart>
					</ResponsiveContainer>
				)}
			</Box>
		</>
	)
}

export default CompaniesChart
