import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Error = (props) => {
	const { t } = useTranslation()

	return (
		<Box pt={4} pb={4}>
			<Typography component="p" align="center">
				{props.error || t('error')}
			</Typography>
		</Box>
	)
}

export default Error
