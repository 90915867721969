import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import axios from 'utils/axios'
import { API_URL, COMPANIES_API_URL } from 'config/constants'

import { Auth as AmplifyAuth } from '@aws-amplify/auth'
import { Authenticator, useAuthenticator, ThemeProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import { Box, Toolbar, useTheme } from '@material-ui/core'
import AppHeader from '../../layouts/Main/components/AppHeader'
import Logo from '../../layouts/Main/components/HeaderContent/Logo'

import SignupFields from '../signup/SignupFields'

import useStyles from './Auth.styles'
import Language from '../../layouts/Main/components/HeaderContent/Language'
import { LanguageContext } from '../../context'
import { I18n } from 'aws-amplify'

const { REACT_APP_ENTERCHECK_API_KEY } = process.env

const Auth = ({ width, initialState = 'signIn' }) => {
	const classes = useStyles()

	const { route } = useAuthenticator((context) => [context.route])
	const { user } = useAuthenticator((context) => [context.user])

	const navigate = useNavigate()
	const location = useLocation()
	const { setUser, setIsLoading } = useContext(UserContext)

	const { language } = useContext(LanguageContext)

	let from = location.state?.from?.pathname || '/'

	const materialTheme = useTheme()

	const authTheme = {
		name: 'Auth Theme',
		tokens: {
			colors: {
				brand: {
					primary: {
						10: 'transparent',
						20: 'transparent',
						30: materialTheme.palette.primary.light,
						40: materialTheme.palette.primary.light,
						50: materialTheme.palette.primary.main,
						60: materialTheme.palette.primary.main,
						70: materialTheme.palette.primary.main,
						80: materialTheme.palette.primary.main,
						90: materialTheme.palette.primary.main,
						100: materialTheme.palette.primary.main
					}
				},
				shadow: {
					primary: materialTheme.palette.grey.A100,
					secondary: materialTheme.palette.grey.A100,
					tertiary: materialTheme.palette.grey.A100
				},
				border: {
					primary: materialTheme.palette.grey.A100,
					secondary: materialTheme.palette.grey.A100,
					tertiary: materialTheme.palette.grey.A100
				},
				font: {
					primary: materialTheme.palette.text.primary,
					secondary: materialTheme.palette.text.primary,
					tertiary: materialTheme.palette.text.hint,
					disabled: materialTheme.palette.text.disabled
				}
			},
			fonts: {
				default: {
					variable: { value: materialTheme.typography.fontFamily },
					static: { value: materialTheme.typography.fontFamily }
				}
			},
			components: {
				authenticator: {
					container: {
						width: {
							max: '100%'
						}
					}
				}
			}
		}
	}

	useEffect(() => {
		I18n.setLanguage(language)
	}, [language])

	useEffect(() => {
		if (route === 'authenticated') {
			console.log('from', from)
			navigate(from, { replace: true })
		}
	}, [route, navigate, from])

	useEffect(() => {
		handleAuthStateChange('signedin', user)
	}, [user])

	const handleAuthStateChange = async (authState, authData) => {
		if (authState === 'signedin' && authData && authData.signInUserSession) {
			setIsLoading(true)

			const {
				username,
				attributes: { email, 'custom:org': organizationId },
				signInUserSession: {
					idToken: {
						payload: { 'cognito:groups': groups }
					}
				}
			} = authData

			let organisation = {
				amlEnabled: false
			}
			let hasCompanies = true

			const userData = {
				username,
				email,
				organizationId,
				groups,
				organisation,
				hasCompanies,
				notifications: 0
			}

			const orgPromise = axios.get(`${API_URL}/v3/organization`, {
				params: {
					uuid: `eq.${organizationId}`
				}
			})
			const companiesPromise = axios.get(`${COMPANIES_API_URL}`, {
				params: {
					limit: 1
				}
			})

			const notificationPromise = await axios.get(
				`${API_URL}/v3/rpc/count_unread_notifications`,
				{
					params: {
						org_id: organizationId
					}
				}
			)

			Promise.all([companiesPromise, orgPromise, notificationPromise])
				.then((res) => {
					console.log('res', res)
					setUser({
						...userData,
						organisation: {
							aml_enabled: res[1]?.data?.aml_enabled
						},
						hasCompanies: res[0].data.length > 0,
						notifications: res[2]?.data
					})
				})
				.catch((e) => {
					setUser(userData)
					console.log('promise err', e)
				})
				.finally(() => {
					setIsLoading(false)
				})
		} else if (
			authState === 'signedout' &&
			location.pathname !== '/login' &&
			location.pathname !== '/register'
		) {
			setIsLoading(false)
			navigate('/login')
		}
	}

	useEffect(() => {
		if (setUser) {
			// Move the middleware logic to handleAuthStateChange
		}
	}, [setUser])

	const signUpAttributes = ['email', 'family_name', 'given_name', 'phone_number']
	const formFields = {
		signUp: {
			username: {
				order: 1
			},
			password: {
				order: 2
			},
			confirm_password: {
				order: 3
			},
			given_name: {
				order: 4
			},
			family_name: {
				order: 5
			},
			phone_number: {
				dialCode: '+358',
				order: 6
			},
			email: {
				order: 7
			}
		}
	}

	const services = {
		async validateCustomSignUp(formData) {
			if (!formData.acknowledgement) {
				return {
					acknowledgement: 'You must agree to the Terms & Conditions'
				}
			}
		},
		async handleSignUp(formData) {
			console.log('formData', formData)
			let { username, password, attributes } = formData

			axios
				.get(`${API_URL}/portal/base`, {
					params: {
						businessId: attributes['custom:company'],
						country: attributes['custom:country']
					},
					headers: {
						'x-api-key': REACT_APP_ENTERCHECK_API_KEY
					}
				})
				.then((data) => console.log(data))
				.catch((err) => console.log(err))

			return AmplifyAuth.signUp({
				username,
				password,
				attributes,
				autoSignIn: {
					enabled: true
				}
			})
		}
	}

	return (
		<>
			<AppHeader>
				<Toolbar>
					<Logo />

					<Box flexGrow={1} />

					<Language />
				</Toolbar>
			</AppHeader>
			<Box className={classes.wrapper}>
				<ThemeProvider theme={authTheme}>
					<Box
						py={10}
						width={{
							xs: '24rem',
							sm: width || '30rem'
						}}
						margin={'auto'}
					>
						<Authenticator
							signUpAttributes={signUpAttributes}
							formFields={formFields}
							initialState={initialState}
							components={{
								SignUp: {
									FormFields() {
										return <SignupFields />
									}
								}
							}}
							services={services}
						></Authenticator>
					</Box>
				</ThemeProvider>
			</Box>
		</>
	)
}

export default Auth
