import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	tableRow: {
		cursor: 'pointer'
	},
	root: {
		margin: `${theme.spacing(2)}px 0px`,
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		height: '48px',
		'&>div': {
			paddingInlineStart: '12px',
			paddingInlineEnd: '60px',
			flexGrow: '1'
		},
		'&>.iconButton': {
			position: 'absolute',
			right: '12px'
		}
	},
	tableRowSuccess: {
		backgroundColor: `${theme.palette.successBg.main} !important`
	},
	tableRowDanger: {
		background: `${theme.palette.errorBg.main} !important`
	}
}))

export default useStyles
