import { useEffect, useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import formatDate from '../../../utils/formatDate'

import useStyles from './DecisionList.styles'

const DecisionListRow = ({ result, headCells }) => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const classes = useStyles()

	const [resultEligible, setResultEligible] = useState(null)

	const successClass = classes.tableRowSuccess
	const errorClass = classes.tableRowDanger

	useEffect(() => {
		let data = result.result

		try {
			const json = typeof data === 'string' ? JSON.parse(data) : data
			data = typeof json === 'object' ? json.result : json
		} catch (e) {}

		setResultEligible(
			data === true || data === 'true'
				? true
				: data === false || data === 'false'
					? false
					: null
		)
	}, [result])

	const gotoResultDetail = (id) => {
		navigate(`/results/${id}`)
	}

	const isExcluded = (key) => {
		return headCells && !headCells.find((cell) => cell.id === key)
	}

	return (
		<TableRow
			className={`${classes.tableRow} ${
				resultEligible === null ? '' : resultEligible ? successClass : errorClass
			}`}
			onClick={() => gotoResultDetail(result.uuid)}
		>
			<TableCell>{t('decision.name.' + result.decisionName)}</TableCell>
			<TableCell>{result.companyName}</TableCell>
			<TableCell>
				{resultEligible !== null &&
					t(`decision.${resultEligible === true ? 'eligible' : 'not_eligible'}`)}
			</TableCell>
			{!isExcluded('createdBy') && <TableCell>{result.createdBy}</TableCell>}
			<TableCell>{formatDate(result.createdAt)}</TableCell>
		</TableRow>
	)
}

export default DecisionListRow
