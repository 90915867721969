import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context'
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

const OrganisationPlan = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const [data, setTotal] = useState()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		;(async () => {
			const { data: stats } = await axios.get(`${API_URL}/v3/organization_plan_details`, {
				params: {
					uuid: `eq.${user.organizationId}`
				}
			})
			setTotal(stats[0])
			setLoading(false)
		})()
	}, [])

	return (
		<Box>
			<Typography variant={'h4'}>
				{loading ? (
					<Skeleton />
				) : (
					<>
						{t('dashboard.plan.current', {})}:{' '}
						<b
							style={{
								textTransform: 'capitalize'
							}}
						>
							{data?.plan_name}
						</b>
					</>
				)}
			</Typography>
			<br />

			{loading ? (
				<>
					<Typography paragraph={true}>
						<Skeleton />
					</Typography>
					<Typography paragraph={true}>
						<Skeleton />
					</Typography>
					<Typography paragraph={true}>
						<Skeleton />
					</Typography>
				</>
			) : (
				<Table
					style={{
						width: '100%'
					}}
				>
					<TableBody>
						<TableRow>
							<TableCell
								style={{
									padding: 10,
									width: 200
								}}
							>
								{t('dashboard.plan.companies')}
							</TableCell>

							<TableCell>
								<b>
									{data?.total_monitored_companies} / {data?.plan_companies}
								</b>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>{t('dashboard.plan.search', {})}</TableCell>

							<TableCell>
								<b>
									{data?.monthly_search_total} / {data?.plan_search_quota}
								</b>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>{t('dashboard.plan.details', {})}</TableCell>

							<TableCell>
								<b>
									{data?.monthly_details_total} / {data?.plan_details_quota}
								</b>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			)}
		</Box>
	)
}

export default OrganisationPlan
