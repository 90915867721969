import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SnackbarContext, UserContext } from '../../../context'

import axios from '../../../utils/axios'
import { KONTAKTO_API_URL, COMPANIES_API_URL } from '../../../config/constants'

import BackgroundCheckConfirmDialog from './BackgroundCheckConfirmDialog'

import useStyles from './CompanyAdd.styles'

import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography
} from '@material-ui/core'

import InvoicingAddressSingleInfo from '../../../components/invoicing-address/InvoicingAddressSingleInfo'
import Error from '../../../layouts/Main/components/Error'
import { Autocomplete } from '@material-ui/lab'

import { getPostalAddress } from '../../../utils/AddressHelper'
import { PageContext } from '../../../context/PageContext'
import { BusinessOutlined, InfoOutlined } from '@material-ui/icons'

const CompanyAdd = () => {
	const countries = ['fi', 'de', 'no', 'se']

	const { t } = useTranslation()

	const { showMessage } = useContext(SnackbarContext)

	const navigate = useNavigate()

	const [error, setError] = useState(false)

	const [searching, setSearching] = useState(false)
	const [searchStartingReady, setSearchStartingReady] = useState(false)
	// const [companyName, setCompanyName] = useState('')
	const [companies, setCompanies] = useState([])
	const [company, setCompany] = useState(null)
	const [country, setCountry] = useState(countries[0])

	const [existingCompanyId, setExistingCompanyId] = useState(null)

	const [loading, setLoading] = useState(false)
	const [companyData, setCompanyData] = useState(null)
	const [saving, setSaving] = useState(false)

	const [shouldCheckCredit, setShouldCheckCredit] = useState(false)
	const [shouldRetrieveCompanyKeyFigures, setShouldRetrieveCompanyKeyFigures] = useState(false)

	const [backgroundChecking, setBackgroundChecking] = useState(false)

	const backgroundCheckEnabled = shouldCheckCredit || shouldRetrieveCompanyKeyFigures

	const COMPANY_SEARCH_DEBOUNCE_TIMEOUT = 200
	const [companySearchDebounceHandler, setCompanySearchDebounceHandler] = useState(null)
	const [companySearchRequestSource, setCompanySearchRequestSource] = useState(null)

	const [openBackgroundCheckConfirmDialog, setOpenBackgroundCheckConfirmDialog] = useState(false)

	const { user, setUser } = useContext(UserContext)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'company.add.title',
			backLink: `/`
		})
	}, [])
	const searchCompanies = (companyName) => {
		setSearching(true)

		const source = axios.CancelToken.source()
		setCompanySearchRequestSource(source)

		axios
			.get(`${KONTAKTO_API_URL}/search`, {
				params: {
					country: country,
					name: companyName
				},
				cancelToken: source.token
			})
			.then(({ data }) => {
				setCompanies(data)
				finishCompanySearching()
				setError(false)
			})
			.catch((e) => {
				if (!axios.isCancel(e)) {
					finishCompanySearching()
					setCompanies([])
				}
			})
	}

	const cancelCompanySearching = () => {
		if (companySearchRequestSource) {
			companySearchRequestSource.cancel(
				'The request has been cancelled due to another new request'
			)
		}
		if (companySearchDebounceHandler) {
			clearTimeout(companySearchDebounceHandler)
			setCompanySearchDebounceHandler(null)
		}

		finishCompanySearching()
	}

	const finishCompanySearching = () => {
		setCompanySearchRequestSource(null)
		setSearching(false)
	}

	const handleCompanyNameChange = (event, companyName) => {
		cancelCompanySearching()

		if (!companyName || companyName.length < 3) {
			setSearchStartingReady(false)
			finishCompanySearching()
			setCompanies([])
			return
		}

		setSearchStartingReady(true)

		setCompanySearchDebounceHandler(
			setTimeout(() => {
				searchCompanies(companyName)
			}, COMPANY_SEARCH_DEBOUNCE_TIMEOUT)
		)
	}

	useEffect(() => {
		setCompanyData(null)
		handleCompanyNameChange(null, company?.name || '')
	}, [country])

	useEffect(() => {
		setError(false)
		setExistingCompanyId(null)

		if (!company) {
			return
		}

		const fetchCompanyData = async () => {
			setLoading(true)
			axios
				.get(`${KONTAKTO_API_URL}/details`, {
					params: {
						country: country,
						id: company.businessId
					}
				})
				.then(({ data }) => {
					if (data.finvoice) {
						data.invoicingAddress = data.finvoice
						delete data.finvoice
					}
					setCompanyData(data)
				})
				.catch(() => {
					setError(true)
				})
				.finally(() => {
					setLoading(false)
				})
		}

		fetchCompanyData()
	}, [company])

	const handleSaveCompany = async () => {
		setSaving(true)

		axios
			.post(`${COMPANIES_API_URL}`, {
				businessId: company.businessId,
				countryCode: companyData.countryCode,
				companyRating: shouldCheckCredit,
				companyKeyFigures: shouldRetrieveCompanyKeyFigures,
				enableMonitoring: false,
				checkStatus: false,
				companyPaymentRemarks: false,
				companyRepresentatives: false
			})
			.then(({ data }) => {
				showMessage(t('company.add.message.success', { name: data.name }), 'success')

				setUser({
					...user,
					hasCompanies: true
				})

				const companyId = data.id
				setExistingCompanyId(companyId)
			})
			.catch((e) => {
				if (!e) {
					showMessage(t('company.add.message.error'), 'error')
					return
				}

				if (e.response?.status === 400) {
					setExistingCompanyId(e.response.data.company_id)

					if (backgroundCheckEnabled) setOpenBackgroundCheckConfirmDialog(true)
					else showMessage(t('company.add.message.error.duplicated'), 'error')
				} else if (e?.message) showMessage(e.message, 'error')
				else if (e.toJSON) {
					const data = e.toJSON
					showMessage(data.message || t('company.add.message.error'), 'error')
				} else {
					showMessage(t('company.add.message.error'), 'error')
				}
			})
			.finally(() => {
				setSaving(false)
			})
	}

	const runBackgroundCheck = () => {
		setBackgroundChecking(true)
		const promises = []

		if (shouldCheckCredit)
			promises.push(axios.get(`${COMPANIES_API_URL}/${existingCompanyId}/rating`))

		if (shouldRetrieveCompanyKeyFigures) {
			promises.push(axios.get(`${COMPANIES_API_URL}/${existingCompanyId}/figures`))
		}

		return Promise.all(promises)
			.then(() => {
				showMessage(t('company.add.message.background_check.success'), 'success')
			})
			.catch(() => {
				// console.log(e)
				showMessage(t('company.add.message.background_check.error'), 'error')
			})
			.finally(() => {
				setBackgroundChecking(false)
			})
	}

	const openCompany = (companyId = null) => {
		navigate(`/companies/${companyId ?? existingCompanyId}`)
	}

	const classes = useStyles()

	return (
		<>
			<Box>
				<Box display={'flex'} gridGap={24}>
					<Autocomplete
						id="company-search"
						size={'small'}
						className={classes.searchBox}
						fullWidth
						value={company}
						options={companies}
						filterOptions={(options) => options}
						getOptionLabel={(company) => company.name}
						onChange={(event, selectedCompany) => setCompany(selectedCompany)}
						onInputChange={handleCompanyNameChange}
						loading={searching}
						noOptionsText={
							searchStartingReady
								? t('company.add.search.no_companies_found')
								: t('company.add.search.not_ready')
						}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={t('company.add.search.placeholder')}
								variant="outlined"
								size={'small'}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{searching ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									)
								}}
							/>
						)}
					/>

					<FormControl variant="outlined" className={classes.formControl} size={'small'}>
						<InputLabel id="country">{t('country')}</InputLabel>
						<Select
							labelId="country"
							id="country"
							value={country}
							onChange={(e) => setCountry(e.target.value)}
							label={t('country')}
						>
							{countries.map((country, index) => {
								return (
									<MenuItem key={`name-${index}`} value={country}>
										{t(`countryCode.${country.toUpperCase()}`)}
									</MenuItem>
								)
							})}
						</Select>
					</FormControl>
				</Box>
				<Box mt={4}>
					{error ? (
						<Error />
					) : loading || !companyData ? (
						<Box className={classes.empty}>{loading && <CircularProgress />}</Box>
					) : (
						<Box>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<Card component={Paper}>
										<CardHeader
											title={t('general_info')}
											avatar={<InfoOutlined />}
										/>

										<CardContent>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell variant={'head'} width="40%">
															{t('name')}
														</TableCell>
														<TableCell>{companyData.name}</TableCell>
													</TableRow>
													<TableRow>
														<TableCell variant={'head'}>
															{t('business_id')}
														</TableCell>
														<TableCell>
															{company && company.businessId}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell variant={'head'}>
															{t('phone')}
														</TableCell>
														<TableCell>
															{companyData.contactPhoneNumber}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell variant={'head'}>
															{t('email')}
														</TableCell>
														<TableCell>
															{companyData.contactEMail}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell variant={'head'}>
															{t('street_address')}
														</TableCell>
														<TableCell>
															{getPostalAddress(
																companyData.visitingAddress
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell variant={'head'}>
															{t('postal_address')}
														</TableCell>
														<TableCell>
															{getPostalAddress(
																companyData.postalAddress
															)}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell variant={'head'}>
															{t('country')}
														</TableCell>
														<TableCell>
															{t(
																'countryCode.' +
																	companyData.countryCode
															)}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</CardContent>
									</Card>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Card component={Paper}>
										<CardHeader
											title={t('invoicing_address')}
											avatar={<BusinessOutlined />}
										/>

										<CardContent>
											{companyData.receivingFinvoiceAddress ? (
												<InvoicingAddressSingleInfo
													data={companyData.receivingFinvoiceAddress}
												/>
											) : (
												<Box p={2}>
													<Typography variant="body1">
														{t('company.add.no_invoice_info_found')}
													</Typography>
												</Box>
											)}
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Box>
					)}
				</Box>
				<Box mt={3}>
					<Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={shouldCheckCredit}
									onChange={(event) => {
										setShouldCheckCredit(event.target.checked)
									}}
									color="primary"
								/>
							}
							label={t('company.add.background_check.credit')}
						/>
					</Box>
					<Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={shouldRetrieveCompanyKeyFigures}
									onChange={(event) => {
										setShouldRetrieveCompanyKeyFigures(event.target.checked)
									}}
									color="primary"
								/>
							}
							label={t('company.add.background_check.key_figures')}
						/>
					</Box>
				</Box>
				<Box mt={2} className={classes.actions}>
					<Button
						color="primary"
						variant="contained"
						disabled={!company || !companyData || saving || existingCompanyId != null}
						onClick={handleSaveCompany}
					>
						{t('company.add.button.title.save')}
					</Button>
					{saving && (
						<Box ml={2}>
							<CircularProgress size={30} />
						</Box>
					)}
					{existingCompanyId && (
						<Box ml={2}>
							<Button
								color="secondary"
								variant="contained"
								disabled={backgroundChecking}
								onClick={() => openCompany()}
							>
								{t('company.add.button.title.open')}
							</Button>
						</Box>
					)}
					{backgroundCheckEnabled && existingCompanyId && (
						<Box ml={2}>
							<Button
								color="secondary"
								variant="contained"
								disabled={backgroundChecking}
								onClick={() => runBackgroundCheck()}
							>
								{t('company.add.button.title.perform_background_checks_again')}
							</Button>
						</Box>
					)}
					{backgroundChecking && (
						<>
							<Box ml={2}>
								<CircularProgress size={30} color="secondary" />
							</Box>
							<Box ml={2}>
								<Typography variant="subtitle1" color="secondary">
									{t('company.add.message.background_check_in_progress')}
								</Typography>
							</Box>
						</>
					)}
				</Box>
			</Box>
			<BackgroundCheckConfirmDialog
				open={openBackgroundCheckConfirmDialog}
				setOpen={setOpenBackgroundCheckConfirmDialog}
				onConfirm={runBackgroundCheck}
			/>
		</>
	)
}

export default CompanyAdd
