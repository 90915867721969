import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Auth } from 'aws-amplify'
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TextField,
	Typography
} from '@material-ui/core'
import PasswordRequirements from '../../../components/shared/PasswordRequirements'
import { UserContext } from '../../../context'
import { PageContext } from '../../../context/PageContext'

const Profile = () => {
	const { t } = useTranslation()

	const { user } = useContext(UserContext)

	const [passwords, setPasswords] = useState({
		current: '',
		new: '',
		confirm: '',
		showCurrent: false,
		showNew: false
	})
	const [passwordStatus, setPasswordStatus] = useState(null)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'nav.profile'
		})
	}, [])

	const handleInputChange = (prop) => (event) => {
		setPasswords({ ...passwords, [prop]: event.target.value })
	}

	const handleChangePassword = (e) => {
		e.preventDefault()
		setPasswordStatus(null)

		if (passwords.confirm !== passwords.new) {
			setPasswordStatus(t('passwords_mismatch'))
			return
		}

		const form = e.target

		Auth.currentAuthenticatedUser()
			.then((user) => {
				return Auth.changePassword(user, passwords.current, passwords.new)
			})
			.then((data) => {
				setPasswordStatus('Success!')
				form.reset()
			})
			.catch((err) => {
				setPasswordStatus(err.message)
			})
	}

	return (
		<>
			{user && (
				<>
					<Box mb={6}>
						<TableContainer>
							<Table
								aria-label="Profile>"
								classes={{
									root: 'primary'
								}}
							>
								<TableHead>
									<TableRow>
										<TableCell width={'33%'}>{t('email')}</TableCell>
										<TableCell width={'33%'}>{t('username')}</TableCell>
										<TableCell width={'33%'}>{t('role')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>{user.email}</TableCell>
										<TableCell>{user.username}</TableCell>
										<TableCell>
											{user.groups
												.map((role) => t(`role.${role}`))
												.join(', ')}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box mb={6}>
						<form noValidate autoComplete="off" onSubmit={handleChangePassword}>
							<TableContainer>
								<Table
									aria-label="Profile>"
									classes={{
										root: 'primary'
									}}
								>
									<TableHead>
										<TableRow>
											<TableCell>{t('password')}</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										<TableRow>
											<TableCell>
												<Box mb={2}>
													<PasswordRequirements />
												</Box>

												<Grid container spacing={2} alignItems="center">
													<Grid item xs={12} sm={4}>
														<TextField
															type={'password'}
															label={t('password.current')}
															variant="outlined"
															size={'small'}
															required
															fullWidth={true}
															onChange={handleInputChange('current')}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<TextField
															type={'password'}
															label={t('password.new')}
															size={'small'}
															variant="outlined"
															required
															fullWidth={true}
															onChange={handleInputChange('new')}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<TextField
															type={'password'}
															label={t('password.confirm')}
															variant="outlined"
															size={'small'}
															required
															fullWidth={true}
															onChange={handleInputChange('confirm')}
														/>
													</Grid>
												</Grid>

												{passwordStatus && (
													<Box mt={3} width="75%">
														<Typography
															variant="subtitle1"
															gutterBottom={true}
														>
															{passwordStatus}
														</Typography>
													</Box>
												)}
											</TableCell>
										</TableRow>
									</TableBody>
									<TableFooter>
										<TableRow>
											<TableCell>
												<Button
													type={'submit'}
													variant="contained"
													color="primary"
												>
													{t('change_password')}
												</Button>
											</TableCell>
										</TableRow>
									</TableFooter>
								</Table>
							</TableContainer>
						</form>
					</Box>
				</>
			)}
		</>
	)
}

export default Profile
