import {
	Card,
	CardContent,
	CardHeader,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core'
import { getAddressString } from '../../../../utils/AddressHelper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { COMPANY_ADDRESS_TYPES } from '../../../../config/constants'
import { PermContactCalendarOutlined } from '@material-ui/icons'

const CompanyContactInfo = ({ result }) => {
	const { t } = useTranslation()

	return (
		<Card component={Paper}>
			<CardHeader title={t('contact_info')} avatar={<PermContactCalendarOutlined />} />

			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell variant={'head'} width="25%">
								{t('phone')}
							</TableCell>
							<TableCell>{result.contactPhoneNumber}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('email')}</TableCell>
							<TableCell>{result.contactEMail}</TableCell>
						</TableRow>

						<TableRow>
							<TableCell variant={'head'}>{t('postalAddress')}</TableCell>
							<TableCell>
								{getAddressString(COMPANY_ADDRESS_TYPES.postal, result.addresses)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('street_address')}</TableCell>
							<TableCell>
								{getAddressString(COMPANY_ADDRESS_TYPES.visit, result.addresses) ||
									t('company.country.empty')}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('homePage')}</TableCell>
							<TableCell>
								{result.websites.map(({ website }, i) => (
									<a key={i} target={'_blank'} rel="noreferrer" href={website}>
										{website}
									</a>
								))}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	)
}

export default CompanyContactInfo
