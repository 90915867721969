import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow
} from '@material-ui/core'
import axios from '../../../../utils/axios'
import { COMPANIES_API_URL } from '../../../../config/constants'
import formatDate from '../../../../utils/formatDate'
import formatCurrency from '../../../../utils/formatCurrency'
import CompanyDataNotAvailable from '../../../../components/shared/CompanyDataNotAvailable'
import UpdateStatus from '../../../../components/update-status/UpdateStatus'
import { PaymentOutlined } from '@material-ui/icons'

const CompanyPaymentRemarks = ({
	id,
	lastUpdatedAt,
	refreshing,
	setRefreshing,
	remarks = [],
	onCompanyDataUpdate
}) => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState(false)

	const [total, setTotal] = useState()
	const [pagedRemarks, setPagedRemarks] = useState([])

	const calculateRemarks = (remarks) => {
		return {
			count: remarks.length,
			amount: formatCurrency(
				remarks.reduce((total, remark) => {
					total += remark.amount
					return total
				}, 0)
			)
		}
	}

	useEffect(() => {
		if (remarks.length) {
			const unpaidRemarks = calculateRemarks(
				remarks.filter((remark) => !remark.remarkPaidDate)
			)
			const totalRemarks = calculateRemarks(remarks)
			setTotal({
				remarks: totalRemarks,
				unpaidRemarks
			})

			setPagedRemarks(remarks.slice(0, 5))
		} else {
			setTotal(null)
			setPagedRemarks([])
		}
	}, [remarks])

	const handleFetchRemarks = async (refresh = true) => {
		setLoading(true)
		setRefreshing(true)

		try {
			const { data } = await axios.get(`${COMPANIES_API_URL}/${id}/remarks`)

			data.remarks = data.remarks.sort((a, b) => b.remarkCreatedAt - a.remarkCreatedAt)

			onCompanyDataUpdate(data)
		} catch (e) {
			// console.log('e', e)
		}

		setLoading(false)
		setRefreshing(false)
	}

	const loadRemarks = () => {
		setPagedRemarks(remarks)
	}

	return (
		<Card component={Paper}>
			<CardHeader
				title={t('company.paymentRemarks')}
				avatar={<PaymentOutlined />}
				action={
					<UpdateStatus
						updatedAt={lastUpdatedAt}
						handleUpdate={() => handleFetchRemarks(true)}
						loading={loading || refreshing}
					/>
				}
			/>

			{loading ? (
				<LinearProgress />
			) : !pagedRemarks.length ? (
				<CardContent>
					<CompanyDataNotAvailable lastUpdatedAt={lastUpdatedAt} isRemarks={true} />
				</CardContent>
			) : (
				<CardContent>
					<TableContainer>
						<Table>
							{!!pagedRemarks.length && (
								<TableHead>
									<TableRow>
										<TableCell>
											{t('company.paymentRemarks.remarkCode')}
										</TableCell>
										<TableCell>
											{t('company.paymentRemarks.remarkDate')}
										</TableCell>
										<TableCell>{t('company.paymentRemarks.amount')}</TableCell>
										<TableCell>
											{t('company.paymentRemarks.creditorName')}
										</TableCell>

										<TableCell>
											{t('company.paymentRemarks.remarkPaidDate')}
										</TableCell>
										<TableCell>
											{t('company.paymentRemarks.remarkValidToDate')}
										</TableCell>
										<TableCell>{t('created_at')}</TableCell>
									</TableRow>
								</TableHead>
							)}
							<TableBody>
								{pagedRemarks.map((remark, i) => (
									<TableRow key={i}>
										<TableCell>
											{t(`remarks.codes.${remark.remarkCode}`)}
										</TableCell>
										<TableCell>{formatDate(remark.remarkDate)}</TableCell>
										<TableCell>{formatCurrency(remark.amount)}</TableCell>
										<TableCell>
											{remark.creditorName || remark.remarkSource}
										</TableCell>
										<TableCell>{formatDate(remark.remarkPaidDate)}</TableCell>
										<TableCell>
											{formatDate(remark.remarkValidToDate)}
										</TableCell>
										<TableCell>{formatDate(remark.remarkCreatedAt)}</TableCell>
									</TableRow>
								))}
							</TableBody>

							{(total || pagedRemarks.length !== remarks.length) && (
								<TableFooter>
									{total && (
										<>
											<TableRow>
												<TableCell
													colSpan={7}
													style={{
														padding: 0
													}}
												>
													<Divider />
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>
													{t('company.paymentRemarks.remarksCount')}:{' '}
													{total.remarks.count}
												</TableCell>
												<TableCell>
													{t('company.paymentRemarks.remarksTotal')}:{' '}
													{total.remarks.amount}
												</TableCell>

												<TableCell>
													{t('company.paymentRemarks.unpaidRemarksCount')}
													: {total.unpaidRemarks.count}
												</TableCell>
												<TableCell>
													{t('company.paymentRemarks.unpaidRemarksTotal')}
													: {total.unpaidRemarks.amount}
												</TableCell>

												<TableCell colSpan={3}></TableCell>
											</TableRow>
										</>
									)}

									{pagedRemarks.length !== remarks.length && (
										<>
											<TableRow>
												<TableCell
													colSpan={7}
													style={{
														padding: 0
													}}
												>
													<Divider />
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell colSpan={7}>
													<Grid
														container
														justifyContent="center"
														direction="column"
														alignItems="center"
													>
														<Button
															variant="outlined"
															color="primary"
															onClick={loadRemarks}
														>
															{t('load_more')}
														</Button>
													</Grid>
												</TableCell>
											</TableRow>
										</>
									)}
								</TableFooter>
							)}
						</Table>
					</TableContainer>
				</CardContent>
			)}
		</Card>
	)
}

export default CompanyPaymentRemarks
