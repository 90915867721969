import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { SMARTFORM_API_URL } from '../../../config/constants'
import {
	Box,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import formatDate from '../../../utils/formatDate'
import Error from '../../../layouts/Main/components/Error'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { PageContext } from '../../../context/PageContext'

const WorkflowEvents = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const [responses, setResponses] = useState([])

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'workflow.responses'
		})
	}, [])

	useEffect(() => {
		;(async () => {
			setLoading(true)

			try {
				const { data } = await axios.get(`${SMARTFORM_API_URL}/responses`)

				setResponses(data)
			} catch (e) {
				setError(true)
			}

			setLoading(false)
		})()
	}, [])

	const handleNavigate = (id) => (e) => {
		if (e.target.tagName.toLowerCase() === 'a') return
		navigate(`/events/${id}`)
	}

	return (
		<>
			{error ? (
				<Error />
			) : (
				<TableContainer>
					<Table
						classes={{
							root: 'primary'
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell width={440}>{t('company')}</TableCell>
								<TableCell>{t('person')}</TableCell>
								<TableCell>{t('status')}</TableCell>
								<TableCell>{t('created_at')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{responses.map((response, i) => (
								<TableRow
									key={i}
									style={{
										cursor: 'pointer'
									}}
									onClick={handleNavigate(response.id)}
								>
									<TableCell>
										<Box display={'flex'} gridGap={24} alignItems={'center'}>
											<Link to={`/companies/${response.companyId}`}>
												{response.companyName}
											</Link>
										</Box>
									</TableCell>
									<TableCell>
										<Link to={`/persons/${response.personId}`}>
											{response.personName}
										</Link>
									</TableCell>
									<TableCell>
										{t(`workflows.response.status.${response.status}`)}
									</TableCell>
									<TableCell>{formatDate(response.createdAt)}</TableCell>
								</TableRow>
							))}
						</TableBody>
						{loading && (
							<TableFooter>
								<TableRow>
									<TableCell colSpan={4}>
										<LinearProgress />
									</TableCell>
								</TableRow>
							</TableFooter>
						)}
					</Table>
				</TableContainer>
			)}
		</>
	)
}

export default WorkflowEvents
