import React, { useContext, useEffect, useState } from 'react'
import { SnackbarContext, UserContext } from '../../../../../context'
import { useTranslation } from 'react-i18next'
import axios from '../../../../../utils/axios'
import { API_URL, CONFIGURATION_API_URL } from '../../../../../config/constants'
import {
	Box,
	Button,
	Card,
	CardHeader,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableRow,
	TextField
} from '@material-ui/core'
import CalendlyList from './components/CalendlyList'

const CalendlyCredentials = ({ workflowId }) => {
	const { user } = useContext(UserContext)

	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [loading, setLoading] = useState(true)
	const [saving, setSaving] = useState(false)
	const [credentials, setCredentials] = useState({
		apiKey: ''
	})

	const [webhooks, setWebhooks] = useState([])

	useEffect(() => {
		;(async () => {
			setLoading(true)

			await loadWebhooks()

			setLoading(false)
		})()
	}, [])

	const loadWebhooks = async () => {
		try {
			const { data } = await axios.get(`${CONFIGURATION_API_URL}/calendly_webhook`, {
				params: {
					organization_id: `eq.${user.organizationId}`,
					deleted_at: 'is.null',
					smart_form_id: `eq.${workflowId}`
				}
			})
			setWebhooks(data)
		} catch (e) {
			console.log(e)
			showMessage(t('error'), 'error')
		}
	}

	const handleInputChange = (prop) => (event) => {
		setCredentials({ ...credentials, [prop]: event.target.value })
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault()

		setSaving(true)

		let isValid = false

		try {
			await axios.get(`${API_URL}/integrations/calendly/validate`, {
				params: {
					apiKey: credentials.apiKey
				}
			})
			isValid = true
		} catch (error) {
			showMessage(t('calendly.error.invalidKey'), 'error')
		}

		if (isValid) {
			try {
				await axios.post(`${API_URL}/integrations/calendly/subscribe`, {
					apiKey: credentials.apiKey,
					smartFormId: workflowId
				})

				showMessage(t('3rd_party_credentials.saved'), 'success')
			} catch (error) {
				showMessage(
					error.response.status === 403
						? t('calendly.error.upgradeRequired')
						: error.response?.data?.message || t('error'),
					'error'
				)
			}
			await loadWebhooks()
		}

		setSaving(false)
	}

	return (
		<Box mt={4}>
			<Card
				component={Paper}
				classes={{
					root: 'primary'
				}}
			>
				<CardHeader title={t('workflow.triggers')} />
				{loading ? (
					<LinearProgress />
				) : (
					<>
						<form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
							<Table
								classes={{
									root: 'primary'
								}}
							>
								<TableBody>
									<TableRow>
										<TableCell>
											<TextField
												size={'small'}
												type={'text'}
												label={t('apiKey')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange('apiKey')}
												value={credentials.apiKey}
											/>
										</TableCell>
									</TableRow>
								</TableBody>

								<TableFooter>
									<TableRow>
										<TableCell colSpan={2}>
											<Box display={'flex'} gridGap={24}>
												<Button
													type={'submit'}
													variant="contained"
													color="primary"
													disabled={saving}
												>
													{t('save')}
												</Button>
											</Box>
										</TableCell>
									</TableRow>
								</TableFooter>
							</Table>
						</form>

						<CalendlyList webhooks={webhooks} setWebhooks={setWebhooks} />
					</>
				)}
			</Card>
		</Box>
	)
}

export default CalendlyCredentials
