import { useContext } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NotificationsNoneOutlined as NotificationsIcon } from '@material-ui/icons'
import { UserContext } from '../../../context'
import { Link } from 'react-router-dom'

const TotalCompanies = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)

	return (
		<Box
			height={'100%'}
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'space-between'}
		>
			<Typography variant={'h4'}>
				{t('dashboard.notifications', {
					value: user?.notifications
				})}
			</Typography>
			<br />

			<Box
				display={'flex'}
				alignItems={'center'}
				gridGap={6}
				component={Link}
				to="/notifications"
			>
				<NotificationsIcon />

				<Box flex={1}>
					<Typography variant={'h1'} component={'span'}>
						{user?.notifications}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

export default TotalCompanies
