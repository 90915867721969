import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../context'
import { LinearProgress } from '@material-ui/core'

export function RequireCompanies({}) {
	const location = useLocation()
	const { user, isLoading } = useContext(UserContext)

	if (user && !user.hasCompanies) {
		return <Navigate to="/welcome" state={{ from: location }} replace />
	}

	if (isLoading) {
		return <LinearProgress />
	}

	return <Outlet />
}
