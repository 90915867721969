import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField
} from '@material-ui/core'

import Error from '../../../layouts/Main/components/Error'

import useStyles from './PersonsList.styles'
import SearchIcon from '@material-ui/icons/Search'
import formatDate from '../../../utils/formatDate'
import { PERSONS_API_URL, ORDER_DIRS, PAGE_SIZE } from '../../../config/constants'
import snakecaseKeys from 'snakecase-keys'
import axios from '../../../utils/axios'
import { PageContext } from '../../../context/PageContext'

const PersonsList = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const [error, setError] = useState(false)

	const classes = useStyles()

	const [persons, setPersons] = useState([])
	const [orderBy, setOrderBy] = useState('created_at')
	const [orderDir, setOrderDir] = useState('desc')
	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	const [loading, setLoading] = useState(true)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({ name: 'person.list' })
	}, [])

	useEffect(() => {
		loadMore()
	}, [offset, orderBy, orderDir, keyword])

	const loadMore = () => {
		setLoading(true)
		setLoadFinish(false)

		const params = snakecaseKeys(
			{
				limit: PAGE_SIZE,
				offset,
				orderBy,
				orderDir,
				q: keyword
			},
			{ deep: true }
		)

		axios
			.get(`${PERSONS_API_URL}`, { params })
			.then(({ data }) => {
				let temp = persons
				temp = temp.concat(data)
				setPersons(temp)
				if (data.length < PAGE_SIZE) setLoadFinish(true)
			})
			.catch((e) => {
				setError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const resetSearch = () => {
		setOffset(0)
		setPersons([])
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) setKeyword(value)
		else if (!offset) {
			loadMore()
		}
		resetSearch()
	}

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)

		resetSearch()
	}

	const gotoResultDetail = (id) => {
		navigate(`/persons/${id}`)
	}

	const headCells = [
		{ id: 'first_name', company: true, label: t('person_name'), width: 25, sortable: true },
		{ id: 'email', label: t('email'), width: 25, sortable: true },
		{ id: 'status', label: t('status'), width: 25, sortable: false },
		{ id: 'created_at', label: t('created_at'), width: 25, sortable: true }
	]

	return (
		<>
			<Box>
				{error ? (
					<Error />
				) : (
					<>
						<Box pb={3}>
							<form onSubmit={handleSearchFormSubmit}>
								<TextField
									label={t('person.search.placeholder')}
									id="search"
									variant={'outlined'}
									size={'small'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													type="submit"
													className="iconButton"
													aria-label="search"
												>
													<SearchIcon />
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</form>
						</Box>

						<TableContainer>
							<Table
								aria-label="Persons Table"
								classes={{
									root: 'primary'
								}}
							>
								<TableHead>
									<TableRow>
										{headCells.map((headCell) => (
											<TableCell
												key={headCell.id}
												sortDirection={
													orderBy === headCell.id ? orderDir : false
												}
												width={`${headCell.width}%`}
											>
												{headCell.sortable ? (
													<TableSortLabel
														active={orderBy === headCell.id}
														direction={
															orderBy === headCell.id
																? orderDir
																: 'asc'
														}
														onClick={() => {
															handleSortChange(
																headCell.id,
																headCell.company
															)
														}}
													>
														{headCell.label}
													</TableSortLabel>
												) : (
													<>{headCell.label}</>
												)}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{persons.map((result, i) => (
										<TableRow
											className={`${classes.tableRow} ${
												result.companyRepresentationApproved
													? classes.tableRowSuccess
													: classes.tableRowDanger
											}`}
											key={`result-table-row-${i}`}
											onClick={() => gotoResultDetail(result.uuid)}
										>
											<TableCell>
												{result.firstName} {result.lastName}
											</TableCell>
											<TableCell>{result.email}</TableCell>
											<TableCell>
												{t(`person.status.${result.status}`)}
											</TableCell>
											<TableCell>{formatDate(result.createdAt)}</TableCell>
										</TableRow>
									))}
								</TableBody>
								<TableFooter>
									{!loadFinished && (
										<>
											{loading && (
												<TableRow>
													<TableCell colSpan={4}>
														<LinearProgress />
													</TableCell>
												</TableRow>
											)}

											<TableRow>
												<TableCell colSpan={4}>
													<Grid
														container
														direction="column"
														alignItems="center"
													>
														<Button
															disabled={loading}
															variant="outlined"
															color="primary"
															onClick={() =>
																setOffset(offset + PAGE_SIZE)
															}
														>
															{t('load_more')}
														</Button>
													</Grid>
												</TableCell>
											</TableRow>
										</>
									)}
								</TableFooter>
							</Table>
						</TableContainer>
					</>
				)}
			</Box>
		</>
	)
}

export default PersonsList
