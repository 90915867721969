import React, { useContext, useEffect, useState } from 'react'
import { PageContext } from '../../../context/PageContext'
import { useParams } from 'react-router-dom'
import axios from '../../../utils/axios'
import { COMPANIES_API_URL } from '../../../config/constants'
import { Card, CardHeader, LinearProgress, Paper } from '@material-ui/core'
import { HistoryTable } from '../../../components/history'
import Error from '../../../layouts/Main/components/Error'
import { useTranslation } from 'react-i18next'
import { History } from '@material-ui/icons'

const CompanyHistory = () => {
	const { t } = useTranslation()

	const params = useParams()

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [histories, setHistories] = useState([])

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'company_list',
			backLink: `/companies/${params.id}`
		})
		;(async () => {
			setError(false)
			setLoading(true)
			try {
				const { data } = await axios.get(`${COMPANIES_API_URL}/${params.id}/history`)

				setHistories(data)

				setLoading(true)
			} catch (error) {
				setError(true)
			}

			setLoading(false)
		})()
	}, [params.id])

	return (
		<>
			{error ? (
				<Error />
			) : loading ? (
				<LinearProgress />
			) : (
				<Card component={Paper}>
					<CardHeader title={t('history')} avatar={<History />} />

					<HistoryTable histories={histories} />
				</Card>
			)}
		</>
	)
}

export default CompanyHistory
