import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import {
	Table,
	TableBody,
	TableCell,
	TableSortLabel,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Button,
	Dialog,
	DialogTitle,
	DialogActions
} from '@material-ui/core'

import { AUTH_API_URL, ORDER_DIRS } from '../../../config/constants'

import { SnackbarContext } from '../../../context'
import OrganisationTableRow from '../OrganisationTableRow/OrganisationTableRow'

const OrganisationTable = ({ results, setResults, roles }) => {
	const { t } = useTranslation()

	const [orderedResults, setOrderedResults] = useState([])
	const [orderBy, setOrderBy] = useState('userName')
	const [orderDir, setOrderDir] = useState(ORDER_DIRS.ASC)

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const [loading, setLoading] = useState(false)

	const [userToDelete, setUserToDelete] = useState(null)
	const [promptOpen, setPromptOpen] = useState(false)

	const { showMessage } = useContext(SnackbarContext)

	const headCells = [
		{ id: 'userName', label: 'username' },
		{ id: 'email', label: 'email' },
		{ id: 'role', label: 'role' }
	]

	const getOnePage = () =>
		rowsPerPage > 0
			? orderedResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			: orderedResults

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	useEffect(() => {
		const sortResults = () => {
			let newResults

			const getField = (obj) => {
				return obj[orderBy] ? obj[orderBy].toLowerCase() : ''
			}
			newResults = results.sort((a, b) => {
				a = getField(a)
				b = getField(b)

				let res = 0
				if (a < b) {
					res = -1
				}
				if (a > b) {
					res = 1
				}

				if (orderDir === ORDER_DIRS.DESC) res *= -1

				return res
			})

			setOrderedResults([...newResults])
		}

		sortResults()
	}, [orderBy, orderDir, results])

	const sortResults = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)
	}

	const handleDeleteUser = async (e) => {
		setLoading(true)
		setPromptOpen(false)

		try {
			await axios.delete(AUTH_API_URL + `/organizations/users/${userToDelete.userId}`, {
				data: {}
			})

			setResults(results.filter((user) => user.userId !== userToDelete.userId))

			setUserToDelete(null)
		} catch (e) {
			console.log(e)
			showMessage(t('user.update.error'), 'error')
		}

		setLoading(false)
	}

	const promptToDeleteUser = (user) => (e) => {
		setUserToDelete(user)
		setPromptOpen(true)
	}

	const handleClosePrompt = () => {
		setPromptOpen(false)
	}

	const updateUserList = (userToUpdate) => {
		const tmp = orderedResults.map((result) => {
			if (result.userId === userToUpdate.userId) {
				result = userToUpdate
			}

			return result
		})
		setResults(tmp)
	}

	return (
		<>
			<Dialog
				fullScreen={false}
				open={promptOpen}
				onClose={handleClosePrompt}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{t('user.delete.confirm', { name: userToDelete?.userName })}
				</DialogTitle>
				<DialogActions>
					<Button
						autoFocus
						onClick={handleClosePrompt}
						color="primary"
						disabled={loading}
					>
						{t('user.delete.confirm.disagree')}
					</Button>
					<Button onClick={handleDeleteUser} color="primary" autoFocus disabled={loading}>
						{t('user.delete.confirm.agree')}
					</Button>
				</DialogActions>
			</Dialog>

			<TableContainer>
				<Table
					aria-label="Results Table"
					classes={{
						root: 'primary'
					}}
				>
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={orderBy === headCell.id ? orderDir : false}
								>
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={
											orderBy === headCell.id ? orderDir : ORDER_DIRS.ASC
										}
										onClick={() => {
											sortResults(headCell.id)
										}}
									>
										{t(headCell.label)}
									</TableSortLabel>
								</TableCell>
							))}
							<TableCell>{t('user.enabled')}</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{getOnePage().map((result, i) => (
							<OrganisationTableRow
								key={i}
								user={result}
								roles={roles}
								promptToDeleteUser={promptToDeleteUser}
								updateUserList={updateUserList}
							/>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
								colSpan={5}
								count={orderedResults.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}
export default OrganisationTable
