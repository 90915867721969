import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	FormControlLabel,
	Grid,
	Switch,
	Typography
} from '@material-ui/core'
import useStyles from './styles'
import {
	ReportProblemOutlined,
	ErrorOutlineOutlined,
	Cancel,
	Assignment,
	CheckCircleOutlined
} from '@material-ui/icons'
import axios from 'axios'
import { COMPANIES_API_URL } from '../../../../config/constants'
import { SnackbarContext } from '../../../../context'

const CompanyStatus = ({ id, result, isMonitoringEnabled, setIsMonitoringEnabled, loadStatus }) => {
	const { showMessage } = useContext(SnackbarContext)
	const [isLoading, setIsLoading] = useState(false)

	const availableStatus = {
		warn: 'WARN',
		info: 'INFO',
		critical: 'CRITICAL'
	}

	const classes = useStyles()

	const { t } = useTranslation()

	const handleMonitoringChange = async (e) => {
		const value = e.target.checked

		setIsLoading(true)
		setIsMonitoringEnabled(value)

		try {
			await axios.patch(`${COMPANIES_API_URL}/${id}/monitoring`, {
				monitoring: value
			})

			if (value) {
				await loadStatus()
			}
		} catch (e) {
			console.log(e)
			showMessage(t('error'), 'error')
		}

		setIsLoading(false)
	}

	return (
		<Card>
			<CardHeader
				title={t('company.status')}
				avatar={<Assignment />}
				action={
					<FormControlLabel
						control={
							<Switch
								checked={isMonitoringEnabled}
								onChange={handleMonitoringChange}
								color="primary"
								disabled={isLoading}
							/>
						}
						labelPlacement="start"
						label={t('company.monitoring')}
					/>
				}
			/>

			{isMonitoringEnabled ? (
				<>
					{result && (
						<>
							{!result.length ? (
								<CardContent>
									<Box
										display={'flex'}
										alignItems={'center'}
										gridGap={36}
										py={2}
										px={2}
										style={{
											height: '100%'
										}}
										className={`${classes.tableRowSuccessLight}`}
									>
										<Box
											display={'flex'}
											alignItems={'center'}
											justifyContent={'center'}
											flexDirection={'column'}
											className={`${classes.textSuccess}`}
										>
											<CheckCircleOutlined />
										</Box>
										<Box>
											<Typography variant={'h5'}>
												{t('company.status.notAvailable')}
											</Typography>
										</Box>
									</Box>
								</CardContent>
							) : (
								<CardContent>
									<Grid container spacing={3}>
										{result.map((item, index) => (
											<Grid key={index} item xs={12} sm={4}>
												<Box
													display={'flex'}
													alignItems={'center'}
													gridGap={36}
													py={2}
													px={2}
													style={{
														height: '100%'
													}}
													className={`${
														item.status === availableStatus.critical
															? classes.tableRowDanger
															: item.status === availableStatus.warn
																? classes.tableRowWarn
																: item.status ===
																	  availableStatus.info
																	? classes.tableRowInfo
																	: ''
													}`}
												>
													<Box
														display={'flex'}
														alignItems={'center'}
														justifyContent={'center'}
														flexDirection={'column'}
														className={`${
															item.status === availableStatus.critical
																? classes.textDanger
																: item.status ===
																	  availableStatus.warn
																	? classes.textWarn
																	: item.status ===
																		  availableStatus.info
																		? classes.textInfo
																		: ''
														}`}
													>
														{item.status === availableStatus.info ? (
															<ErrorOutlineOutlined />
														) : item.status === availableStatus.warn ? (
															<ReportProblemOutlined />
														) : item.status ===
														  availableStatus.critical ? (
															<Cancel />
														) : (
															''
														)}

														<Typography variant={'h6'}>
															{t(
																`company.status.${item.status}`
															)?.toUpperCase()}
														</Typography>
													</Box>
													<Box>
														<Typography variant={'h6'}>
															{t(`company.status.${item.category}`)}
														</Typography>

														<Typography variant={'h5'}>
															{t(
																`company.status.${item.category}.${item.name}`
															)}
														</Typography>
														<Typography variant={'p'}>
															{item.description}
														</Typography>
													</Box>
												</Box>
											</Grid>
										))}
									</Grid>
								</CardContent>
							)}
						</>
					)}
				</>
			) : (
				<CardContent>
					<Box display={'flex'} justifyContent={'center'}>
						<Typography variant={'h4'} align={'center'}>
							{t('company.monitoring.disabled')}
						</Typography>
					</Box>
				</CardContent>
			)}
		</Card>
	)
}

export default CompanyStatus
