import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: `${theme.spacing(3)}px`
	},
	companyAddButton: {
		marginLeft: 'auto'
	},
	tableRow: {
		cursor: 'pointer'
	},
	progress: {
		margin: theme.spacing(2),
		width: 'calc(100% - 32px)'
	},
	root: {
		position: 'relative',
		background: theme.palette.background.secondary,
		display: 'flex',
		alignItems: 'center',
		border: '1px solid #DFD9D9',
		height: '48px',
		'&>div': {
			paddingInlineStart: '12px',
			paddingInlineEnd: '60px',
			flexGrow: '1'
		},
		'&>.iconButton': {
			position: 'absolute',
			right: '12px'
		}
	}
}))

export default useStyles
