import { Link } from 'react-router-dom'

const Logo = () => {
	return (
		<Link to="/">
			<img width={'160px'} src="/logo.png" alt={'Enterpay'} />
		</Link>
	)
}

export default Logo
