import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { SMARTFORM_API_URL } from '../../../config/constants'
import { useParams } from 'react-router-dom'
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	LinearProgress,
	Paper
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Error from '../../../layouts/Main/components/Error'
import WorkflowDetailTasks from './components/WorkflowDetailTasks'
import WorkflowSettingsDetailBaseForm from './components/WorkflowSettingsDetailBaseForm'
import camelcaseKeys from 'camelcase-keys'
import { PageContext } from '../../../context/PageContext'
import WorkflowSettingsDetailRecipients from './components/WorkflowSettingsDetailRecipients'
import WorkflowDetailDelete from './components/WorkflowDetailDelete'
import CalendlyCredentials from './components/CalendlyCredentials'

const WorkflowSettingsDetail = () => {
	const { t } = useTranslation()

	const params = useParams()

	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const [form, setForm] = useState()
	const [tasks, setTasks] = useState([])
	const [taskTypes, setTaskTypes] = useState([])
	const [recipients, setRecipients] = useState([])

	const [promptOpen, setPromptOpen] = useState(false)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'workflow.settings',
			backUrl: '/workflows'
		})
	}, [])

	const handleAddTask = () => {
		setTasks([...tasks, { continueOnFalse: false, taskType: '', priority: '' }])
	}

	const handleRemoveTask = (index) => {
		const tmp = [...tasks]
		tmp.splice(index, 1)
		setTasks(tmp)
	}

	const handleUpdateTaskInList = (index, task) => {
		const tmp = [...tasks]
		tmp[index] = task
		setTasks(tmp)
	}

	useEffect(() => {
		;(async () => {
			console.log('params.id', params.id)

			setLoading(true)

			if (params.id) {
				try {
					const { data } = await axios.get(`${SMARTFORM_API_URL}/${params.id}`, {})
					const { notificationRecipients, tasks, ...formData } = camelcaseKeys(data, {
						deep: true
					})
					setForm(formData)
					setTasks(tasks.sort((a, b) => (+a.priority > +b.priority ? 1 : -1)))
					setRecipients(notificationRecipients)

					setError(false)
				} catch (e) {
					setError(true)
				}
			} else {
				setForm({
					name: '',
					notificationsEnabled: false,
					notificationsTemplateName: '',
					active: true,
					fixedNotificationRecipients: [],
					tasks: []
				})
				setRecipients([])
				setTasks([])
			}

			setLoading(false)

			try {
				const { data } = await axios.get(`${SMARTFORM_API_URL}/tasks/types`)
				console.log(data)
				setTaskTypes(data)
			} catch (e) {
				console.log(e)
			}
		})()
	}, [params.id])

	return (
		<>
			{error ? (
				<Error />
			) : loading ? (
				<LinearProgress />
			) : (
				<>
					<WorkflowDetailDelete
						promptOpen={promptOpen}
						setPromptOpen={setPromptOpen}
						name={form.name}
					/>

					<WorkflowSettingsDetailBaseForm
						form={form}
						setForm={setForm}
						recipients={recipients}
						tasks={tasks}
						setPromptOpen={setPromptOpen}
					/>

					<WorkflowSettingsDetailRecipients
						recipients={recipients}
						setRecipients={setRecipients}
					/>

					<Box mt={4}>
						<Card
							component={Paper}
							classes={{
								root: 'primary'
							}}
						>
							<CardHeader title={t('workflow.tasks')} />

							{!!tasks.length && (
								<CardContent>
									{tasks.map((task, index) => (
										<WorkflowDetailTasks
											key={index}
											task={task}
											taskTypes={taskTypes}
											formId={form.uuid}
											index={index}
											removeTaskFromList={handleRemoveTask}
											updateTaskInList={handleUpdateTaskInList}
										/>
									))}
								</CardContent>
							)}

							<CardActions>
								<Button
									variant="outlined"
									color={'primary'}
									onClick={handleAddTask}
								>
									{t('workflow.task.add')}
								</Button>
							</CardActions>
						</Card>
					</Box>

					{!!params.id && <CalendlyCredentials workflowId={params.id} />}
				</>
			)}
		</>
	)
}

export default WorkflowSettingsDetail
