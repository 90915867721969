import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { DEFAULT_LANGUAGE } from './config/constants'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationFi from './components/auth/locales/fi.json'
import translationEn from './components/auth/locales/en.json'

import { I18n } from 'aws-amplify'

const DETECTION_OPTIONS = {
	order: ['localStorage'],
	caches: ['localStorage']
}

// amplify
I18n.putVocabulariesForLanguage('en', translationEn)
I18n.putVocabulariesForLanguage('fi', translationFi)

I18n.setLanguage(DEFAULT_LANGUAGE)

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		detection: DETECTION_OPTIONS,
		// lng: DEFAULT_LANGUAGE,
		fallbackLng: DEFAULT_LANGUAGE, // use en if detected lng is not available

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		},

		ns: ['translations'],
		defaultNS: 'translations',

		useDataAttrOptions: true,

		react: {
			wait: true,
			useSuspense: false
		}
	})

export default i18n
