import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../../../../../../utils/axios'
import { API_URL } from '../../../../../../config/constants'
import { SnackbarContext } from '../../../../../../context'
import formatDate from '../../../../../../utils/formatDate'
import { Delete } from '@material-ui/icons'

const CalendlyList = ({ webhooks, setWebhooks }) => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState(false)
	const { showMessage } = useContext(SnackbarContext)

	const handleDelete = (webhook) => async () => {
		setLoading(true)

		try {
			await axios.delete(`${API_URL}/integrations/calendly/unsubscribe`, {
				data: {
					webhookId: webhook.uuid,
					apiToken: webhook.signing_secret
				}
			})

			setWebhooks(webhooks.filter((item) => item.id !== webhook.id))
			showMessage(t('3rd_party_credentials.saved'), 'success')
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setLoading(false)
	}

	return (
		<>
			{!!webhooks.length && (
				<TableContainer>
					<Table
						classes={{
							root: 'primary'
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell>{t('3rd_party_credentials.calendly.uri')}</TableCell>
								<TableCell>
									{t('3rd_party_credentials.calendly.webhook_subscription_id')}
								</TableCell>
								<TableCell>{t('created_by')}</TableCell>
								<TableCell>{t('created_at')}</TableCell>
								<TableCell>{t('delete')}</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{webhooks.map((item, index) => (
								<TableRow key={index}>
									<TableCell>{item.uri}</TableCell>
									<TableCell>{item.webhook_subscription_id}</TableCell>
									<TableCell>{item.created_by_name}</TableCell>
									<TableCell>{formatDate(item.created_at)}</TableCell>
									<TableCell>
										<IconButton
											aria-label="delete"
											onClick={handleDelete(item)}
											disabled={loading}
										>
											<Delete fontSize="small" />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	)
}

export default CalendlyList
