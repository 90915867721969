import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	textField: {
		marginBottom: theme.spacing(2)
	},
	button: {
		marginRight: theme.spacing(4)
	}
}))

export default useStyles
