import { Table, TableBody } from '@material-ui/core'
import { EInvoices } from './components'

const InvoicingAddressSingleInfo = (props) => {
	const invoice = props.data

	return (
		<Table>
			<TableBody>
				{invoice.length > 0 && (
					<EInvoices data={invoice} />
				)}
			</TableBody>
		</Table>
	)
}

export default InvoicingAddressSingleInfo
