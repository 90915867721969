import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { SMARTFORM_API_URL } from '../../../config/constants'
import {
	Box,
	Button,
	LinearProgress,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow
} from '@material-ui/core'
import { AddCircle, FileCopyOutlined as FileCopy } from '@material-ui/icons'
import formatDate from '../../../utils/formatDate'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import Error from '../../../layouts/Main/components/Error'
import { SnackbarContext, UserContext } from '../../../context'
import { PageContext } from '../../../context/PageContext'

const WorkflowSettings = () => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)
	const navigate = useNavigate()

	const [forms, setForms] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const { setPageConfig } = useContext(PageContext)
	const { user } = useContext(UserContext)

	useEffect(() => {
		setPageConfig({
			name: 'workflow.settings'
		})
	}, [])

	useEffect(() => {
		;(async () => {
			setLoading(true)

			try {
				const { data } = await axios.get(`v3/workflow`, {
					params: {
						organization_id: `eq.${user.organizationId}`
					}
				})
				console.log(data)
				setForms(data)
			} catch (e) {
				setError(true)
			}

			setLoading(false)
		})()
	}, [])

	const handleDefaultForm = async () => {
		const { data } = await axios.get(`${SMARTFORM_API_URL}/start`)
		console.log(data)
		setForms(data)
	}

	const handleNavigate = (id) => () => {
		navigate(`/workflows/${id}`)
	}

	const handleCopyId = (id) => () => {
		navigator.clipboard.writeText(id)
		showMessage(t('workflow.id_copied'), 'success')
	}

	const handleAddForm = () => {
		navigate(`/workflows/create`)
	}

	return (
		<>
			{!loading && !error && (
				<Box mb={3}>
					{forms.length ? (
						<Button
							onClick={handleAddForm}
							variant={'contained'}
							color={'primary'}
							startIcon={<AddCircle />}
						>
							{t('workflow.add_new')}
						</Button>
					) : (
						<Button
							onClick={handleDefaultForm}
							variant={'contained'}
							color={'primary'}
							startIcon={<AddCircle />}
						>
							{t('workflow.add_default')}
						</Button>
					)}
				</Box>
			)}

			{error ? (
				<Error />
			) : (
				<TableContainer>
					<Table
						classes={{
							root: 'primary'
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell width={440}>{t('id')}</TableCell>
								<TableCell>{t('name')}</TableCell>
								<TableCell>{t('status')}</TableCell>
								<TableCell>{t('created_at')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{forms.map((form, i) => (
								<TableRow
									key={i}
									style={{
										cursor: 'pointer'
									}}
								>
									<TableCell onClick={handleCopyId(form.uuid)}>
										<Box display={'flex'} gridGap={24} alignItems={'center'}>
											{form.uuid}
											<FileCopy fontSize={'small'} />
										</Box>
									</TableCell>
									<TableCell onClick={handleNavigate(form.uuid)}>
										<Link>{form.name}</Link>
									</TableCell>
									<TableCell onClick={handleNavigate(form.uuid)}>
										{form.active ? t('active') : t('disabled')}
									</TableCell>
									<TableCell onClick={handleNavigate(form.uuid)}>
										{formatDate(form.created_at)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						{loading && (
							<TableFooter>
								<TableRow>
									<TableCell colSpan={4}>
										<LinearProgress />
									</TableCell>
								</TableRow>
							</TableFooter>
						)}
					</Table>
				</TableContainer>
			)}
		</>
	)
}

export default WorkflowSettings
