import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'

import {
	Button,
	Card,
	CardHeader,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import CompanyAmlPerson from './CompanyAmlPerson'
import { UserContext } from '../../../../context'
import { AccountBalanceWalletOutlined } from '@material-ui/icons'

const CompanyAmlPersons = ({ data, loadAmlData, loading }) => {
	const { user } = useContext(UserContext)

	const { t } = useTranslation()

	const [amlData, setAmlData] = useState(null)

	useEffect(() => {
		setAmlData(data.filter((item) => item.data && item.data.amlStatus))
	}, [data])

	return (
		<>
			<Card component={Paper}>
				<CardHeader
					title={t('company.amlStatus')}
					avatar={<AccountBalanceWalletOutlined />}
					action={
						<>
							{user.organisation?.amlEnabled ? (
								<Button
									color={'primary'}
									variant={'contained'}
									onClick={loadAmlData}
									disabled={loading}
								>
									{t('company.retrieveAmlData')}
								</Button>
							) : (
								<Button color={'primary'} variant={'contained'} disabled>
									{t('company.retrieveAmlNotAvailable')}
								</Button>
							)}
						</>
					}
				/>
				{loading ? (
					<LinearProgress />
				) : (
					<>
						{amlData && amlData.length > 0 && (
							<>
								<Table
									classes={{
										root: 'secondary'
									}}
								>
									<TableHead>
										<TableRow>
											<TableCell>{t('name')}</TableCell>
											<TableCell>{t('email')}</TableCell>
											<TableCell>{t('amlStatus.amlMatch')}</TableCell>
											<TableCell>
												{t('amlStatus.businessProhibition')}
											</TableCell>
											<TableCell>{t('amlStatus.remarks')}</TableCell>
											<TableCell>{t('amlStatus.pep')}</TableCell>
											<TableCell>{t('amlStatus.rca')}</TableCell>
											<TableCell>{t('amlStatus.sanction')}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{amlData.map((person, i) => {
											return (
												<CompanyAmlPerson
													person={person}
													key={'person-' + i}
												/>
											)
										})}
									</TableBody>
								</Table>
							</>
						)}
					</>
				)}
			</Card>
		</>
	)
}

export default CompanyAmlPersons
