import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'between'
	},
	tableRow: {
		cursor: 'pointer'
	},
	tableRowSuccess: {
		backgroundColor: `${theme.palette.successBg.main} !important`
	},
	tableRowDanger: {
		background: `${theme.palette.errorBg.main} !important`
	},
	root: {
		marginBottom: `${theme.spacing(2)}px`,
		background: theme.palette.background.secondary,
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		height: '48px',
		'&>div': {
			paddingInlineStart: '12px',
			paddingInlineEnd: '60px',
			flexGrow: '1'
		},
		'&>.iconButton': {
			position: 'absolute',
			right: '12px'
		}
	}
}))

export default useStyles
