import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context'
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'

const MonthlyApiUsage = () => {
	const { t, i18n } = useTranslation()
	const { user } = useContext(UserContext)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		;(async () => {
			const { data } = await axios.get(`${API_URL}/v3/monthly_api_usage_stats`, {
				params: {
					uuid: `eq.${user.organizationId}`,
					month: `eq.${new Date(new Date().setDate(1)).toLocaleDateString('en-CA')}`
				}
			})
			setData(data)
			setLoading(false)
		})()
	}, [])

	const snakeToCamel = (str) => {
		str = str
			.toLowerCase()
			.replace('decision_', '')
			.replace(/([-_][a-z])/gi, ($1) => {
				return $1.toUpperCase().replace('-', '').replace('_', '')
			})

		return str
	}

	return (
		<>
			<Typography variant={'h4'}>
				{loading ? <Skeleton /> : <>{t('dashboard.plan.usage')}</>}
			</Typography>

			<br />

			{loading ? (
				<>
					<Typography paragraph={true}>
						<Skeleton />
					</Typography>
					<Typography paragraph={true}>
						<Skeleton />
					</Typography>
				</>
			) : (
				<>
					{!data.length ? (
						<Typography>
							<b>{t('dashboard.plan.usage.empty')}</b>
						</Typography>
					) : (
						<Table
							style={{
								width: '100%'
							}}
						>
							<TableBody>
								{data.map((item, i) => (
									<TableRow key={i}>
										<TableCell
											style={{
												padding: 10,
												width: 200
											}}
										>
											{i18n.exists(
												`decision.result.${snakeToCamel(item.api_endpoint)}`
											)
												? t(
														`decision.result.${snakeToCamel(item.api_endpoint)}`
													)
												: item.api_endpoint}
										</TableCell>

										<TableCell>
											<b>{item.monthly_usage_count}</b>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</>
			)}
		</>
	)
}

export default MonthlyApiUsage
