import { createMuiTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
	palette: {
		background: {
			default: '#F4F6F8',
			paper: colors.common.white,
			neutral: '#f4f5f7',
			secondary: '#F5F6FC'
		},
		primary: {
			contrastText: '#ffffff',
			main: '#5664d2'
		},
		text: {
			primary: '#172b4d',
			secondary: '#6b778c'
		},
		successBg: {
			main: 'rgba(76, 175, 80, 0.1)',
			light: 'rgba(76, 175, 80, 0.06)',
			dark: 'rgba(76, 175, 80, 0.8)'
		},
		errorBg: {
			main: '#FFE6E6',
			dark: 'rgba(244, 67, 54, 0.8)'
		},
		warnBg: {
			main: '#FFF4EA'
		},
		infoBg: {
			main: '#E9F3F8'
		},
		warning: {
			main: '#FF7B01',
			contrastText: '#FF7B01'
		},
		error: {
			main: '#DE2121',
			contrastText: '#DE2121'
		},
		info: {
			main: '#01A4FF',
			contrastText: '#01A4FF'
		}
	},
	shape: {
		borderRadius: '6px'
	},
	shadows,
	typography,
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0
				},
				html: {
					'-webkit-font-smoothing': 'antialiased',
					'-moz-osx-font-smoothing': 'grayscale',
					height: '100%',
					width: '100%'
				},
				body: {
					backgroundColor: '#F5F6FC',
					height: '100%',
					width: '100%'
				},
				a: {
					textDecoration: 'none'
				},
				'#root': {
					height: '100%',
					width: '100%'
				}
			}
		},
		MuiSelect: {
			select: {
				'&:focus': {
					borderRadius: 'inherit'
				}
			}
		},
		MuiIconButton: {
			root: {
				padding: defaultTheme.spacing(1),
				borderRadius: '12px',
				'&[class*="MuiIconButton-colorPrimary"]:not([class*="MuiCheckbox"])': {
					background: '#F5F6FC',
					border: '1px solid #EAE4E4'
				}
			}
		},
		MuiGrid: {
			root: {
				'& [class*="MuiPaper-root"]': {
					height: '100%'
				}
			}
		},
		MuiCard: {
			root: {
				'&[class*="rounded"]:not(.primary)': {
					borderRadius: '12px'
				},
				'&.primary': {
					borderRadius: '0',
					background: '#FAFAFA',
					'& [class*="MuiCardHeader-root"]': {
						backgroundColor: '#5664D21F !important',
						fontWeight: 600,
						padding: defaultTheme.spacing(1),

						'& *': {
							fontSize: '14px',
							lineHeight: '1.5rem'
						}
					},
					'& [class*="MuiCardContent-root"]': {
						background: '#FFFFFF',
						borderLeft: `${defaultTheme.spacing(1)}px solid #FAFAFA`,
						borderRight: `${defaultTheme.spacing(1)}px solid #FAFAFA`,
						borderBottom: `${defaultTheme.spacing(1)}px solid #FAFAFA`,
						padding: defaultTheme.spacing(2)
					},
					'& [class*="MuiCardActions-root"]': {
						background: '#FAFAFA',
						padding: `${defaultTheme.spacing(2)}px ${defaultTheme.spacing(3)}px`
					}
				}
			}
		},
		MuiPaper: {
			root: {
				border: '1px solid #F7EEEE',

				'&[class*="elevation1"]': {
					boxShadow: 'none'
				}
			}
		},
		MuiCardHeader: {
			root: {},
			title: {
				fontWeight: 600,
				fontSize: 16
			},

			avatar: {
				display: 'flex',
				alignItems: 'center'
			}
		},
		MuiTable: {
			root: {
				borderCollapse: 'initial',
				'&.primary, &.secondary': {
					'& [class*="MuiTableHead-root"] [class*="MuiTableCell-head"]': {
						backgroundColor: '#5664D21F !important',
						fontWeight: 600,
						'&:not(:last-child)': {
							borderRight: '4px solid #fff'
						}
					}
				},
				'&.primary [class*="MuiTableFooter-root"] [class*="MuiTableCell-footer"]': {
					backgroundColor: '#FAFAFA !important',
					fontWeight: 600,
					'&:not(:last-child)': {
						borderRight: '4px solid #fff'
					}
				},
				'&.primary [class*="MuiTableBody-root"]': {
					'& [class*="MuiTableCell-body"]': {
						borderBottom: '10px solid #FAFAFA',
						'&:first-child': {
							borderLeft: '8px solid #FAFAFA'
						},
						'&:last-child': {
							borderRight: '8px solid #FAFAFA'
						}
					}
				},
				'&:not(.primary):not(.secondary) [class*="MuiTableHead-root"] [class*="MuiTableCell-head"]':
					{
						backgroundColor: '#F5F6FC !important'
					}
			}
		},

		MuiTableHead: {
			root: {
				'& [class*="MuiTableCell-head"]': {
					paddingTop: defaultTheme.spacing(1),
					paddingBottom: defaultTheme.spacing(1)
				}
			}
		},
		MuiTableBody: {
			root: {
				'& [class*="MuiTableCell-head"]': {
					width: 240
				}
			}
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#FAFAFA'
			}
		},
		MuiTableCell: {
			head: {
				fontWeight: 500,
				fontSize: 14,
				borderBottom: `1px solid #E4E4E4`
			},
			body: {
				borderBottom: `1px solid #E4E4E4`,
				color: '#666464',
				fontSize: 14,
				fontWeight: 500
			},
			root: {
				borderBottom: 0,
				paddingLeft: defaultTheme.spacing(2),
				paddingRight: defaultTheme.spacing(2),
				backgroundColor: '#FFFFFF',
				[defaultTheme.breakpoints.down('sm')]: {
					paddingLeft: defaultTheme.spacing(1),
					paddingRight: defaultTheme.spacing(1)
				}
			},
			stickyHeader: {
				backgroundColor: '#f7f9fc !important'
			}
		}
	}
})

export default theme
