import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { DB_API_URL } from '../../../config/constants'
import { useNavigate } from 'react-router'
import Error from '../../../layouts/Main/components/Error'
import { LinearProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const CompanyByBusinessId = () => {
	const params = useParams()
	const navigate = useNavigate()

	const { t } = useTranslation()

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(async () => {
		setLoading(true)

		try {
			const { data } = await axios.get(`${DB_API_URL}/companies/buid/${params.buid}`)

			if (data.id) {
				navigate(`/companies/${data.id}`)
			} else {
				setError(true)
			}
		} catch (e) {
			setError(true)
		}

		setLoading(false)
	}, [params.buid])

	return (
		<>
			{error ? <Error error={t('company.notFound')} /> : loading ? <LinearProgress /> : <></>}
		</>
	)
}

export default CompanyByBusinessId
