import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import { COMPANIES_API_URL } from '../../../../config/constants'
import axios from '../../../../utils/axios'
import { Box, Button, Dialog, DialogTitle, Grid, TextField } from '@material-ui/core'
import { SnackbarContext } from '../../../../context'

const CompanyPersonForm = ({
	id,
	handleModalClose,
	businessId,
	loadPersons,
	loading,
	setLoading,
	country
}) => {
	const { t } = useTranslation()

	const { showMessage } = useContext(SnackbarContext)

	const [person, setPerson] = useState({
		businessId: businessId,
		email: '',
		firstName: '',
		lastName: '',
		phoneNumber: ''
	})

	const handleChange = (prop, isApproval) => (event) => {
		if (isApproval) {
			const tmp = { ...person.approval, [prop]: event.target.value }
			setPerson({ ...person, ...tmp })
		} else {
			setPerson({ ...person, [prop]: event.target.value })
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		setLoading(true)

		const personApiUrl = `${COMPANIES_API_URL}/${id}/persons?approval=false&country=${country}&refresh=false`
		try {
			await axios.post(personApiUrl, person)

			await loadPersons()

			handleModalClose()
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setLoading(false)
	}

	return (
		<Dialog
			open={true}
			onClose={handleModalClose}
			aria-labelledby="person-form-modal-title"
			aria-describedby="person-form-modal-description"
		>
			<DialogTitle id="simple-modal-title">{t('person.create')}</DialogTitle>

			<Box p={4}>
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<TextField
								type="email"
								required
								label={t('email')}
								fullWidth
								variant="outlined"
								defaultValue={person.email}
								onChange={handleChange('email')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								type="firstName"
								required
								label={t('firstName')}
								fullWidth
								variant="outlined"
								onChange={handleChange('firstName')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								type="lastName"
								required
								label={t('lastName')}
								fullWidth
								variant="outlined"
								onChange={handleChange('lastName')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								type="phoneNumber"
								required
								label={t('phoneNumber')}
								fullWidth
								variant="outlined"
								onChange={handleChange('phoneNumber')}
							/>
						</Grid>
					</Grid>

					<Box mt={8} display={'flex'} gridGap={16} justifyContent={'flex-end'}>
						<Button color="secondary" onClick={handleModalClose}>
							{t('user.cancel')}
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{t('user.save')}
						</Button>
					</Box>
				</form>
			</Box>
		</Dialog>
	)
}

export default CompanyPersonForm
