import { Box, Grid, Paper } from '@material-ui/core'

import useStyles from './Stats.styles'
import ApiUsageChart from './components/ApiUsageChart'
import TotalCompanies from './components/TotalCompanies'
import CompaniesChart from './components/CompaniesChart'
import Notifications from './components/Notifications'
import OrganisationPlan from './components/OrganisationPlan'
import MonthlyApiUsage from './components/MonthlyApiUsageChart'

const Stats = () => {
	const classes = useStyles()

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Paper className={classes.paper}>
								<TotalCompanies />
							</Paper>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Paper className={classes.paper}>
								<Notifications />
							</Paper>
						</Grid>
					</Grid>

					<Box display={'flex'} flexDirection={'column'} mt={2} gridGap={16}>
						<Paper className={classes.paper}>
							<CompaniesChart />
						</Paper>

						<Paper className={classes.paper}>
							<ApiUsageChart />
						</Paper>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Box display={'flex'} flexDirection={'column'} gridGap={16}>
						<Paper className={classes.paper}>
							<OrganisationPlan />
						</Paper>

						<Paper className={classes.paper}>
							<MonthlyApiUsage />
						</Paper>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default Stats
