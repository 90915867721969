import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		width: '100%'
	},
	wrapper: {
		display: 'flex',
		flex: '1 1 auto',
		overflow: 'hidden'
		// paddingTop: theme.spacing(4),
		// paddingLeft: theme.spacing(3),
		// paddingRight: theme.spacing(3),
		// [theme.breakpoints.down('sm')]: {
		// 	paddingLeft: theme.spacing(1),
		// 	paddingRight: theme.spacing(1)
		// }
	},
	container: {
		display: 'flex',
		flex: '1 1 auto',
		overflow: 'hidden',
		paddingBottom: theme.spacing(2)
	},
	rootBox: {
		paddingTop: theme.spacing(8),
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
		flex: '1 1 auto',
		minHeight: '100%',
		maxWidth: '100%',
		paddingTop: theme.spacing(6),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1)
		}
		// overflow: 'auto'
	},
	drawer: {
		flexShrink: 0
	},
	sidebarTrigger: {
		color: theme.palette.text.primary,
		marginRight: '0 !important'
	},
	drawerSidebar: {}
}))

export default useStyles
