const formatDate = (date, withTime = true) => {
	if (!date) return ''

	const format = 'en-GB'

	let d
	if (!isNaN(date) || date.indexOf('T') > -1) {
		// timestamp
		d = new Date(date)
	} else {
		date = date + ' UTC'
		d = new Date(date.replace(/-/g, '/'))
	}

	if (withTime) {
		return d.toLocaleString(format)
	} else {
		return d.toLocaleDateString(format)
	}
}

export default formatDate
