const typegraphy = {
	h1: {
		fontWeight: 500,
		fontSize: 24,
		letterSpacing: '-0.24px'
	},
	h2: {
		fontWeight: 500,
		fontSize: 22,
		letterSpacing: '-0.24px'
	},
	h3: {
		fontWeight: 500,
		fontSize: 20,
		letterSpacing: '-0.06px'
	},
	h4: {
		fontWeight: 500,
		fontSize: 16,
		letterSpacing: '-0.06px'
	},
	h5: {
		fontWeight: 500,
		fontSize: 14,
		letterSpacing: '-0.05px'
	},
	h6: {
		fontWeight: 500,
		fontSize: 12,
		letterSpacing: '-0.05px'
	},
	subtitle1: {
		fontSize: 14
	},
	body1: {
		fontSize: 14
	},
	overline: {
		fontWeight: 500
	},
	button: {
		textTransform: 'capitalize'
	},
	fontSize: 14,
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'Segoe UI',
		'Helvetica',
		'Arial',
		'sans-serif',
		'Apple Color Emoji',
		'Segoe UI Emoji'
	].join(',')
}

export default typegraphy
