import {
	Card,
	CardContent,
	CardHeader,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core'
import formatDate from '../../../../utils/formatDate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoOutlined } from '@material-ui/icons'

const CompanyGeneral = ({ result, handleRefresh, refreshing }) => {
	const { t } = useTranslation()

	return (
		<Card component={Paper}>
			<CardHeader title={`${t('general_info')} `} avatar={<InfoOutlined />} />

			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell variant={'head'} width="25%">
								{t('name')}
							</TableCell>
							<TableCell>{result.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('business_id')}</TableCell>
							<TableCell>{result.primaryBusinessId}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('country')}</TableCell>
							<TableCell>{t('countryCode.' + result.countryCode)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('legal_form')}</TableCell>
							<TableCell>{result.formValue}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('industry')}</TableCell>
							<TableCell>{result.businessLineValue}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('sector')}</TableCell>
							<TableCell>
								{result.companySector
									? t('sector.' + result.companySector)
									: t('sector.notAvailable')}
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell variant={'head'}>{t('created')}</TableCell>
							<TableCell>{formatDate(result.createdAt)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'head'}>{t('modified')}</TableCell>
							<TableCell>
								{result.modifiedAt ? formatDate(result.modifiedAt) : ''}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	)
}

export default CompanyGeneral
