import { Box, Link, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const NotFound = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const navigateBack = () => {
		navigate(-1)
	}

	return (
		<Box mt={26}>
			<Typography variant={'h4'} align={'center'}>
				{t('pageNotFound')}
			</Typography>
			<br />
			<Typography variant={'h6'} align={'center'}>
				<Link onClick={navigateBack}>{t('pageNotFound.back')}</Link>
			</Typography>
		</Box>
	)
}

export default NotFound
