import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { SnackbarContext, UserContext } from '../../../../context'
import axios from '../../../../utils/axios'
import { PERSONS_API_URL } from '../../../../config/constants'
import { Button, Link, TableCell, TableRow } from '@material-ui/core'
import formatDate from '../../../../utils/formatDate'
import useStyles from './styles'
import { useNavigate, useParams } from 'react-router-dom'

const PERSON_STATUS = {
	verified: 'Verified',
	personDecisionApproved: 'PersonDecisionApproved',
	deactivated: 'Deactivated',
	personDecisionRejected: 'PersonDecisionRejected'
}

const CompanyPerson = ({ person }) => {
	const classes = useStyles()

	const navigate = useNavigate()
	const params = useParams()
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)
	const { isSupport } = useContext(UserContext)

	const isVerified = (status) => {
		return status
	}

	const [verified, setVerified] = useState(isVerified(person.companyRepresentationApproved))

	const toggleVerifyPerson = (person) => {
		setLoading(true)
		axios
			.patch(`${PERSONS_API_URL}/${person.uuid}/status`, {
				representationStatus: !verified
			})
			.then(({ data }) => {
				showMessage(t('user.update.success'), 'success')
				setVerified(isVerified(data.representationStatus))
			})
			.catch((e) => {
				if (e.response) {
					const data = e.response.data
					console.log(data?.error?.message)
				} else {
					console.log(e.message)
				}
				showMessage(t('user.update.error'), 'error')
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const gotoPersonTable = (id) => {
		navigate(`/companies/${params.id}/${id}`)
	}

	return (
		<TableRow
			className={`${classes.tableRow} ${
				verified ? classes.tableRowSuccess : !person.active ? classes.tableRowDanger : ''
			}`}
		>
			<TableCell onClick={() => gotoPersonTable(person.uuid)}>
				<Link>
					{person.firstName} {person.lastName}
				</Link>
			</TableCell>
			<TableCell>{person.phoneNumber}</TableCell>
			<TableCell>{person.email}</TableCell>
			{!isSupport() ? (
				<TableCell>
					<Button
						color="primary"
						onClick={() => {
							toggleVerifyPerson(person)
						}}
						disabled={!person.active || loading}
					>
						{t(`${verified ? 'deactivate' : 'company.person.verify'}`)}
					</Button>
				</TableCell>
			) : (
				<TableCell>{t(`${verified ? 'active' : 'amlStatus.matchNotFound'}`)}</TableCell>
			)}

			<TableCell>{formatDate(person.createdAt)}</TableCell>
		</TableRow>
	)
}

export default CompanyPerson
