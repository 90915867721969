import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3)
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	headerBox: {
		columnGap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			rowGap: theme.spacing(1),
			columnGap: theme.spacing(2)
		}
	}
}))

export default useStyles
