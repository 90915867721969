import axios from 'axios'
import { Auth } from 'aws-amplify'
import { API_URL } from '../config/constants'

const axiosRequestInterceptor = async (config) => {
	config.baseURL = API_URL

	const session = await Auth.currentSession()

	const token = session.getIdToken().getJwtToken()

	config.headers.Authorization = `Bearer ${token}`

	return config
}

axios.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))

export default axios
