import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../../../../utils/axios'
import { COMPANIES_API_URL } from '../../../../config/constants'
import { SnackbarContext } from '../../../../context'
import formatDate from '../../../../utils/formatDate'
import { CloudDownloadOutlined, GetApp, SupervisorAccountOutlined } from '@material-ui/icons'
import CompanyDataNotAvailable from '../../../../components/shared/CompanyDataNotAvailable'

const CompanyDocuments = ({ id }) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [loading, setLoading] = useState(false)

	const [documents, setDocuments] = useState([])

	useEffect(() => {
		;(async () => {
			await getDocumentsList()
		})()
	}, [id])

	const getDocumentsList = async () => {
		setLoading(true)

		try {
			const { data } = await axios.get(
				`${COMPANIES_API_URL}/${id}/trade-register-extracts`,
				{}
			)
			setDocuments(data)
		} catch (e) {}

		setLoading(false)
	}

	const downloadDocument = async (url) => {
		setLoading(true)

		const res = await axios.get(url, {
			responseType: 'blob'
		})

		try {
			const data = JSON.parse(await res.data.text())
			if (data.Error) {
				showMessage(t('company.documentDownload.error'), 'error')
			}

			window.open(URL.createObjectURL(res.data))
		} catch (e) {}

		setLoading(false)
	}

	const downloadNewDocument = async () => {
		await downloadDocument(`${COMPANIES_API_URL}/${id}/trade-register-extract`)
		await getDocumentsList()
	}

	const downloadDocumentById = async (docId) => {
		await downloadDocument(`${COMPANIES_API_URL}/${id}/trade-register-extracts/${docId}`)
	}

	return (
		<Card component={Paper}>
			<CardHeader
				title={t('trade_register_extract')}
				avatar={<SupervisorAccountOutlined />}
				action={
					<Button
						onClick={downloadNewDocument}
						disabled={loading}
						color={'primary'}
						variant={'outlined'}
						startIcon={<CloudDownloadOutlined fontSize={'small'} />}
					>
						{t('get')}
					</Button>
				}
			/>

			{!documents.length ? (
				<CardContent>
					<CompanyDataNotAvailable />
				</CardContent>
			) : (
				<CardContent>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('date')}</TableCell>
								<TableCell>{t('action')}</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{documents.map((item, index) => (
								<TableRow key={index}>
									<TableCell>{formatDate(item.createdAt)}</TableCell>
									<TableCell width="200px">
										<Button
											onClick={() => {
												downloadDocumentById(item.id)
											}}
											disabled={loading}
											color={'primary'}
											startIcon={<GetApp />}
										>
											{t('download_pdf')}
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</CardContent>
			)}
		</Card>
	)
}

export default CompanyDocuments
