import React, { useContext, useEffect } from 'react'
import DNBCredentials from '../components/DNBCredentials'
import PipedriveCredentials from '../components/PipedriveCredentials'
import { PageContext } from '../../../context/PageContext'
import { Paper, Tab, Tabs } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

const ThirdPartyCredentials = () => {
	const { t } = useTranslation()

	const { setPageConfig } = useContext(PageContext)

	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	useEffect(() => {
		setPageConfig({
			name: 'nav.3rd_party_credentials'
		})
	}, [])

	return (
		<>
			<Paper square>
				<Tabs
					value={value}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleChange}
					aria-label="disabled tabs example"
				>
					<Tab label={t('3rd_party_credentials.dnbCredentials')} />
					<Tab label={t('3rd_party_credentials.pipedriveCredentials')} />
				</Tabs>
			</Paper>
			<TabPanel value={value} index={0}>
				<DNBCredentials />
			</TabPanel>

			<TabPanel value={value} index={1}>
				<PipedriveCredentials />
			</TabPanel>
		</>
	)
}

export default ThirdPartyCredentials
