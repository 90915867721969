import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'

import formatDate from '../../../utils/formatDate'

import snakecaseKeys from 'snakecase-keys'

import axios from '../../../utils/axios'
import { COMPANIES_API_URL } from '../../../config/constants'

import useStyles from './CompanyList.styles'

import {
	Box,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	IconButton,
	TableSortLabel,
	Button,
	Grid,
	InputAdornment,
	TextField
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { PAGE_SIZE, ORDER_DIRS } from '../../../config/constants'
import { AddCircle } from '@material-ui/icons'

const CompanyListTable = ({ setError }) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const classes = useStyles()

	const [companies, setCompanies] = useState([])
	const [orderBy, setOrderBy] = useState('created_at')
	const [orderDir, setOrderDir] = useState('desc')
	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		loadMore()
	}, [offset, orderBy, orderDir, keyword])

	const handleAddCompanyClick = () => {
		navigate('/companies/add')
	}

	const loadMore = () => {
		setLoading(true)
		setLoadFinish(false)

		const params = snakecaseKeys(
			{
				limit: PAGE_SIZE,
				offset,
				orderBy,
				orderDir,
				q: keyword
			},
			{ deep: true }
		)

		axios
			.get(`${COMPANIES_API_URL}`, { params })
			.then(({ data }) => {
				let temp = companies
				temp = temp.concat(data)
				setCompanies(temp)
				setLoading(false)
				if (data.length < PAGE_SIZE) setLoadFinish(true)
			})
			.catch((e) => {
				setError(true)
				setLoading(false)
			})
	}

	const resetSearch = () => {
		setOffset(0)
		setCompanies([])
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) setKeyword(value)
		else if (!offset) {
			loadMore()
		}
		resetSearch()
	}

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)

		resetSearch()
	}

	const gotoResultDetail = (id) => {
		navigate(`/companies/${id}`)
	}

	const headCells = [
		{ id: 'name', company: true, label: t('company_name'), width: 40, sortable: true },
		{ id: 'primary_business_id', label: t('businessId'), width: 20, sortable: true },
		{ id: 'status', label: t('status'), width: 20, sortable: true },
		{ id: 'created_at', label: t('date'), width: 20, sortable: true }
	]

	return (
		<>
			<Box className={classes.header}>
				<form onSubmit={handleSearchFormSubmit}>
					<TextField
						label={t('company.search.placeholder')}
						id="search"
						variant={'outlined'}
						size={'small'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										type="submit"
										className="iconButton"
										aria-label="search"
									>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</form>

				<Button
					variant="contained"
					color="primary"
					onClick={handleAddCompanyClick}
					className={classes.companyAddButton}
					startIcon={<AddCircle />}
				>
					{t('company.add.title')}
				</Button>
			</Box>

			<TableContainer>
				<Table
					aria-label="Companies Table"
					classes={{
						root: 'primary'
					}}
				>
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={orderBy === headCell.id ? orderDir : false}
									width={`${headCell.width}%`}
								>
									{headCell.sortable ? (
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? orderDir : 'asc'}
											onClick={() => {
												handleSortChange(headCell.id, headCell.company)
											}}
										>
											{headCell.label}
										</TableSortLabel>
									) : (
										<>{headCell.label}</>
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{companies.map((result, i) => (
							<TableRow
								className={classes.tableRow}
								key={`result-table-row-${i}`}
								onClick={() => gotoResultDetail(result.id)}
							>
								<TableCell>{result.name}</TableCell>
								<TableCell>{result.primaryBusinessId}</TableCell>
								<TableCell>
									{result.status
										? t(
												[`company.status.${result.status.toUpperCase()}`],
												result.status
											)
										: t('company.status.unspecified')}
								</TableCell>

								<TableCell>{formatDate(result.createdAt)}</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						{!loadFinished && (
							<TableRow>
								<TableCell colSpan={4}>
									<Grid container direction="column" alignItems="center">
										{loading && <LinearProgress className={classes.progress} />}
										<Button
											disabled={loading}
											variant="outlined"
											color="primary"
											onClick={() => setOffset(offset + PAGE_SIZE)}
										>
											{t('load_more')}
										</Button>
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}

export default CompanyListTable
