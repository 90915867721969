import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	tableRowSuccess: {
		backgroundColor: `${theme.palette.successBg.main} !important`
	},
	tableRowDanger: {
		background: `${theme.palette.errorBg.main} !important`
	},
	tableRowSuccessLight: {
		background: `${theme.palette.successBg.light} !important`
	},
	tableRowWarn: {
		background: `${theme.palette.warnBg.main} !important`
	},
	tableRowInfo: {
		background: `${theme.palette.infoBg.main} !important`
	},
	tableRow: {
		cursor: 'pointer'
	},
	borderBottom: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
	},
	nestedTableCell: {
		padding: `0 0 ${theme.spacing(6)}px`,
		background: '#fff !important'
	},
	iconButton: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1),
		padding: theme.spacing(1)
	},
	textDanger: {
		color: theme.palette.error.main
	},
	textWarn: {
		color: theme.palette.warning.main
	},
	textInfo: {
		color: theme.palette.info.main
	},
	textSuccess: {
		color: theme.palette.success.main
	}
}))

export default useStyles
