import { createContext, useState } from 'react'

export const UserContext = createContext({
	user: null,
	setUser: () => {},
	isInternal: () => {},
	isAdmin: () => {},
	isSupport: () => {},
	isFormPageAvailable: null,
	setIsFormPageAvailable: () => {},
	isLoading: () => {},
	setIsLoading: () => {}
})

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isFormPageAvailable, setIsFormPageAvailable] = useState(null)

	const isInGroup = (group) => user?.groups && user?.groups.indexOf(group) > -1

	const isAdmin = () => isInGroup('admin')
	const isInternal = () => isInGroup('internal')
	const isSupport = () => isInGroup('support')

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
				isInternal,
				isAdmin,
				isSupport,
				isFormPageAvailable,
				setIsFormPageAvailable,
				isLoading,
				setIsLoading
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
