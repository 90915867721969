import { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { PERSONS_API_URL } from '../../../config/constants'
import { Link, useParams } from 'react-router-dom'
import {
	Box,
	Card,
	CardHeader,
	Grid,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	Button,
	CardContent,
	Breadcrumbs
} from '@material-ui/core'
import Error from '../../../layouts/Main/components/Error'
import { useTranslation } from 'react-i18next'
import formatDate from '../../../utils/formatDate'
import React from 'react'
import { HistoryTable } from '../../../components/history'
import { SnackbarContext } from '../../../context'
import { PageContext } from '../../../context/PageContext'
import {
	AnnouncementOutlined,
	CallSplitOutlined,
	CheckBoxOutlined,
	HistoryOutlined,
	LibraryBooksOutlined,
	PersonOutlineOutlined
} from '@material-ui/icons'
import camelcaseKeys from 'camelcase-keys'

const TH_WIDTH = 280
const PersonDetail = () => {
	const { t } = useTranslation()

	const params = useParams()

	const [result, setResult] = useState(null)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [updatingStatus, setUpdatingStatus] = useState(false)

	const [prompts, setPrompts] = useState([])

	const [decisions, setDecisions] = useState([])

	const [histories, setHistories] = useState([])

	const [approvalsStatus, setApprovalsStatus] = useState([])

	const { showMessage } = useContext(SnackbarContext)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({ name: 'person', backLink: '/persons' })
	}, [])

	useEffect(() => {
		const load = async () => {
			try {
				setLoading(true)

				const { data } = await axios.get(`v3/person`, {
					params: {
						uuid: `eq.${params.id}`
					}
				})

				setResult(camelcaseKeys(data[0]))
				setLoading(false)
			} catch (error) {
				console.log(error)
				setError(true)
			}
		}

		const loadPrompts = async () => {
			try {
				const { data } = await axios.get(`${PERSONS_API_URL}/${params.id}/approvals`)
				setPrompts(data)
			} catch (error) {
			} finally {
			}
		}

		// const loadDecisions = async () => {
		// 	try {
		// 		const { data } = await axios.get(`${PERSONS_API_URL}/${params.id}`)
		// 		setDecisions(data)
		// 	} catch (error) {
		// 	} finally {
		// 	}
		// }

		const loadHistory = async () => {
			try {
				const { data } = await axios.get(`${PERSONS_API_URL}/${params.id}/history`)

				setHistories(data)
			} catch (error) {
			} finally {
			}
		}

		const loadApprovalsStatus = async () => {
			try {
				const { data } = await axios.get(`${PERSONS_API_URL}/${params.id}/approvals/status`)

				setApprovalsStatus(data)
			} catch (error) {}
		}

		load()
		loadPrompts()
		// loadDecisions()
		loadHistory()
		loadApprovalsStatus()
	}, [params.id])

	const toggleStatusUpdate = async (body) => {
		setUpdatingStatus(true)

		try {
			const { data } = await axios.patch(`${PERSONS_API_URL}/${params.id}/status`, body)
			showMessage(t('user.update.success'), 'success')

			setResult({
				...result,
				...data,
				companyRepresentationApproved: data.representationStatus
			})
		} catch (e) {
			console.log(e)
			showMessage(t('user.update.error'), 'error')
		}

		setUpdatingStatus(false)
	}

	const handleVerifyIdentity = async () => {
		await toggleStatusUpdate({
			identityVerified: !result.identityVerified
		})
	}

	const handleDeactivate = async () => {
		await toggleStatusUpdate({
			active: !result.active
		})
	}

	const handleVerifyRepresentation = async () => {
		await toggleStatusUpdate({
			representationStatus: !result.companyRepresentationApproved
		})
	}

	return (
		<>
			<nav>
				<Breadcrumbs separator={<> / </>} aria-label="breadcrumb">
					{params.companyId ? (
						<Link to={`/companies/${params.companyId}`}>{t('nav.back.company')}</Link>
					) : params.resultId ? (
						<Link to={`/results/${params.resultId}`}>{t('nav.back.result')}</Link>
					) : (
						<Link to={`/persons`}>{t('nav.back.persons')}</Link>
					)}

					{!params.companyId && result && (
						<Link to={`/companies/${result.companyId}`}>{t('nav.to.company')}</Link>
					)}
				</Breadcrumbs>
			</nav>

			{error ? (
				<Error />
			) : loading ? (
				<LinearProgress />
			) : (
				<Box mt={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Card>
								<CardHeader
									title={t('person')}
									avatar={<PersonOutlineOutlined />}
								/>

								<CardContent>
									<Table>
										<TableBody>
											<TableRow>
												<TableCell variant={'head'} width={TH_WIDTH}>
													{t('name')}
												</TableCell>
												<TableCell colSpan={2}>
													{result.firstName} {result.lastName}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>{t('email')}</TableCell>
												<TableCell colSpan={2}>{result.email}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>{t('phone')}</TableCell>
												<TableCell colSpan={2}>
													{result.phoneNumber}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('status')}
												</TableCell>
												<TableCell colSpan={2}>
													{t(`person.status.${result.status}`)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('person.representationStatus')}
												</TableCell>
												<TableCell>
													{t(
														`person.representationStatus.${result.companyRepresentationApproved || false}`
													)}
												</TableCell>

												<TableCell>
													<Button
														onClick={handleVerifyRepresentation}
														color="primary"
														variant="contained"
														disabled={updatingStatus}
													>
														{t(
															`person.representation.${
																result.companyRepresentationApproved
																	? 'disapprove'
																	: 'approve'
															}`
														)}
													</Button>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell variant={'head'}>
													{t('person.identityVerified')}
												</TableCell>
												<TableCell>
													{t(
														`person.identityVerified.${result.identityVerified}`
													)}
												</TableCell>
												<TableCell>
													<Button
														onClick={handleVerifyIdentity}
														color="primary"
														variant="contained"
														disabled={updatingStatus}
													>
														{t(
															`person.${
																result.identityVerified
																	? 'disproveIdentity'
																	: 'verifyIdentity'
															}`
														)}
													</Button>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell variant={'head'} width={TH_WIDTH}>
													{t('person.active')}
												</TableCell>
												<TableCell>
													{t(`person.active.${result.active}`)}
												</TableCell>
												<TableCell>
													<Button
														onClick={handleDeactivate}
														color="primary"
														variant="contained"
														disabled={updatingStatus}
													>
														{t(
															`person.${
																result.active
																	? 'deactivate'
																	: 'activate'
															}`
														)}
													</Button>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Card>
								<CardHeader
									title={t('details')}
									avatar={<LibraryBooksOutlined />}
								/>

								<CardContent>
									<Table>
										<TableBody>
											<TableRow>
												<TableCell variant={'head'} width={TH_WIDTH}>
													{t('address')}
												</TableCell>
												<TableCell>{result.address}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('date_of_birth')}
												</TableCell>
												<TableCell>
													{formatDate(result.dateOfBirth, false)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('gender')}
												</TableCell>
												<TableCell>{result.gender}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('language_code')}
												</TableCell>
												<TableCell>{result.languageCode}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('modified')}
												</TableCell>
												<TableCell>
													{result.modifiedAt
														? formatDate(result.modifiedAt)
														: ''}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('created')}
												</TableCell>
												<TableCell>
													{formatDate(result.createdAt)}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Card>
								<CardHeader
									title={t('person.approvalsStatus')}
									avatar={<CheckBoxOutlined />}
								/>

								<CardContent>
									<Table>
										<TableBody>
											<TableRow>
												<TableCell variant={'head'} width={TH_WIDTH}>
													{t('persons.approvalsStatus.emailVerified')}
												</TableCell>
												<TableCell>
													{t(
														`persons.verified.${approvalsStatus.emailVerified}`
													)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>{t('email')}</TableCell>
												<TableCell>{approvalsStatus.email}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t(
														'persons.approvalsStatus.phoneNumberVerified'
													)}
												</TableCell>
												<TableCell>
													{t(
														`persons.verified.${approvalsStatus.phoneNumberVerified}`
													)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('phoneNumber')}
												</TableCell>
												<TableCell>{approvalsStatus.phoneNumber}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('person.identityVerified')}
												</TableCell>
												<TableCell>
													{t(
														`persons.verified.${approvalsStatus.identityVerified}`
													)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t('persons.approvalsStatus.identityMatchName')}
												</TableCell>
												<TableCell>
													{approvalsStatus.identityMatchName}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell variant={'head'}>
													{t(
														'persons.approvalsStatus.identityExpectedName'
													)}
												</TableCell>
												<TableCell>
													{approvalsStatus.identityExpectedName}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Grid>

						<Grid item xs={12}>
							<Card>
								<CardHeader
									title={t('prompt_list')}
									avatar={<AnnouncementOutlined />}
								/>

								<PromptListTable prompts={prompts} t={t} />
							</Card>
						</Grid>

						<Grid item xs={12}>
							<Card component={Paper}>
								<CardHeader title={t('decisions')} avatar={<CallSplitOutlined />} />
								<DecisionListTable decisions={decisions} t={t} />
							</Card>
						</Grid>

						<Grid item xs={12}>
							<Card>
								<CardHeader title={t('history')} avatar={<HistoryOutlined />} />

								<HistoryTable histories={histories} />
							</Card>
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	)
}

const PromptListTable = ({ prompts, t }) => (
	<TableContainer>
		<Table
			classes={{
				root: 'secondary'
			}}
		>
			<TableHead>
				<TableRow>
					<TableCell>{t('email')}</TableCell>
					<TableCell>{t('created')}</TableCell>
					<TableCell>{t('finalized_at')}</TableCell>
					<TableCell>{t('redirect_url')}</TableCell>
					<TableCell>{t('status')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{prompts.map((prompt, i) => (
					<TableRow key={`prompt-${i}`}>
						<TableCell>{prompt.email}</TableCell>
						<TableCell>{formatDate(prompt.createdAt, true)}</TableCell>
						<TableCell>{formatDate(prompt.finalizedAt, true)}</TableCell>
						<TableCell>{prompt.redirectUrl}</TableCell>
						<TableCell>{prompt.status}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
)

const DecisionListTable = ({ decisions, t }) => (
	<TableContainer>
		<Table
			classes={{
				root: 'secondary'
			}}
		>
			<TableHead>
				<TableRow>
					<TableCell>{t('name')}</TableCell>
					<TableCell>{t('result')}</TableCell>
					<TableCell>{t('company_name')}</TableCell>
					<TableCell>{t('person_name')}</TableCell>
					<TableCell>{t('email')}</TableCell>
					<TableCell>{t('created_by')}</TableCell>
					<TableCell>{t('created_at')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{/*{decisions.map((decision, i) => (*/}
				{decisions?.data && (
					<TableRow key={`prompt`}>
						<TableCell>{decisions.data.decisionName}</TableCell>
						<TableCell>{decisions.data.result}</TableCell>
						<TableCell>{decisions.data.companyName}</TableCell>
						<TableCell>
							{decisions.data.firstName} {decisions.data.lastName}
						</TableCell>
						<TableCell>{decisions.data.email}</TableCell>
						<TableCell>{decisions.data.createdBy}</TableCell>
						<TableCell>{formatDate(decisions.createdAt)}</TableCell>
					</TableRow>
				)}
				{/*))}*/}
			</TableBody>
		</Table>
	</TableContainer>
)

export default PersonDetail
