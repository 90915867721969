import { Box, IconButton, Typography } from '@material-ui/core'
import formatDate from '../../utils/formatDate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Autorenew } from '@material-ui/icons'

const UpdateStatus = ({ updatedAt, handleUpdate, loading, isSingleLine }) => {
	const { t } = useTranslation()

	return (
		<Box display={'flex'} justifyContent={'center'} alignItems={'center'} gridGap={24}>
			{updatedAt && (
				<>
					{isSingleLine ? (
						<Box textAlign={'right'}>
							<Typography variant={'subtitle1'}>
								{t('lastUpdatedAtDate')} {formatDate(updatedAt, false)}
							</Typography>
						</Box>
					) : (
						<Box
							textAlign={'right'}
							style={{
								height: '32px',
								marginTop: '-6px'
							}}
						>
							<Typography
								variant={'caption'}
								style={{
									fontSize: '12px',
									display: 'block'
								}}
							>
								{t('lastUpdatedAtDate')}
							</Typography>
							<Typography
								variant={'caption'}
								style={{
									fontSize: '14px',
									display: 'block',
									marginTop: '-4px'
								}}
							>
								{formatDate(updatedAt, false)}
							</Typography>
						</Box>
					)}
				</>
			)}
			<IconButton
				color={'primary'}
				size={isSingleLine ? 'medium' : 'small'}
				variant={'contained'}
				onClick={handleUpdate}
				disabled={loading}
			>
				<Autorenew />
			</IconButton>
		</Box>
	)
}

export default UpdateStatus
