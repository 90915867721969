import {
	Card,
	CardContent,
	CardHeader,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { InfoOutlined } from '@material-ui/icons'

const SmartFormResponseMainInfo = ({ data }) => {
	const { t } = useTranslation()

	return (
		<Card>
			<CardHeader title={t('workflows.response.main_info')} avatar={<InfoOutlined />} />

			<CardContent>
				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell width={200} variant={'head'}>
									{t(`workflows.response.id`)}
								</TableCell>
								<TableCell>{data.id}</TableCell>
							</TableRow>

							<TableRow>
								<TableCell variant={'head'}>
									{t(`workflows.response.smartFormId`)}
								</TableCell>
								<TableCell>{data.smartFormId}</TableCell>
							</TableRow>

							<TableRow>
								<TableCell variant={'head'}>
									{t(`workflows.response.smartFormName`)}
								</TableCell>
								<TableCell>{data.smartFormName}</TableCell>
							</TableRow>

							<TableRow>
								<TableCell variant={'head'}>{t(`company`)}</TableCell>
								<TableCell>
									<Link to={`/companies/${data.companyId}`}>
										{data.companyName}
									</Link>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell variant={'head'}>{t(`person`)}</TableCell>
								<TableCell>
									<Link to={`/persons/${data.personId}`}>{data.personName}</Link>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell variant={'head'}>{t(`email`)}</TableCell>
								<TableCell>{data.personEmail}</TableCell>
							</TableRow>

							<TableRow>
								<TableCell variant={'head'}>{t(`phone`)}</TableCell>
								<TableCell>{data.personPhoneNumber}</TableCell>
							</TableRow>

							<TableRow>
								<TableCell variant={'head'}>{t(`status`)}</TableCell>
								<TableCell>
									{t(`workflows.response.status.${data.status}`)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	)
}

export default SmartFormResponseMainInfo
