import { Hub } from 'aws-amplify'
import { useNavigate } from 'react-router'

import { Box, IconButton, Hidden, Toolbar, Badge } from '@material-ui/core'
import { ChevronLeft, Notifications, NotificationsOutlined } from '@material-ui/icons'
import Avatar from './Avatar'
import Language from './Language'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { PageContext } from '../../../../context/PageContext'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useStyles from './HeaderContent.styles'
import { UserContext } from '../../../../context'

const HeaderContent = (props) => {
	const { user } = useContext(UserContext)
	const { t } = useTranslation()

	const { pageConfig } = useContext(PageContext)
	const navigate = useNavigate()

	const classes = useStyles()

	Hub.listen('auth', (data) => {
		switch (data.payload.event) {
			case 'signOut':
				navigate('/login')
				break
			default:
				break
		}
	})

	return (
		<Toolbar>
			{/*<Logo />*/}

			<Box display={'flex'} alignItems={'center'} gridGap={24}>
				{pageConfig?.backLink && (
					<Link to={pageConfig.backLink}>
						<IconButton color={'primary'}>
							<ChevronLeft />
						</IconButton>
					</Link>
				)}

				<Typography variant={'h3'}>{t(pageConfig?.name)}</Typography>
			</Box>

			<Box flexGrow={1} />

			<Language />

			<Link to={'/notifications'} className={classes.link}>
				{user?.notifications ? (
					<Badge badgeContent={user.notifications} color="primary">
						<NotificationsOutlined color={'inherit'} />
					</Badge>
				) : (
					<NotificationsOutlined color={'inherit'} />
				)}
			</Link>

			<Avatar />

			<Hidden lgUp>{props.sidebarTrigger}</Hidden>
		</Toolbar>
	)
}

export default HeaderContent
