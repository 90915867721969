import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'

import {
	LinearProgress,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	CardHeader,
	Card,
	CardContent,
	Grid
} from '@material-ui/core'

import Error from '../../../layouts/Main/components/Error'
import formatDate from '../../../utils/formatDate'
import { useTranslation } from 'react-i18next'
import formatCurrency from '../../../utils/formatCurrency'
import TableCellWithTooltip from '../../../components/shared/TableCellWithTooltip'
import { PageContext } from '../../../context/PageContext'
import camelcaseKeys from 'camelcase-keys'
import { BusinessOutlined, ImportContacts, InfoOutlined, PersonOutlined } from '@material-ui/icons'

const DecisionDetail = () => {
	const { t, i18n } = useTranslation()

	const params = useParams()

	const [result, setResult] = useState(null)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({ name: 'nav.decision', backLink: '/results' })
	}, [])

	useEffect(() => {
		const load = async () => {
			try {
				setLoading(true)

				const { data } = await axios.get(`${API_URL}/v3/decision_details`, {
					params: {
						uuid: `eq.${params.id}`
					}
				})

				if (!data[0]) throw Error('no data found')

				setResult(
					camelcaseKeys(data[0], {
						deep: true
					})
				)

				setLoading(false)
			} catch (error) {
				setError(true)
			}
		}

		load()
	}, [params.id])

	const parseValue = (value, key) => {
		return typeof value === 'boolean'
			? value.toString()
			: key === 'limit'
				? formatCurrency(value)
				: key === 'dataRetrievedAt'
					? formatDate(value, true)
					: value
	}

	const NestedTable = ({ data }) => {
		const { t } = useTranslation()

		return (
			<>
				{Object.keys(data).map((key) => {
					const value = parseValue(data[key], key)
					return (
						<React.Fragment key={key}>
							{data[key] && typeof data[key] === 'object' ? (
								<></>
							) : (
								<TableRow>
									<TableCell width="35%" component="th" scope="row">
										<TableCellWithTooltip
											title={`decision.result.${key}`}
											info={`decision.result.${key}.info`}
										/>
									</TableCell>

									<TableCell>
										{typeof data[key] === 'boolean' || !/[0-9]/.test(data[key])
											? t(`decision.result.${value}`)
											: value}
									</TableCell>
								</TableRow>
							)}
						</React.Fragment>
					)
				})}
			</>
		)
	}

	const Row = ({ data }) => {
		let json
		if (typeof data === 'string') {
			try {
				json = JSON.parse(data)
			} catch (error) {
				console.log(error)
			}
		} else {
			json = data
		}

		return data ? (
			<>
				{json && json !== true ? (
					<NestedTable data={json} />
				) : (
					<TableRow>
						<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
							data
						</TableCell>
					</TableRow>
				)}
			</>
		) : (
			<></>
		)
	}

	return (
		<>
			{error ? (
				<Error />
			) : loading ? (
				<LinearProgress />
			) : (
				<>
					<Grid container spacing={3}>
						{result && result.company && (
							<Grid item xs={12} md={6}>
								<Card>
									<CardHeader
										title={t('company')}
										avatar={<BusinessOutlined />}
									/>
									<CardContent>
										<TableContainer>
											<Table aria-label="Decision company Table">
												<TableHead>
													<TableRow>
														<TableCell variant={'head'} width="25%">
															{t('company_name')}
														</TableCell>
														<TableCell variant={'head'} width="25%">
															{t('company.primary_business_id')}
														</TableCell>
														<TableCell variant={'head'} width="25%">
															{t('created_at')}
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>
															<Link
																to={`/companies/${result.companyId}`}
															>
																{result.company?.name}
															</Link>
														</TableCell>
														<TableCell>
															{result?.primaryBusinessId}
														</TableCell>
														<TableCell>
															{formatDate(result.companyCreatedAt)}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
						)}

						<Grid item xs={12} md={6}>
							<Card>
								<CardHeader title={t('overview')} avatar={<InfoOutlined />} />
								<CardContent>
									<TableContainer>
										<Table aria-label="Decision Overview Table">
											<TableBody>
												<TableRow>
													<TableCell width="25%">
														<TableCellWithTooltip
															title={'decisions.header'}
															info={`decisions.header.${result.decision?.model}.info`}
														/>
													</TableCell>
													<TableCell>
														{i18n.exists(
															'decision.name.' + result.decision?.name
														) &&
															t(
																'decision.name.' +
																	result.decision?.name
															)}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell width="25%">
														{t('company_name')}
													</TableCell>
													<TableCell>
														{result.companyId ? (
															<Link
																to={`/companies/${result.companyId}`}
															>
																{result.companyName}
															</Link>
														) : (
															result.companyName
														)}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell width="25%">
														{t('primaryBusinessId')}
													</TableCell>
													<TableCell>
														{result.primaryBusinessId}
													</TableCell>
												</TableRow>

												{result.decision?.model?.toLowerCase() ===
													'company-person-decision' && (
													<>
														<TableRow>
															<TableCell width="25%">
																{t('person_name')}
															</TableCell>
															<TableCell>
																{result.personId ? (
																	<Link
																		to={`/results/${result.id}/${result.personId}`}
																	>
																		{result.personFirstName}{' '}
																		{result.personLastName}
																	</Link>
																) : (
																	<>
																		{result.personFirstName}{' '}
																		{result.personLastName}
																	</>
																)}
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell width="25%">
																{t('person.email')}
															</TableCell>
															<TableCell>
																{result.personEmail}
															</TableCell>
														</TableRow>
													</>
												)}

												<TableRow>
													<TableCell>{t('result')}</TableCell>
													<TableCell>
														{i18n.exists(
															'decision.result.' +
																result.decision?.result?.result
														) &&
															t(
																'decision.result.' +
																	result.decision?.result?.result
															)}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														{t('decision.result.description')}
													</TableCell>
													<TableCell>
														{result.decision?.result?.description &&
															t(
																'decision.description.' +
																	result.decision?.result
																		?.description
															)}
													</TableCell>
												</TableRow>

												<TableRow>
													<TableCell>{t('created_by')}</TableCell>
													<TableCell>{result.createdBy}</TableCell>
												</TableRow>

												<TableRow>
													<TableCell>{t('created_at')}</TableCell>
													<TableCell>
														{formatDate(result.createdAt)}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</CardContent>
							</Card>
						</Grid>
						{result && result.person && (
							<Grid item xs={12} md={6}>
								<Card>
									<CardHeader title={t('person')} avatar={<PersonOutlined />} />
									<CardContent>
										<TableContainer>
											<Table aria-label="Decision person Table">
												<TableHead>
													<TableRow>
														<TableCell variant={'head'} width="25%">
															{t('person')}
														</TableCell>
														<TableCell variant={'head'} width="25%">
															{t('person.email')}
														</TableCell>
														<TableCell variant={'head'} width="25%">
															{t('created_at')}
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>
															{result.person?.fullName}
														</TableCell>
														<TableCell>
															{result.person?.email}
														</TableCell>
														<TableCell>
															{result.personCreatedAt}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
						)}
						{result.decision && result.decision.response && (
							<Grid item xs={12} md={6}>
								<Card>
									<CardHeader title={t('response')} avatar={<ImportContacts />} />
									<CardContent>
										<TableContainer>
											<Table aria-label="Decision Response Table">
												<TableBody>
													<Row data={result.decision.response} />
												</TableBody>
											</Table>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
						)}
					</Grid>
				</>
			)}
		</>
	)
}

export default DecisionDetail
