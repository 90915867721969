const getSortedDecisionData = (tableData) => {
	const parseDate = (date) => {
		return typeof date === 'number' ? date : Date.parse(date.replace(/-/g, '/'))
	}

	let groups = tableData.reduce((decision, item) => {
		const name = item.data?.name
		if (!decision[name]) {
			decision[name] = []
		}

		decision[name].push(item)
		return decision
	}, {})

	let tmp = []
	for (const name of Object.keys(groups)) {
		const group = groups[name]

		const latestDate = Math.max(...group.map((e) => parseDate(e.createdAt)))
		const decision = group.find((g) => parseDate(g.createdAt) === latestDate)

		if (decision) {
			try {
				decision.data.result = JSON.parse(decision.data.result)
			} catch (e) {}

			tmp.push(decision)
		}
	}

	return tmp
}

export default getSortedDecisionData
