import { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { CONFIGURATION_API_URL } from '../../../config/constants'
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableRow,
	TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { SnackbarContext, UserContext } from '../../../context'

const DNBCredentials = () => {
	const { user } = useContext(UserContext)

	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [credentialsExists, setCredentialsExists] = useState(false)
	const [loading, setLoading] = useState(true)
	const [saving, setSaving] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [dnbCredentials, setDnbCredentials] = useState({
		username: '',
		password: ''
	})

	useEffect(() => {
		if (user) {
			setDnbCredentials({
				...dnbCredentials,
				organization_id: user.organizationId
			})
		}
	}, [user])

	useEffect(() => {
		;(async () => {
			setLoading(true)

			try {
				const { data } = await axios.get(
					`${CONFIGURATION_API_URL}/organization_bisnode_credentials`,
					{
						params: {
							organization_id: `eq.${user.organizationId}`
						}
					}
				)
				console.log('data', data)
				if (data[0]) {
					setDnbCredentials(data[0])
					setCredentialsExists(true)
				}
			} catch (e) {
				console.log(e)
				setCredentialsExists(false)
			}

			setLoading(false)
		})()
	}, [])

	const handleInputChange = (prop) => (event) => {
		setDnbCredentials({ ...dnbCredentials, [prop]: event.target.value })
	}

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault()

		setSaving(true)

		try {
			if (credentialsExists) {
				await axios.patch(
					`${CONFIGURATION_API_URL}/organization_bisnode_credentials`,
					dnbCredentials
				)
			} else {
				await axios.post(
					`${CONFIGURATION_API_URL}/organization_bisnode_credentials`,
					dnbCredentials
				)
			}

			showMessage(t('3rd_party_credentials.saved'), 'success')
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setCredentialsExists(true)
		setSaving(false)
	}

	const handleDelete = async () => {
		setSaving(true)

		try {
			await axios.delete(`${CONFIGURATION_API_URL}/organization_bisnode_credentials`, {
				data: {}
			})

			setDnbCredentials({
				username: '',
				password: ''
			})

			showMessage(t('3rd_party_credentials.saved'), 'success')
		} catch (e) {
			showMessage(t('error'), 'error')

			setCredentialsExists(false)
		}

		setSaving(false)
	}

	return (
		<Box mb={6}>
			{loading ? (
				<LinearProgress />
			) : (
				<form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
					<Table
						classes={{
							root: 'primary'
						}}
					>
						<TableBody>
							<TableRow>
								<TableCell>
									<TextField
										type={'text'}
										label={t('username')}
										variant="outlined"
										required
										fullWidth={true}
										onChange={handleInputChange('username')}
										value={dnbCredentials.username}
										size={'small'}
									/>
								</TableCell>

								<TableCell>
									<TextField
										type={showPassword ? 'text' : 'password'}
										label={t('password')}
										variant="outlined"
										required
										fullWidth={true}
										onChange={handleInputChange('password')}
										value={dnbCredentials.password}
										size={'small'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
													>
														{showPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</TableCell>
							</TableRow>
						</TableBody>

						<TableFooter>
							<TableRow>
								<TableCell colSpan={2}>
									<Box display={'flex'} gridGap={24}>
										<Button
											type={'submit'}
											variant="contained"
											color="primary"
											disabled={saving}
										>
											{t('save')}
										</Button>

										<Button
											type={'button'}
											variant="outlined"
											color="secondary"
											onClick={handleDelete}
											disabled={saving}
										>
											{t('delete')}
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</form>
			)}
		</Box>
	)
}

export default DNBCredentials
