import { useContext, useEffect } from 'react'
import { PageContext } from '../../context/PageContext'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Add, Redo } from '@material-ui/icons'
import useStyles from './Welcome.styles'

const Welcome = () => {
	const { t } = useTranslation()

	const { setPageConfig } = useContext(PageContext)
	const classes = useStyles()

	useEffect(() => {
		setPageConfig({
			name: 'dashboard'
		})
	}, [])

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			height={'100%'}
		>
			<Typography variant={'h1'} align={'center'} className={classes.header}>
				{t('welcome.header')}
			</Typography>

			<Box mt={2} mb={4}>
				<Typography variant={'h5'} align={'center'}>
					{t('welcome.subHeader')}
				</Typography>
			</Box>

			<Box display={'flex'} justifyContent={'center'} alignItems={'center'} gridGap={'24px'}>
				<Link to={'/companies/add'} className={classes.button}>
					<Add fontSize={'small'} />
					{t('welcome.createCompany')}
				</Link>

				<Link
					to={'https://docs.entercheck.eu/'}
					target="_blank"
					rel="noopener"
					className={classes.link}
				>
					<Redo fontSize={'small'} />
					{t('welcome.tutorial')}
				</Link>
			</Box>
		</Box>
	)
}

export default Welcome
