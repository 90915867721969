import { useTranslation } from 'react-i18next'
import React, { useEffect, useState, useContext } from 'react'

import { Box, Button, LinearProgress, Typography } from '@material-ui/core'

import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'

import { OrganisationTable, UserForm } from '../../../components/organisation'
import Error from '../../../layouts/Main/components/Error'
import { UserContext } from '../../../context/UserContext'
import { PageContext } from '../../../context/PageContext'
import { AddCircle } from '@material-ui/icons'

const Organisation = () => {
	const { isAdmin } = useContext(UserContext)

	const { t } = useTranslation()

	const [roles, setRoles] = useState([])
	const [results, setResults] = useState([])
	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(false)
	const [loaded, setLoaded] = useState(false)

	const [userModalOpen, setUserModalOpen] = useState(false)
	const [userEdit, setUserEdit] = useState(null)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'my_organisation'
		})
	}, [])

	useEffect(() => {
		if (!isAdmin || loaded) return

		const loadUsers = () => {
			setLoading(true)
			axios
				.get(`${API_URL}/organizations/users`)
				.then(({ data }) => {
					setResults(data.filter((user) => !user.deletedAt))

					setLoaded(true)
				})
				.catch((e) => {
					setError(true)
				})
				.finally(() => {
					setLoading(false)
				})
		}

		const loadRoles = () => {
			axios.get(`${API_URL}/organizations/roles`).then(({ data }) => {
				setRoles(data)
			})
		}

		loadUsers()
		loadRoles()
	}, [loaded])

	const handleUserFormModal = (user = null) => {
		setUserEdit(user)
		setUserModalOpen(true)
	}

	const loadUsers = () => {
		setLoaded(false)
	}

	const handleClose = (e, reloadUsers = false) => {
		setUserEdit(null)
		setUserModalOpen(false)

		reloadUsers && loadUsers()
	}

	return (
		<>
			{isAdmin && (
				<>
					<Box display="flex" justifyContent="space-between">
						<Box my="auto">
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									handleUserFormModal(null)
								}}
								startIcon={<AddCircle />}
							>
								{t('user.add')}
							</Button>
						</Box>
					</Box>

					{userModalOpen ? (
						<UserForm roles={roles} user={userEdit} onModalClose={handleClose} />
					) : (
						<></>
					)}

					<Box mt={4}>
						{error ? (
							<Error />
						) : loading ? (
							<LinearProgress />
						) : (
							<OrganisationTable
								roles={roles}
								results={results}
								setResults={setResults}
							/>
						)}
					</Box>
				</>
			)}
		</>
	)
}

export default Organisation
