const ChartTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div
				style={{
					backgroundColor: 'rgba(255, 255, 255, 0.96)',
					padding: 12,
					border: '1px solid #f1f1f1'
				}}
			>
				<p className="label">{`${label} : ${payload[0].value}`}</p>
			</div>
		)
	}

	return null
}

export default ChartTooltip
