import { useTranslation } from 'react-i18next'
import { Box, Link, Typography } from '@material-ui/core'
import { useErrorBoundary } from 'react-error-boundary'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

const ErrorBoundaryMessage = () => {
	let location = useLocation()
	const navigate = useNavigate()

	const [currentPath] = useState(location.pathname)

	const { t } = useTranslation()

	const { resetBoundary } = useErrorBoundary()

	useEffect(() => {
		console.log('location', location.pathname)
		if (location.pathname !== currentPath) {
			resetBoundary()
		}
	}, [location])

	const navigateBack = () => {
		navigate(-1)
	}

	return (
		<Box mt={26}>
			<Typography variant={'h4'} align={'center'}>
				{t('pageError')}
			</Typography>
			<br />
			<Typography variant={'h6'} align={'center'}>
				<Link onClick={navigateBack}>{t('pageNotFound.back')}</Link>
			</Typography>
		</Box>
	)
}

export default ErrorBoundaryMessage
