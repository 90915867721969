import { useTranslation } from 'react-i18next'
import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import useStyles from './styles'

const CompanyAmlPerson = ({ person }) => {
	const classes = useStyles()

	const navigate = useNavigate()
	const params = useParams()

	const { t } = useTranslation()

	const gotoPersonTable = (id) => {
		navigate(`/companies/${params.id}/${id}`)
	}

	return (
		<TableRow
			className={`${classes.tableRow} ${
				person.data?.amlStatus?.amlMatch ? classes.tableRowDanger : ''
			}`}
		>
			<TableCell onClick={() => gotoPersonTable(person.id)}>
				{person.data?.firstName} {person.data?.lastName}
			</TableCell>
			<TableCell>{person.data?.email}</TableCell>
			<TableCell>
				{person.data?.amlStatus?.amlMatch
					? t('amlStatus.matchFound')
					: t('amlStatus.matchNotFound')}
			</TableCell>
			<TableCell>
				{person.data?.amlStatus?.businessProhibition
					? t('amlStatus.matchFound')
					: t('amlStatus.matchNotFound')}
			</TableCell>
			<TableCell>
				{person.data?.amlStatus?.remarks
					? t('amlStatus.matchFound')
					: t('amlStatus.matchNotFound')}
			</TableCell>
			<TableCell>
				{person.data?.amlStatus?.pep
					? t('amlStatus.matchFound')
					: t('amlStatus.matchNotFound')}
			</TableCell>
			<TableCell>
				{person.data?.amlStatus?.rca
					? t('amlStatus.matchFound')
					: t('amlStatus.matchNotFound')}
			</TableCell>
			<TableCell>
				{person.data?.amlStatus?.sanction
					? t('amlStatus.matchFound')
					: t('amlStatus.matchNotFound')}
			</TableCell>
		</TableRow>
	)
}

export default CompanyAmlPerson
