import './Home.css'

import Stats from '../../components/stats/Stats'
import { useContext, useEffect } from 'react'
import { PageContext } from '../../context/PageContext'

const Home = () => {
	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({
			name: 'nav.dashboard'
		})
	}, [])

	return (
		<>
			<Stats />
		</>
	)
}

export default Home
