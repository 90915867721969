export default function (days = 7) {
	const end = new Date()
	const start = new Date(end).setDate(end.getDate() - days)

	const arr = []
	for (const date = new Date(start); date <= new Date(end); date.setDate(date.getDate() + 1)) {
		arr.push(new Date(date).toISOString().split('T', 1)[0])
	}
	return arr
}
