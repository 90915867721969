import { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { PERSONS_API_URL } from '../../../config/constants'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Box,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core'
import formatDate from '../../../utils/formatDate'
import { PageContext } from '../../../context/PageContext'

const PersonsHistoryAudit = () => {
	const { t, i18n } = useTranslation()
	const [history, setHistory] = useState([])
	const [loading, setLoading] = useState(true)

	const { setPageConfig } = useContext(PageContext)

	useEffect(() => {
		setPageConfig({ name: 'persons.historyAudit' })
	}, [])

	useEffect(() => {
		;(async () => {
			setLoading(true)

			const { data } = await axios.get(`${PERSONS_API_URL}/history/audit`)
			console.log(data)
			setHistory(data)

			setLoading(false)
		})()
	}, [])

	return (
		<Box>
			{loading ? (
				<LinearProgress />
			) : (
				<TableContainer>
					<Table
						classes={{
							root: 'primary'
						}}
					>
						<TableHead>
							<TableRow>
								<TableCell>{t('person_name')}</TableCell>
								<TableCell>{t('persons.audit.action')}</TableCell>
								<TableCell>{t('company')}</TableCell>
								<TableCell>{t('createdAt')}</TableCell>
								<TableCell>{t('created_by')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{history.map((item, index) => (
								<TableRow key={`list-${index}`}>
									<TableCell>
										<Link to={`/persons/${item.personId}`}>
											{item.personName}
										</Link>
									</TableCell>
									<TableCell>
										{i18n.exists(`persons.audit.action.${item.action}`)
											? t(`persons.audit.action.${item.action}`)
											: item.action}
									</TableCell>
									<TableCell>
										{item.companyId && (
											<Link to={`/companies/${item.companyId}`}>
												{t('nav.to.company')}
											</Link>
										)}
									</TableCell>
									<TableCell>{formatDate(item.createdAt)}</TableCell>
									<TableCell>{item.createdByName}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	)
}

export default PersonsHistoryAudit
