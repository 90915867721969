import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: `${theme.zIndex.drawer - 200}`,
		backgroundColor: `${theme.palette.background.paper}`,
		padding: theme.spacing(1),
		color: 'black',
		paddingLeft: 226,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0
		}
	}
}))

export default useStyles
