import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	highlighted: {
		'& [class*="MuiTableCell"]': {
			background: '#f2f2f2 !important'
		}
	}
}))

export default useStyles
