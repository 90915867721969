import {
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Grid,
	Button,
	Box,
	Typography
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../../../../utils/axios'
import { SMARTFORM_API_URL } from '../../../../config/constants'
import { SnackbarContext } from '../../../../context'
import { useParams } from 'react-router-dom'

const WorkflowDetailTasks = ({
	formId,
	task,
	index,
	removeTaskFromList,
	updateTaskInList,
	taskTypes
}) => {
	const { showMessage } = useContext(SnackbarContext)

	const params = useParams()

	const [data, setData] = useState(task)
	const [saving, setSaving] = useState(false)
	const [error, setError] = useState(false)

	const { t } = useTranslation()

	const handleInputChange = (prop) => (e) => {
		setData({
			...data,
			[prop]: e.target.value
		})

		updateTaskInList(index, {
			...data,
			[prop]: e.target.value
		})
	}
	const handleSwitchChange = (prop) => (e) => {
		setData({
			...data,
			[prop]: e.target.checked
		})

		updateTaskInList(index, {
			...data,
			[prop]: e.target.value
		})
	}

	const handleDelete = async () => {
		setSaving(true)
		setError(undefined)

		try {
			if (task.id) {
				await axios.delete(`${SMARTFORM_API_URL}/${formId}/tasks/${task.id}`, {
					data: {}
				})
			}

			removeTaskFromList(index)
		} catch (e) {
			const msg = e?.response?.data?.message || t('error')
			showMessage(msg, 'error')
			setError(msg)
		}

		setSaving(false)
	}

	const handleUpdateForm = async (e) => {
		e.preventDefault()

		setSaving(true)
		setError(undefined)

		try {
			if (task.id) {
				await axios.put(`${SMARTFORM_API_URL}/${formId}/tasks/${task.id}`, data)
			} else {
				await axios.post(`${SMARTFORM_API_URL}/${formId}/tasks`, data)
			}
		} catch (e) {
			const msg = e?.response?.data?.message || t('error')
			showMessage(msg, 'error')
			setError(msg)
		}

		setSaving(false)
	}

	return (
		<form noValidate autoComplete="off" onSubmit={handleUpdateForm}>
			{data && (
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12} sm={4}>
						<TextField
							type={'number'}
							label={t('workflow.task.priority')}
							variant="outlined"
							fullWidth={true}
							onChange={handleInputChange('priority')}
							value={data.priority}
							size={'small'}
							required
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<FormControl variant="outlined" required fullWidth={true} size={'small'}>
							<InputLabel htmlFor="org-select">{t('workflow.task.type')}</InputLabel>
							<Select
								labelId="org-select-label"
								label={t('type')}
								id="org-select"
								onChange={handleInputChange('taskType')}
								value={data.taskType}
								required
							>
								{taskTypes.map((type, index) => {
									return (
										<MenuItem key={index} value={type}>
											{type}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={2}>
						<FormControlLabel
							value="top"
							control={
								<Switch
									checked={data.continueOnFalse}
									onChange={handleSwitchChange('continueOnFalse')}
									color="primary"
									value={true}
								/>
							}
							label={t('workflow.continueOnFalse')}
							labelPlacement="top"
						/>
					</Grid>

					<Grid item xs={12} sm={1}>
						{params.id && (
							<Button
								type={'submit'}
								variant="contained"
								color="primary"
								disabled={saving}
							>
								{t('workflow.task.update')}
							</Button>
						)}
					</Grid>

					<Grid item xs={12} sm={1}>
						<Button
							variant="outlined"
							color={'secondary'}
							disabled={saving}
							onClick={handleDelete}
						>
							{t('workflow.task.remove')}
						</Button>
					</Grid>
				</Grid>
			)}

			{error && (
				<Box mb={4}>
					<Typography>{error}</Typography>
				</Box>
			)}
		</form>
	)
}

export default WorkflowDetailTasks
