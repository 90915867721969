import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'
import axios from '../../../utils/axios'
import { API_URL } from '../../../config/constants'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ChartTooltip from '../../chart/ChartTooltip'
import getDatesArray from '../../../utils/getDatesArray'

const ApiUsageChart = () => {
	const { t } = useTranslation()
	const { user } = useContext(UserContext)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		;(async () => {
			const { data } = await axios.get(`${API_URL}/v3/daily_api_stats`, {
				params: {
					uuid: `eq.${user.organizationId}`,
					order: 'day.desc',
					limit: 7
				}
			})

			const dates = getDatesArray(7)

			setData(
				dates.map((day) => {
					const item = data.find((item) => item.day === day)
					return {
						daily_usage_count: item?.daily_usage_count || 0,
						day: new Date(item?.day || day).toLocaleString('en-US', {
							day: '2-digit',
							month: 'short'
						})
					}
				})
			)
			setLoading(false)
		})()
	}, [])

	return (
		<>
			<Typography variant={'h4'}>
				{loading ? <Skeleton /> : <>{t('dashboard.apiUsage.chart')}</>}
			</Typography>

			<Box width={'100%'} height={300} mt={4}>
				{loading ? (
					<Skeleton variant={'rect'} height={300} />
				) : (
					<ResponsiveContainer width="100%" height="100%">
						<LineChart
							width={500}
							height={300}
							data={data}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5
							}}
						>
							<CartesianGrid strokeDasharray="10 0" horizontal={false} />
							<XAxis
								dataKey="day"
								stroke={'#a7a7b0'}
								axisLine={false}
								tick={{ fontSize: 12 }}
							/>
							<YAxis stroke={'#a7a7b0'} axisLine={false} tick={{ fontSize: 12 }} />
							<Tooltip
								cursor={{ fill: 'rgba(167, 167, 176, 0.2)' }}
								content={<ChartTooltip />}
							/>
							<Line
								type="monotone"
								dataKey="daily_usage_count"
								stroke="#FFE912"
								strokeWidth={10}
								activeDot={{ r: 8 }}
								dot={{
									stroke: '#fff',
									fill: '#FFE912',
									strokeWidth: 4,
									r: 8,
									strokeDasharray: ''
								}}
							/>
						</LineChart>
					</ResponsiveContainer>
				)}
			</Box>
		</>
	)
}

export default ApiUsageChart
