import React from 'react'
import { useTranslation } from 'react-i18next'
import { Auth, Hub } from 'aws-amplify'
import { useNavigate, useLocation } from 'react-router'

import { Collapse, List, ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {
	PieChart as DashboardIcon,
	ExitToApp as SignOutIcon,
	Business as BusinessIcon,
	AccountTree as DecisionIcon,
	ExpandLess,
	ExpandMore,
	Settings,
	Group as PersonsIcons,
	Description as SmartFormIcon,
	Event as EventIcon,
	Code as ApiIcon,
	MenuBook as GuideIcon
} from '@material-ui/icons'

import { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../../../context/UserContext'

import Logo from '../HeaderContent/Logo'

import useStyles from './NavContent.styles'

const NavContent = () => {
	const { user, isInternal, isSupport, isAdmin } = useContext(UserContext)

	const { t } = useTranslation()

	const [navItems, setNavItems] = useState([])

	const [settingsOpen, setSettingsOpen] = useState(false)
	const [personsOpen, setPersonsOpen] = useState(false)
	const [smartFormsOpen, setSmartFormsOpen] = useState(false)

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const items = []

		const settings = [
			{
				title: t('profile'),
				path: '/profile',
				isVisible: true
			},
			{
				title: t('my_organisation'),
				path: '/organisation',
				exact: true,
				isVisible: isAdmin()
			},
			{
				title: t('nav.3rd_party_credentials'),
				path: '/3rd-party-credentials',
				exact: true,
				isVisible: isAdmin()
			}
		]

		const persons = [
			{
				title: t('nav.persons.list'),
				path: '/persons',
				isVisible: true,
				exact: true
			},
			{
				title: t('persons.historyAudit'),
				path: '/persons-history-audit',
				exact: true,
				isVisible: isInternal() || isAdmin()
			}
		]

		items.push(
			{
				title: t('dashboard'),
				icon: <DashboardIcon fontSize="small" color={'inherit'} />,
				path: '/',
				exact: true,
				isVisible: true
			},
			{
				title: t('companies'),
				icon: <BusinessIcon fontSize="small" color={'inherit'} />,
				path: '/companies',
				exact: true,
				isVisible: true
			},

			{
				title: t('persons'),
				icon: <PersonsIcons fontSize="small" color={'inherit'} />,
				isVisible: true,
				nested: persons,
				isNestedOpen: personsOpen,
				onClick: () => {
					setPersonsOpen(!personsOpen)
					return false
				}
			},
			{
				title: t('nav.workflow.responses'),
				path: '/events',
				isVisible: true,
				icon: <EventIcon fontSize="small" color={'inherit'} />
			},
			{
				title: t('nav.workflow.settings'),
				path: '/workflows',
				isVisible: true,
				icon: <SmartFormIcon fontSize="small" color={'inherit'} />
			},
			{
				title: t('results'),
				icon: <DecisionIcon fontSize="small" color={'inherit'} />,
				path: '/results',
				isVisible: true
			},
			{
				title: t('nav.guide'),
				path: 'https://docs.entercheck.eu',
				icon: <GuideIcon fontSize="small" color={'inherit'} />,
				exact: true,
				isVisible: true,
				target: '_blank',
				hasMargin: true
			},
			{
				title: t('api'),
				path: 'https://developer.entercheck.eu/',
				icon: <ApiIcon fontSize="small" color={'inherit'} />,
				exact: true,
				isVisible: true,
				target: '_blank'
			},
			{
				title: t('settings'),
				icon: <Settings fontSize="small" color={'inherit'} />,
				nested: settings,
				isNestedOpen: settingsOpen,
				onClick: () => {
					setSettingsOpen(!settingsOpen)
					return false
				},
				isVisible: true
			},
			{
				title: t('sign_out'),
				icon: <SignOutIcon fontSize="small" color={'inherit'} />,
				onClick: () => {
					Auth.signOut()
					return false
				},
				isVisible: true
			}
		)
		setNavItems(items)
	}, [user, isInternal, isSupport, t, settingsOpen, personsOpen, smartFormsOpen])

	Hub.listen('auth', (data) => {
		switch (data.payload.event) {
			case 'signOut':
				navigate('/login')
				break
			default:
				break
		}
	})

	const classes = useStyles()

	const active = (exact, path) => {
		return (
			(exact && location.pathname === path) || (!exact && location.pathname.startsWith(path))
		)
	}

	const Menu = ({ navItems, isNested }) => {
		return (
			<List classes={{ root: isNested ? classes.nestedList : classes.list }}>
				{navItems.map(
					(
						{
							title,
							icon,
							path,
							exact,
							onClick,
							nested,
							isNestedOpen,
							isVisible,
							target,
							hasMargin
						},
						i
					) => {
						return (
							<React.Fragment key={`nav-item-${i}`}>
								{isVisible && (
									<>
										<ListItem
											button
											onClick={
												onClick
													? (e) => {
															e.preventDefault()
															onClick(e)
														}
													: null
											}
											selected={active(exact, path)}
											classes={{
												root: `${hasMargin ? classes.itemMargin : ''} ${isNested ? classes.itemNested : classes.root}`,
												selected: classes.selected
											}}
											component={path ? Link : undefined}
											to={path}
											target={target}
										>
											{!!icon && (
												<ListItemIcon classes={{ root: classes.icon }}>
													{icon}
												</ListItemIcon>
											)}
											<ListItemText
												classes={{ primary: classes.listItemText }}
												primary={title}
											/>

											{nested &&
												(isNestedOpen ? <ExpandLess /> : <ExpandMore />)}
										</ListItem>

										{nested && (
											<Collapse
												in={isNestedOpen}
												timeout="auto"
												unmountOnExit
											>
												<Menu navItems={nested} isNested={true} />
											</Collapse>
										)}
									</>
								)}
							</React.Fragment>
						)
					}
				)}
			</List>
		)
	}

	return (
		<Box className={classes.container}>
			<Box className={classes.logo}>
				<Logo />
			</Box>
			<Menu navItems={navItems} />
		</Box>
	)
}

export default NavContent
