import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TableCell,
	TableRow
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AUTH_API_URL } from '../../../config/constants'
import { SnackbarContext, UserContext } from '../../../context'
import { useTranslation } from 'react-i18next'

const OrganisationTableRow = ({ user, roles, promptToDeleteUser, updateUserList }) => {
	const { t } = useTranslation()

	const { showMessage } = useContext(SnackbarContext)
	const { user: authUser } = useContext(UserContext)

	const [loading, setLoading] = useState(false)
	const [role, setRole] = useState(user.role)
	const [userEnabled, setUserEnabled] = useState(user.enabled)
	const [isSelf, setIsSelf] = useState(false)

	useEffect(() => {
		setRole(user.role)
		setUserEnabled(user.enabled)
		setIsSelf(authUser.username === user.userName)
	}, [user])

	const updateUser = (user, props) => {
		setLoading(true)

		axios
			.patch(AUTH_API_URL + '/organizations/users', {
				uuid: user.userId,
				...props
			})
			.then((response) => {
				if (response.status === 200) {
					const { data } = response

					showMessage(t('user.update.success'), 'success')

					updateUserList({
						userId: data.id,
						...data
					})
				} else {
					showMessage(t('user.update.error'), 'error')
				}
			})
			.catch((error) => {
				showMessage(t('user.update.error'), 'error')
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const toggleEnableUser = (user) => (e) => {
		setUserEnabled(!user.enabled)
		updateUser(user, {
			enabled: !user.enabled
		})
	}

	const handleUserRoleUpdate = (user) => (e) => {
		setRole(e.target.value)
		updateUser(user, {
			role: e.target.value
		})
	}

	return (
		<TableRow>
			<TableCell>{user.userName}</TableCell>
			<TableCell>{user.email}</TableCell>
			<TableCell width={220}>
				{isSelf ? (
					t(`role.${role.toLowerCase()}`)
				) : (
					<Box width={140}>
						<FormControl variant="outlined" fullWidth={true} size={'small'}>
							<InputLabel id={`role_${user.userName}`}>{t('role')}</InputLabel>
							<Select
								labelId={`role_${user.userName}`}
								id={`role_${user.userName}`}
								value={role}
								onChange={handleUserRoleUpdate(user)}
								label={t('role')}
								fullWidth={true}
								disabled={loading}
							>
								{role && !roles.includes(role) && (
									<MenuItem value={role}>
										{t(`role.${role.toLowerCase()}`)}
									</MenuItem>
								)}

								{roles.map((role, index) => {
									return (
										<MenuItem key={`name-${index}`} value={role}>
											{t(`role.${role.toLowerCase()}`)}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</Box>
				)}
			</TableCell>
			<TableCell width={140}>
				<Switch
					checked={userEnabled}
					onChange={toggleEnableUser(user)}
					color="primary"
					disabled={isSelf || loading}
				/>
			</TableCell>
			<TableCell width={140}>
				<Button
					onClick={promptToDeleteUser(user)}
					disabled={isSelf || loading || user.enabled}
					variant="outlined"
					color={'secondary'}
				>
					{t('user.delete')}
				</Button>
			</TableCell>
		</TableRow>
	)
}

export default OrganisationTableRow
