import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		color: '#172b4d',
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(1.5),
			marginRight: 0
		},
		'&:hover': {
			color: theme.palette.primary.main
		}
	}
}))

export default useStyles
