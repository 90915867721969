import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './layouts/Main'

import Home from './pages/home/Home'
import Login from './pages/login/Login'
import {
	CompanyList,
	CompanyDetail,
	CompanyAdd,
	CompanyByBusinessId,
	CompanyHistory
} from './pages/company'
import { DecisionList, DecisionDetail } from './pages/decision'
import { Profile } from './pages/profile'
import { Organisation } from './pages/organisation'
import { NotFound } from './pages/not-found'
import { RequireAuth } from './utils/RequireAuth'
import Register from './pages/register/Register'
import { PersonsList, PersonDetail, PersonsHistoryAudit } from './pages/person'
import { WorkflowSettings, WorkflowSettingsDetail } from './pages/workflow'
import WorkflowEventsDetail from './pages/wokrflow-events/WorkflowEventsDetail'
import ThirdPartyCredentials from './pages/third-party-credentials/ThirdPartyCredentials'
import { RequireCompanies } from './utils/RequireCompanies'
import Welcome from './pages/welcome/Welcome'
import { Notifications } from './pages/notifications'
import { WorkflowEvents } from './pages/wokrflow-events'

const AppRouter = () => (
	<Routes>
		<Route path="/login" element={<Login />} />
		<Route path="/register" element={<Register />} />

		<Route element={<Main />}>
			<Route element={<RequireAuth />}>
				<Route path="/welcome" element={<Welcome />} />

				<Route path="/profile" element={<Profile />} />
				<Route path="/organisation" element={<Organisation />} />
				<Route path="/3rd-party-credentials" element={<ThirdPartyCredentials />} />

				<Route path="/companies/add" element={<CompanyAdd />} />

				<Route path="/workflows" element={<WorkflowSettings />} />
				<Route path="/workflows/:id" element={<WorkflowSettingsDetail />} />
				<Route path="/workflows/create" element={<WorkflowSettingsDetail />} />

				<Route element={<RequireCompanies />}>
					<Route path="/companies" element={<CompanyList />} />
					<Route path="/companies/:id" element={<CompanyDetail />} />
					<Route path="/companies/:id/history" element={<CompanyHistory />} />

					<Route path="/companies/:companyId/:id" element={<PersonDetail />} />

					<Route path="/companies/buid/:buid" element={<CompanyByBusinessId />} />

					<Route path="/" element={<Home />} />

					<Route path="/persons" element={<PersonsList />} />
					<Route path="/persons/:id" element={<PersonDetail />} />
					<Route path="/persons-history-audit" element={<PersonsHistoryAudit />} />

					<Route path="/results" element={<DecisionList />} />
					<Route path="/results/:id" element={<DecisionDetail />} />

					<Route path="/results/:resultId/:id" element={<PersonDetail />} />

					<Route path="/events" element={<WorkflowEvents />} />
					<Route path="/events/:id" element={<WorkflowEventsDetail />} />

					<Route path="/notifications" element={<Notifications />} />
				</Route>
			</Route>
		</Route>

		<Route path="*" element={<NotFound />}></Route>

		{/* <Route   path="/api-keys" element={<ApiKeyList />} /> */}
	</Routes>
)

export default AppRouter
